import { Box, Divider, Typography } from "@mui/material";
import CEODrawer from "../../../../../components/turism/CEODrawer";
import IExcursion from "../../../../../services/api/Excursion/IExcursion";
import { generalDarkGrey, dmPrimaryOrange } from "../../../../../styles/colors";
import { ImageCarousel } from "../../../../../components/general/ImageCarousel";
import { useEffect, useState } from "react";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../../../components/general/buttons/SecondaryButton";
import ExcursionServiceCard from "../../../../../components/comercial/ExcursionServiceCard";
import { ExcursionServiceType } from "../../../../../types/types";
import printResponseError from "../../../../../utils/printResponseError";
import { getCoordinatesByAddress } from "../../../../../hooks/getCoodinatesByAddress";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import markerIconUrl from "../../../../../assets/img/map-pin.png";
import { Icon } from "leaflet";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import NearMeIcon from "@mui/icons-material/NearMe";
import EventIcon from "@mui/icons-material/Event";
import dayjs from "dayjs";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

const ExcursionDetails = () => {
  const selectedExcursion: IExcursion = JSON.parse(
    localStorage.getItem("selectedExcursion")
  );

  const [toggleExcursionDetails, setToggleExcursionDetails] = useState(true);
  const [coordinates, setCoordinates] = useState<[number, number]>([0, 0]);

  const fetchExcursionLocation = async () => {
    try {
      const response = await getCoordinatesByAddress({
        city: selectedExcursion.city,
        state: selectedExcursion.state,
        region: selectedExcursion.region,
        country: selectedExcursion.country,
        district: "",
        number: "",
        street: "",
      });
      setCoordinates([Number(response[0]), Number(response[1])]);
    } catch (error) {
      printResponseError("Error fetching excursion location: ", error);
    }
  };

  useEffect(() => {
    fetchExcursionLocation();
  }, []);

  const customMarker = new Icon({
    iconUrl: markerIconUrl,
    iconSize: [40, 40],
  });

  const sectionDivider = (
    <Divider
      sx={{
        width: "100%",
        color: generalDarkGrey,
      }}
    />
  );

  const renderExcursionDetails = (
    <Box>
      <Box
        display="flex"
        gap={5}
        margin="15px 0px 30px 0px"
        sx={{
          "@media(max-width:1000px)": {
            flexDirection: "column",
            gap: 2,
          },
        }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h6" fontWeight="bold">
            Quais serviços estão inclusos?
          </Typography>
          <Box
            display="flex"
            gap={1.5}
            sx={{
              "@media(max-width:800px)": {
                flexDirection: "column",
                gap: 2,
                margin: "10px 0px",
              },
            }}
          >
            <ExcursionServiceCard
              serviceType={ExcursionServiceType.ACCOMMODATION}
              service={selectedExcursion.accommodationService}
            />
            <ExcursionServiceCard
              serviceType={ExcursionServiceType.TRANSPORT}
              service={selectedExcursion.transportService}
            />
            <ExcursionServiceCard
              serviceType={ExcursionServiceType.FOOD}
              service={selectedExcursion.foodService}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h6" fontWeight="bold">
            Restrições
          </Typography>
          <Typography color={generalDarkGrey}>
            • {selectedExcursion.restriction}
          </Typography>
        </Box>
      </Box>

      {sectionDivider}

      {selectedExcursion.description.length > 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          margin="20px 0px 30px 0px"
        >
          <Typography variant="h6" fontWeight="bold">
            Descrição:
          </Typography>
          <Typography textAlign="justify" color={generalDarkGrey}>
            {selectedExcursion.description}
          </Typography>
        </Box>
      ) : null}

      {sectionDivider}

      <Box display="flex" flexDirection="column" gap={2} margin="30px 0px">
        <Typography variant="h5" fontWeight="bold">
          Explore seu destino!
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          <MapContainer
            zoom={13}
            center={[coordinates[0], coordinates[1]]}
            style={{ height: "400px", borderRadius: "20px" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              key={selectedExcursion.id}
              position={[coordinates[0], coordinates[1]]}
              icon={customMarker}
            />
          </MapContainer>

          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="h6" fontWeight="bold">
              {`${selectedExcursion.city} - ${selectedExcursion.state} (${selectedExcursion.region}) | ${selectedExcursion.country}`}
            </Typography>
            {selectedExcursion.router.length > 0 ? (
              <Box display="flex" gap={1} alignItems="center">
                <NearMeIcon />
                <Box>
                  <Typography fontWeight="bold">
                    Rota da viagem:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {selectedExcursion.router.map((route, index) => {
                        return index === selectedExcursion.router.length - 1
                          ? `${route}.`
                          : `${route}, `;
                      })}
                    </span>
                  </Typography>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const renderExcursionShowcase = (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      width="80%"
      sx={{
        "@media(max-width:1000px)": {
          width: "100%",
        },
      }}
    >
      <Box>
        <ImageCarousel imageUrls={selectedExcursion.imageOriginal} />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        gap={2}
        sx={{
          "@media(max-width:1000px)": {
            flexDirection: "column",
          },
        }}
      >
        <Box>
          <Typography variant="h5" fontWeight="bold">
            {selectedExcursion.title}
          </Typography>
          <Typography
            variant="h6"
            color={generalDarkGrey}
          >{`Categoria: ${selectedExcursion.mainCategory}`}</Typography>
        </Box>
        <Box>
          <Typography
            variant="h6"
            color={dmPrimaryOrange}
          >{`R$ ${selectedExcursion.price.toFixed(2)}`}</Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        gap={5}
        sx={{ "@media(max-width:900px)": { flexDirection: "column", gap: 1 } }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <EventIcon />
          <Typography textAlign="center">{`${dayjs(
            selectedExcursion.startDate
          ).format("DD/MM/YYYY")} - ${dayjs(selectedExcursion.endDate).format(
            "DD/MM/YYYY"
          )}`}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <ConfirmationNumberIcon />
          <Typography textAlign="center">{`1 vagas disponíveis (${selectedExcursion.maxVacancies} totais)`}</Typography>
        </Box>
      </Box>

      <Box marginTop="20px">
        <PrimaryButton
          label="Adicionar ao carrinho"
          icon={<AddShoppingCartIcon />}
        />
      </Box>
      <Box marginTop="15px">{sectionDivider}</Box>
      <Box display="flex" gap={2}>
        {toggleExcursionDetails ? (
          <PrimaryButton label="Detalhes" />
        ) : (
          <SecondaryButton
            label="Detalhes"
            onClickAction={() => {
              setToggleExcursionDetails(true);
            }}
          />
        )}
        {toggleExcursionDetails ? (
          <SecondaryButton
            label="Avaliações"
            onClickAction={() => {
              setToggleExcursionDetails(false);
            }}
          />
        ) : (
          <PrimaryButton label="Avaliações" />
        )}
      </Box>

      {toggleExcursionDetails ? renderExcursionDetails : null}
    </Box>
  );

  const renderServiceViewContent = (
    <Box display="flex" gap={5}>
      {renderExcursionShowcase}
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderServiceViewContent} />
    </Box>
  );
};

export default ExcursionDetails;
