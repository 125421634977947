import { Box, IconButton, SxProps } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface BackPageButtonProps {
  containerStyles: SxProps;
  iconStyles: SxProps;
}

const BackPageButton = (props: BackPageButtonProps) => {
  const navigate = useNavigate();

  const { containerStyles, iconStyles } = props;

  return (
    <Box sx={containerStyles}>
      <IconButton onClick={() => navigate(-1)} color="primary">
        <ArrowBackIcon sx={iconStyles} />
      </IconButton>
    </Box>
  );
};

export default BackPageButton;
