import { createTheme } from "@mui/material/styles";
import {
  generalBlack,
  generalDarkGrey,
  petPrimaryGreen,
  petSecondaryGreen,
  beautyPrimaryPurple,
  beautySecondaryPurple,
  turismPrimary,
  turismSecondary,
} from "./colors";
import { ptBR } from "@mui/x-date-pickers/locales";

export const beautyTheme = createTheme(
  {
    palette: {
      primary: {
        main: beautyPrimaryPurple,
      },
      secondary: {
        main: beautySecondaryPurple,
      },
      text: {
        primary: generalBlack,
        secondary: generalDarkGrey,
      },
    },
  },
  ptBR
);

export const petTheme = createTheme(
  {
    palette: {
      primary: {
        main: petPrimaryGreen,
      },
      secondary: {
        main: petSecondaryGreen,
      },
      text: {
        primary: generalBlack,
        secondary: generalDarkGrey,
      },
    },
  },
  ptBR
);

export const turismTheme = createTheme(
  {
    palette: {
      primary: {
        main: turismPrimary,
      },
      secondary: {
        main: turismSecondary,
      },
      text: {
        primary: generalBlack,
        secondary: generalDarkGrey,
      },
    },
  },
  ptBR
);
