import { Box, Divider, TextField, Typography } from "@mui/material";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Alert from "@mui/material/Alert";
import jwtDecode from "jwt-decode";
import { PrimaryButton } from "../../../components/general/buttons/PrimaryButton";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { generalDarkGrey } from "../../../styles/colors";
import { UserRole } from "../../../types/types";
import { loginSchema } from "../../../utils/yupSchemas";
import {
  DEMANDORIA_SUBDOMAINS,
  getDemandoriaSubdomain,
} from "../../../utils/stringUtils";

const STANDARD_WIDTH: number = 380;

const currentSubdomain = getDemandoriaSubdomain();

const Login = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestErrorFlag, setRequestErrorFlag] = useState<boolean>(false);

  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isLoggedIn) {
      if (auth.user.role === UserRole.CLIENT) navigate("/home");
      else if (auth.user.role === UserRole.COMERCIAL)
        if (
          currentSubdomain === DEMANDORIA_SUBDOMAINS[0] ||
          currentSubdomain === DEMANDORIA_SUBDOMAINS[1]
        )
          navigate("/stores");
        else navigate("/agencies");
    }
  });

  useEffect(() => {
    setRequestErrorFlag(false);
  }, [emailRef.current?.value, passwordRef.current?.value]);

  const handleSubmit = async () => {
    const userEmail = emailRef.current.value;
    const userPassword = passwordRef.current.value;

    setRequestErrorFlag(false);
    setIsLoading(!isLoading);
    try {
      await auth.authenticate(userEmail, userPassword);
      console.log("User successfully logged in.");
    } catch (error) {
      setIsLoading(false);
      setRequestErrorFlag(true);
      console.warn("Error on user login: ", error);
    }
  };

  const handleGoogleLoginSubmit = async (codedJwtUserInfo: string) => {
    const decodedGoogleAccountInfo = jwtDecode(codedJwtUserInfo);

    const decodedGoogleUsername = decodedGoogleAccountInfo?.name;
    const decodedGoogleEmail = decodedGoogleAccountInfo?.email;
    const decodedGoogleImage = decodedGoogleAccountInfo?.picture;

    console.log({
      decodedGoogleUsername,
      decodedGoogleEmail,
      decodedGoogleImage,
    });

    try {
      await auth.authenticateWithGoogle(
        codedJwtUserInfo,
        decodedGoogleUsername,
        decodedGoogleEmail,
        decodedGoogleImage
      );
      console.log("User successfully logged in.");
    } catch (error) {
      console.warn("Error login with google: ", error);
    }
  };

  const renderFormTitle = (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      margin="20px 0px"
      alignItems="center"
    >
      <Typography variant="h4" fontWeight="bold">
        {currentSubdomain === DEMANDORIA_SUBDOMAINS[0]
          ? "Demandoria Beleza"
          : currentSubdomain === DEMANDORIA_SUBDOMAINS[1]
            ? "Demandoria PET"
            : "Demandoria Turismo"}
      </Typography>
      <Typography fontSize="21px">Conecte-se à sua conta</Typography>
    </Box>
  );

  const renderLoginForm = (
    <form onSubmit={onSubmit(handleSubmit)}>
      <Box display="flex" flexDirection="column" gap={2} marginBottom="10px">
        <TextField
          label="Email*"
          type="email"
          inputRef={emailRef}
          sx={{ minWidth: STANDARD_WIDTH, height: "65px" }}
          {...register("email")}
          error={!!errors.email || requestErrorFlag}
          helperText={errors.email?.message}
        />

        <Box display="flex" flexDirection="column" gap={1} margin="5px 0px">
          <TextField
            label="Senha*"
            type="password"
            inputRef={passwordRef}
            sx={{ minWidth: STANDARD_WIDTH, height: "65px" }}
            {...register("password")}
            error={!!errors.password || requestErrorFlag}
            helperText={errors.password?.message}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              cursor: "pointer",
              color: "text.secondary",
              alignSelf: "flex-end",
              textTransform: "none",
            }}
          >
            <span
              onClick={() => {
                navigate("/password-recovery");
              }}
            >
              Esqueceu sua senha?
            </span>
          </Typography>
        </Box>

        <Box marginBottom="20px">
          <PrimaryButton
            label="Entrar"
            icon={<LoginIcon />}
            isLoading={isLoading}
            styles={{ height: "50px" }}
          />
        </Box>
      </Box>
    </form>
  );

  const renderThirdPartyLoginOptions = (
    <Box>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "14px",
          color: "text.secondary",
          textTransform: "none",
        }}
      >
        <Divider>Ou</Divider>
      </Typography>

      <Box display="flex" flexDirection="column" margin="15px 0px" gap={2}>
        <GoogleOAuthProvider
          clientId="729888061740-8683j1899b2usv443a616open6gpm1gm.apps.googleusercontent.com"
          key="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"
        >
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              handleGoogleLoginSubmit(credentialResponse.credential);
            }}
            width={String(STANDARD_WIDTH)}
          />
        </GoogleOAuthProvider>
      </Box>
    </Box>
  );

  const renderNewUserLink = (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      margin="20px 0px"
      gap={0.5}
    >
      <Typography fontSize="14px" color={generalDarkGrey}>
        Usuário novo?
      </Typography>
      <Typography
        onClick={() => {
          navigate("/register");
        }}
        sx={{
          color: "primary.main",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        Cadastre-se
      </Typography>
    </Box>
  );

  const renderFormArea = (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box display="flex" flexDirection="column">
        {renderFormTitle}
        {requestErrorFlag ? (
          <Alert severity="error" sx={{ p: "0px 3px", mb: "25px" }}>
            Email ou senha inválidos.
          </Alert>
        ) : null}
        {renderLoginForm}
        {renderThirdPartyLoginOptions}
        {renderNewUserLink}
      </Box>
    </Box>
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <>{renderFormArea}</>
    </Box>
  );
};

export default Login;
