import { Box, Button, SxProps, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import { IStore } from "../../../services/api/Store/types";
import { generalDarkGrey } from "../../../styles/colors";

interface MapHoverCardProps {
  imageUrl: string;
  primaryText: string;
  secondaryText: string;
  selectedStore?: IStore;
}

export const MapCard = (props: MapHoverCardProps) => {
  const navigate = useNavigate();

  const handleSelectStoreClick = (storeId: string) => {
    navigate(`/store?id=${storeId}`);
  };

  return (
    <Box sx={styles.mainContainer}>
      <img
        src={props.imageUrl}
        alt={`${props.primaryText} preview`}
        width="100%"
        height="100%"
        style={{ objectFit: "cover" }}
      />

      <Box sx={styles.storeInfoContainer}>
        <Typography variant="h6" sx={styles.storeName}>
          {props.primaryText}
        </Typography>

        <Box sx={styles.addressContainer}>
          <LocationOnIcon sx={{ width: 13, height: 13 }} />
          <Typography variant="body2">{props.secondaryText}</Typography>
        </Box>
      </Box>
      <Button
        variant="contained"
        fullWidth
        disableElevation
        onClick={() => {
          handleSelectStoreClick(props.selectedStore.id);
        }}
        sx={{ textTransform: "none" }}
      >
        ir para a página da loja
      </Button>
    </Box>
  );
};

const styles = {
  mainContainer: {
    margin: "0px",
  } as SxProps,

  storeName: { margin: "-12px 0px" } as SxProps,

  addressContainer: {
    display: "flex",
    gap: 0.5,
    alignItems: "center",
    color: generalDarkGrey,
  } as SxProps,

  storeInfoContainer: {
    display: "flex",
    flexDirection: "column",
  } as SxProps,
};
