import {
  Modal,
  Box,
  Button,
  Typography,
  Collapse,
  TextField,
  Rating,
  CircularProgress,
  IconButton,
} from "@mui/material";
import AppointmentStatusChip from "../AppointmentStatusChip";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { Dispatch, useEffect, useRef, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import dayjs from "dayjs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import appointmentApi from "../../../services/api/Appointment";
import { AppointmentStatus, UserRole } from "../../../types/types";
import { formatDateAndTimeDayDateToString } from "../../../pages/petAndBeauty/ClientPages/ScheduilingPage/timeUtils";
import ratingApi from "../../../services/api/Rating";
import { IAppointment } from "../../../services/api/Appointment/types";
import { PrimaryButton } from "../buttons/PrimaryButton";
import StarRateIcon from "@mui/icons-material/StarRate";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import CheckIcon from "@mui/icons-material/Check";
import { IRating } from "../../../services/api/Rating/types";
import CloseIcon from "@mui/icons-material/Close";

const styles = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,

  bgcolor: "background.paper",
  p: "20px",
  "@media (max-width: 600px)": {
    width: 300,
  },
};

interface AppointmentDetailsModalProps {
  open: any;
  onClose: Function;
  appointment?: IAppointment;
  onConfirm?: any;
  onFinish?: any;
  showClient?: boolean;
  showRating?: boolean;
  setSucceededRatingPost?: Dispatch<React.SetStateAction<boolean>>;
}

export const AppointmentDetailsModal = (
  props: AppointmentDetailsModalProps
) => {
  const auth = useAuth();
  const [wantsToCancel, setWantsToCancel] = useState(false);

  const appointmentCancellationReasonRef = useRef<HTMLInputElement>(null);

  const [loadingRating, setLoadingRating] = useState(true);
  const [rating, setRating] = useState<IRating | null>(null);
  const [ratingStars, setRatingStars] = useState(5);

  const ratingCommentRef = useRef<HTMLInputElement>(null);

  const handleCancelAppointmentClick = async () => {
    const today = dayjs().toISOString();

    const cancelationReasonString =
      appointmentCancellationReasonRef.current?.value;

    const updatedAppointment = {
      serviceEmployeeId: props.appointment?.serviceEmployeeId,
      cancelationReason: cancelationReasonString,
      canceledAt: today,
      status: AppointmentStatus.CANCELED,
    };

    try {
      await appointmentApi.updateAppointment(
        props.appointment.id,
        updatedAppointment
      );

      location.reload();
    } catch (error) {
      console.warn("Error cancelling the appointment: ", error);
    }
  };

  const handleFinishAppointmentClick = async () => {
    const updatedAppointment = {
      status: AppointmentStatus.FINISHED,
    };

    try {
      await appointmentApi.updateAppointment(
        props.appointment.id,
        updatedAppointment
      );

      props.onFinish();
    } catch (error) {
      console.warn("Error finishing the appointment: ", error);
    }
  };

  const fetchAppointmentRating = async () => {
    if (!loadingRating) setLoadingRating(true);
    try {
      const appointmentRatingResponse =
        await ratingApi.getRatingFromUserRatedAppointment(
          props.appointment.id,
          auth.user.id
        );

      setRating(appointmentRatingResponse);
      setLoadingRating(false);
    } catch (error) {
      console.error("Error fetching appointment rating: ", error);
    }
  };

  const handleRateService = async () => {
    const newRating: IRating = {
      appointmentId: props.appointment.id,
      rating: ratingStars,
      comment: ratingCommentRef.current.value,
      who: "CLIENT",
    };

    try {
      const newRatingResponse = await ratingApi.createRating(newRating);

      newRatingResponse ? props.setSucceededRatingPost(true) : null;

      props.onFinish();
    } catch (error) {
      console.error("Error rating the service: ", error);
    }
  };

  const toggleWantsToCancel = () => {
    setWantsToCancel(!wantsToCancel);
  };

  const handleModalClose = () => {
    setWantsToCancel(false);
    props.onClose();
  };

  useEffect(() => {
    if (props.open && auth.user.role === UserRole.CLIENT)
      fetchAppointmentRating();
    else setRating(null);
  }, [props.open]);

  return (
    <Modal open={props.open} onClose={handleModalClose}>
      <Box
        sx={styles}
        borderRadius="10px"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={2}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          <Box margin="-20px " alignSelf="flex-end">
            <IconButton onClick={handleModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="h6">Detalhes do serviço</Typography>
          <Typography color="text.secondary" fontSize="10pt">
            Agendado em:{" "}
            <span>
              {props.appointment?.createdAt
                ? formatDateAndTimeDayDateToString(props.appointment?.createdAt)
                : ""}
            </span>
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography fontWeight="bold">
            Serviço:{" "}
            <span style={{ fontWeight: "normal" }}>
              {props.appointment?.serviceEmployee.ServiceStore.Service.name}
            </span>
          </Typography>

          <Typography fontWeight="bold">
            Estabelecimento:{" "}
            <span style={{ fontWeight: "normal" }}>
              {props.appointment?.serviceEmployee.Employee.store.name}
            </span>
          </Typography>
          {props.showClient ? (
            <Typography fontWeight="bold">
              Cliente:{" "}
              <span style={{ fontWeight: "normal" }}>
                {props.appointment?.clientName}
              </span>
            </Typography>
          ) : null}
          <Typography fontWeight="bold">
            Profissional:{" "}
            <span style={{ fontWeight: "normal" }}>
              {props.appointment?.serviceEmployee.Employee.User.name}
            </span>
          </Typography>
          <Typography fontWeight="bold">
            Data:{" "}
            <span style={{ fontWeight: "normal" }}>
              {props.appointment?.date
                ? formatDateAndTimeDayDateToString(props.appointment?.date)
                : ""}
            </span>
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography fontWeight="bold">Status: </Typography>
            <AppointmentStatusChip status={props.appointment?.status} />
          </Box>
        </Box>

        {(props.appointment?.status === AppointmentStatus.PENDENT ||
          props.appointment?.status === AppointmentStatus.CONFIRMED) &&
        auth.user.role !== UserRole.CLIENT ? (
          <Box margin="10px 0px 20px 0px" width="100%">
            <Button
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              fullWidth
              onClick={handleFinishAppointmentClick}
              startIcon={<CheckCircleIcon />}
            >
              Finalizar serviço
            </Button>
          </Box>
        ) : (
          <></>
        )}
        {props.appointment?.status === AppointmentStatus.PENDENT ||
        props.appointment?.status === AppointmentStatus.CONFIRMED ? (
          <Box>
            <Button
              fullWidth
              sx={{ textTransform: "none" }}
              disableElevation
              endIcon={wantsToCancel ? <ExpandLess /> : <ExpandMore />}
              onClick={toggleWantsToCancel}
              color="error"
            >
              Deseja cancelar o serviço?
            </Button>
            <Collapse in={wantsToCancel} timeout="auto" unmountOnExit>
              <Box
                margin="20px 0px"
                display="flex"
                flexDirection="column"
                gap={2}
                width="300px"
              >
                <TextField
                  fullWidth
                  size="small"
                  label="Motivo de cancelamento (opcional)"
                  rows={2}
                  multiline
                  inputRef={appointmentCancellationReasonRef}
                />
                <Button
                  fullWidth
                  sx={{ textTransform: "none" }}
                  disableElevation
                  startIcon={<EventBusyIcon />}
                  variant="contained"
                  color="error"
                  onClick={handleCancelAppointmentClick}
                >
                  Cancelar serviço
                </Button>
              </Box>
            </Collapse>
          </Box>
        ) : null}
        {props.appointment?.status === AppointmentStatus.FINISHED &&
        auth.user.role === UserRole.CLIENT ? (
          <>
            {!loadingRating ? (
              <>
                {rating === null || rating.length === 0 ? (
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={2}
                  >
                    <Button
                      fullWidth
                      sx={{ textTransform: "none" }}
                      disableElevation
                      startIcon={<StarRateIcon />}
                      endIcon={wantsToCancel ? <ExpandLess /> : <ExpandMore />}
                      onClick={toggleWantsToCancel}
                    >
                      Deseja avaliar o serviço?
                    </Button>
                    <Collapse in={wantsToCancel} timeout="auto" unmountOnExit>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap={2}
                        width="300px"
                      >
                        <Box>
                          <Rating
                            value={ratingStars}
                            onChange={(_, newValue) => {
                              setRatingStars(newValue);
                            }}
                            size="large"
                          />
                        </Box>

                        <TextField
                          fullWidth
                          size="small"
                          label="Comentário"
                          rows={2}
                          multiline
                          inputRef={ratingCommentRef}
                        />
                        <PrimaryButton
                          label="Confirmar avaliação"
                          icon={<CheckIcon />}
                          onClickAction={handleRateService}
                        />
                      </Box>
                    </Collapse>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography fontWeight="bold" marginBottom="10px">
                      Sua avaliação
                    </Typography>
                    <Rating
                      name="read-only"
                      value={rating[0].rating}
                      readOnly
                    />
                    <Typography color="text.secondary">
                      Comentário: <span>{rating[0].comment}</span>
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                margin="20px 0px"
              >
                <CircularProgress />
              </Box>
            )}
          </>
        ) : null}
      </Box>
    </Modal>
  );
};
