import { useState } from "react";

interface DescriptionWrapperProps {
  text: string | null;
  max: number;
}

const DescriptionWrapper = (props: DescriptionWrapperProps) => {
  const { text, max } = props;
  const [showFullText, setShowFullText] = useState(false);

  return (
    <>
      {showFullText ? text : text?.substring(0, max)}
      <span
        onClick={() => setShowFullText(!showFullText)}
        style={{
          cursor: "pointer",
          fontWeight: "bold",
        }}
      >
        {text?.length <= max
          ? ""
          : showFullText
            ? " ...mostrar menos"
            : "...mostrar mais"}
      </span>
    </>
  );
};

export default DescriptionWrapper;
