import { Badge } from "@mui/material";
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { Dispatch, SetStateAction } from "react";

const today = dayjs();

interface DmCalendarProps {
  selectedDate: Dayjs;
  setSelectedDate: Dispatch<SetStateAction<Dayjs>>;
  highlightedDays: string[];
}

const DmCalendar = (calendarProps: DmCalendarProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <DateCalendar
        defaultValue={today}
        disablePast
        slots={{
          day: (props) => {
            const iterableDate = props.day.format("YYYY-MM-DD");

            if (calendarProps.highlightedDays.includes(iterableDate)) {
              return (
                <Badge
                  key={props.day.toString()}
                  overlap="circular"
                  variant="dot"
                  color="success"
                >
                  <PickersDay {...props} day={props.day} />
                </Badge>
              );
            } else {
              return (
              <Badge key={props.day.toString()}>
                <PickersDay {...props} day={props.day} />
              </Badge>)
            }
          },
        }}
        dayOfWeekFormatter={(day) => {}}
        value={calendarProps.selectedDate}
        onChange={(newDate) => {
          calendarProps.setSelectedDate(newDate);
        }}
      />
    </LocalizationProvider>
  );
};

export default DmCalendar;
