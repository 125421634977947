import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Box,
} from "@mui/material";
import { generalWhite, generalDarkGrey } from "../../../styles/colors";
import ClearIcon from "@mui/icons-material/Clear";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { ReactNode } from "react";

interface ImageGalleryProps {
  images: HTMLCanvasElement[];
  setImages: React.Dispatch<React.SetStateAction<HTMLCanvasElement[]>>;
  setOriginalImages: React.Dispatch<React.SetStateAction<HTMLCanvasElement[]>>;
  setPreviewImages: React.Dispatch<React.SetStateAction<HTMLCanvasElement[]>>;
  interable?: boolean;
  emptyGalleryText?: ReactNode;
}

const ImageGallery = (props: ImageGalleryProps) => {
  const handleRemoveImageClick = (index: number) => {
    if (props.images.length > 0)
      props.setImages((previousImages) =>
        previousImages.filter((image, i) => i !== index)
      );
    props.setOriginalImages((previousImages) =>
      previousImages.filter((image, i) => i !== index)
    );
    props.setPreviewImages((previousImages) =>
      previousImages.filter((image, i) => i !== index)
    );
  };

  return (
    <>
      {props.images.length > 0 ? (
        <ImageList
          sx={{ marginBottom: "40px" }}
          cols={3}
          variant="quilted"
          rowHeight={300}
        >
          {props.images.map((image, index) => (
            <ImageListItem key={index}>
              <img
                src={image.toDataURL()}
                alt={`Image ${index}`}
                loading="lazy"
              />
              {props.interable ? (
                <ImageListItemBar
                  sx={{
                    background:
                      "linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, " +
                      "rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%)",
                  }}
                  position="top"
                  actionIcon={
                    <IconButton
                      sx={{ color: generalWhite }}
                      onClick={() => {
                        handleRemoveImageClick(index);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                  actionPosition="left"
                />
              ) : null}
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
          color={generalDarkGrey}
          margin="70px 0px"
        >
          <AddPhotoAlternateIcon fontSize="large" />
          <Box>{props.emptyGalleryText}</Box>
        </Box>
      )}
    </>
  );
};

export default ImageGallery;
