import { Box, ImageList, ImageListItem, Typography } from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CEODrawer from "../../../../../components/pet-beauty/CEODrawer";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import TimeRangeInterpreter from "../../../../../components/comercial/TimeRangeInterpreter";
import { IStore } from "../../../../../services/api/Store/types";
import { generalDarkGrey } from "../../../../../styles/colors";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";

const StoreView = () => {
  const selectedStore: IStore = JSON.parse(
    localStorage.getItem("selectedStore")
  );

  const imageUrls: string[] = selectedStore.imageOriginal || [];

  const isMobile = useMediaQuery("(max-width: 1000px)");
  const isMediumSize = useMediaQuery("(max-width: 1600px)");

  const cols = isMobile ? 1 : isMediumSize ? 2 : 4;

  const renderStoreData = (
    <Box>
      <CEOInterfaceHeader title="Dados do estabelecimento" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "25px",
          gap: { xs: 2, md: 3 },
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography fontWeight="bold" variant="h6">
            {selectedStore.name}
          </Typography>
          <Typography variant="body1" textAlign="justify">
            {selectedStore.CNPJ}
          </Typography>
        </Box>

        <Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              alignItems="center"
            >
              <Box display="flex" gap={1}>
                <FmdGoodOutlinedIcon color="primary" />
                <Typography>{`${selectedStore.logradouro}, ${
                  selectedStore.number || "s/n"
                } - ${selectedStore.district}, ${selectedStore.city} - ${
                  selectedStore.state
                }, ${selectedStore.CEP}`}</Typography>
              </Box>
              <Box display="flex" gap={3}>
                <Box display="flex" gap={1}>
                  <LocalPhoneIcon color="primary" />
                  <Typography variant="body1">{selectedStore.phone}</Typography>
                </Box>
                <Box display="flex" gap={1}>
                  <AlternateEmailIcon color="primary" />
                  <Typography variant="body1">{selectedStore.email}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box marginBottom="20px">
        <Box display="flex" flexDirection="column">
          <Typography fontWeight="bold" variant="h6">
            Descrição:
          </Typography>

          {selectedStore.description?.length > 0 ? (
            <Typography variant="body1" textAlign="justify">
              {selectedStore.description}
            </Typography>
          ) : (
            <Typography
              variant="body1"
              textAlign="justify"
              color={generalDarkGrey}
            >
              Este estabelecimento não possui descrição.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );

  const renderImageGallery = (
    <Box>
      <CEOInterfaceHeader title="Galeria de fotos" />
      <Box
        display="flex"
        flexDirection="column"
        margin="20px"
        gap={3}
        alignItems="center"
      >
        <ImageList
          sx={{ marginBottom: "40px" }}
          cols={cols}
          gap={10}
          variant="standard"
        >
          {imageUrls.length !== 0 ? (
            imageUrls.map((imageUrl, index) => (
              <ImageListItem key={index}>
                <img
                  src={imageUrl}
                  alt={`Image ${index}`}
                  loading="lazy"
                  style={{
                    width: isMobile ? 250 : isMediumSize ? 300 : 300,
                    height: isMobile ? 250 : isMediumSize ? 300 : 300,
                  }}
                />
              </ImageListItem>
            ))
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin="30px 0px"
            >
              <Typography
                textAlign="center"
                color={generalDarkGrey}
                fontWeight="bold"
              >
                Galeria vazia.
              </Typography>
            </Box>
          )}
        </ImageList>
      </Box>
    </Box>
  );

  const renderWorkScale = (
    <Box>
      <CEOInterfaceHeader title="Horários de funcionamento" />
      <Box display="flex" flexDirection="column" margin="20px" gap={3}>
        <TimeRangeInterpreter
          timeMonday={selectedStore.timeMonday}
          timeTuesday={selectedStore.timeTuesday}
          timeWednesday={selectedStore.timeWednesday}
          timeThursday={selectedStore.timeThursday}
          timeFriday={selectedStore.timeFriday}
          timeSaturday={selectedStore.timeSaturday}
          timeSunday={selectedStore.timeSunday}
        />
      </Box>
    </Box>
  );

  const renderAddStorePageContent = (
    <Box>
      {renderStoreData}
      {renderImageGallery}
      {renderWorkScale}
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderAddStorePageContent} />
    </Box>
  );
};

export default StoreView;
