/* ===== COMMON ===== */
export const generalBlack = "#181F1C";
export const generalDarkGrey = "#8F8F8F";
export const generalBrightGrey = "#DADADA";
export const generalWhite = "#FFFFFF";
/* ================== */

/* ===== DEMANDORIA ===== */
export const dmYellow = "#FFCC8C";
export const dmBrighterOrange = "#F99446";
export const dmPrimaryOrange = "#E86740";
/* ====================== */

/* ===== DEMANDORIA ===== */
export const turismPrimary = "#934B00";
export const turismSecondary = "#FFD57E";
/* ====================== */

/* ===== PET ===== */
export const petPrimaryGreen = "#16697A";
export const petSecondaryGreen = "#91DDCF";
/* =============== */

/* ===== BEAUTY ===== */
export const beautyPrimaryPurple = "#8F3985";
export const beautySecondaryPurple = "#FFADDA";
/* ================== */

export const validAvailability = "#ACFCD9";
export const invalidAvailability = "#FCACC5";
export const validAvailabilityBorder = "#4D994F";
export const invalidAvailabilityBorder = "#9A275A";
