const calculateDistanceBetweenCoordinates = (
  x1: number,
  y1: number,
  x2: number,
  y2: number
): number => {
  const R = 6371;
  const dX = toRadians(x2 - x1);
  const dY = toRadians(y2 - y1);

  const a =
    Math.sin(dY / 2) * Math.sin(dY / 2) +
    Math.cos(toRadians(y1)) *
      Math.cos(toRadians(y2)) *
      Math.sin(dX / 2) *
      Math.sin(dX / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;

  return distance;
};

const toRadians = (degree: number): number => {
  return degree * (Math.PI / 180);
};
export default calculateDistanceBetweenCoordinates;
