import { resizeImage } from "./imageStandardizer";

export const handleSelectOriginalAndPreviewImage = async (
  e: React.ChangeEvent<HTMLInputElement>,
  setImageArray: React.Dispatch<React.SetStateAction<HTMLCanvasElement[]>>,
  originalImagesArray: HTMLCanvasElement[],
  previewImagesArray: HTMLCanvasElement[]
): Promise<void> => {
  const fileList = e.target.files;
  if (fileList) {
    const selectedImages: HTMLCanvasElement[] = [];

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const objectURL = URL.createObjectURL(file);

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (ctx) {
        const img = new Image();
        img.src = objectURL;

        await new Promise((resolve) => {
          img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);

            selectedImages.push(canvas);
            resolve(true);

            originalImagesArray.push(resizeImage(canvas, 800, 600));
            previewImagesArray.push(resizeImage(canvas, 320, 240));
          };
        });
      }
    }

    setImageArray((previousImages) => [...previousImages, ...selectedImages]);
  }
};
