export const ORIGINAL_FIREBASE_WIDTH = 800;
export const ORIGINAL_FIREBASE_HEIGHT = 600;

export const PREVIEW_FIREBASE_WIDTH = 320;
export const PREVIEW_FIREBASE_HEIGHT = 240;

export const resizeImage = (
  image: HTMLCanvasElement,
  width: number,
  height: number
): HTMLCanvasElement => {
  const resizedImage = document.createElement("canvas");
  const context = resizedImage.getContext("2d");

  const scaleX = width / image.width;
  const scaleY = height / image.height;

  const scale = Math.min(scaleX, scaleY);

  const newWidth = image.width * scale;
  const newHeight = image.height * scale;


  const offsetX = (width - newWidth) / 2;
  const offsetY = (height - newHeight) / 2;

  resizedImage.width = width;
  resizedImage.height = height;

  context.clearRect(0, 0, resizedImage.width, resizedImage.height);
  context.drawImage(image, offsetX, offsetY, newWidth, newHeight);

  return resizedImage;
};
