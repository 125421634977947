import {
  Box,
  CircularProgress,
  Divider,
  LinearProgress,
  SxProps,
  Typography,
} from "@mui/material";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import IExcursion from "../../../../services/api/Excursion/IExcursion";
import { ImageCarousel } from "../../../../components/general/ImageCarousel";
import { generalDarkGrey } from "../../../../styles/colors";
import DescriptionWrapper from "../../../../components/general/DescriptionWrapper";
import ExcursionServiceCard from "../../../../components/comercial/ExcursionServiceCard";
import { ExcursionServiceType } from "../../../../types/types";
import EventIcon from "@mui/icons-material/Event";

import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

import NearMeIcon from "@mui/icons-material/NearMe";
import dayjs from "dayjs";
import { PrimaryButton } from "../../../../components/general/buttons/PrimaryButton";
import { priceFormatter } from "../../../../utils/priceFormatter";
import { fetchExcursion } from "../../../../utils/fetchEntities";

export const ExcursionPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [excursion, setExcursion] = useState<IExcursion | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchExcursion(searchParams.get("id"), setExcursion!);
  }, []);

  useEffect(() => {
    if (excursion) setLoading(false);
  }, [excursion]);

  const renderExcursionData = (
    <Box sx={{ ...styles.singleSection, gap: 1.5 }}>
      <Box sx={styles.excursionShowcase}>
        <Box>
          <Typography variant="h4" fontWeight="bold">
            {excursion?.title}
          </Typography>
          <Typography
            textAlign="justify"
            fontWeight="bold"
            color={generalDarkGrey}
            variant="h5"
          >
            {`Categoria: ${excursion?.mainCategory}`}
          </Typography>
        </Box>

        <Box sx={styles.excursionShowcaseContent}>
          {excursion && excursion.imageOriginal?.length > 0 ? (
            <Box sx={styles.excursionImage}>
              <ImageCarousel imageUrls={excursion.imageOriginal} />
            </Box>
          ) : null}

          <Box sx={styles.excursionRelevantInfoContainer}>
            <Box sx={styles.excursionRelevantInfo}>
              <Typography>{`Agente: ${excursion?.agent}`}</Typography>
              <Box sx={{ ...styles.excursionShowcase, gap: 2 }}>
                <Box display="flex" alignItems="center" gap={1}>
                  <EventIcon />
                  <Typography textAlign="center">{`${dayjs(
                    excursion?.startDate
                  ).format("DD/MM/YYYY")} - ${dayjs(excursion?.endDate).format(
                    "DD/MM/YYYY"
                  )}`}</Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <ConfirmationNumberIcon />
                  <Typography textAlign="center">{`${
                    excursion?.maxVacancies - 18
                  } vagas disponíveis (${excursion?.maxVacancies} totais)`}</Typography>
                </Box>
              </Box>
              <Box>
                <LinearProgress
                  variant="determinate"
                  value={
                    (100 * (excursion?.maxVacancies - 18)) /
                    excursion?.maxVacancies
                  }
                  sx={styles.vacanciesBar}
                  color="secondary"
                />
              </Box>
              <Typography
                variant="h5"
                color="primary.main"
                fontWeight="bold"
              >{`R$ ${priceFormatter(excursion?.price * 100)}`}</Typography>
            </Box>
            <PrimaryButton
              label="Adicionar ao carrinho"
              styles={{ height: 50 }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ ...styles.headerContentContainer, marginTop: "40px" }}>
        <Typography variant="h6" fontWeight="bold">
          Descrição:
        </Typography>
        <Typography textAlign="justify" color={generalDarkGrey}>
          <DescriptionWrapper
            text={excursion?.description ?? "Carregando..."}
            max={300}
          />
        </Typography>
      </Box>
    </Box>
  );

  const renderExcursionDetails = (
    <>
      <Box sx={styles.excursionDetailsContainer}>
        <Box sx={styles.headerContentContainer}>
          <Typography variant="h6" fontWeight="bold">
            Quais serviços estão inclusos?
          </Typography>
          <Box sx={styles.excursionServicesContainer}>
            <ExcursionServiceCard
              serviceType={ExcursionServiceType.ACCOMMODATION}
              service={excursion?.accommodationService}
            />
            <ExcursionServiceCard
              serviceType={ExcursionServiceType.TRANSPORT}
              service={excursion?.transportService}
            />
            <ExcursionServiceCard
              serviceType={ExcursionServiceType.FOOD}
              service={excursion?.foodService}
            />
          </Box>
        </Box>

        <Box sx={styles.headerContentContainer}>
          <Typography variant="h6" fontWeight="bold">
            Restrições
          </Typography>
          <Typography color={generalDarkGrey}>
            • {excursion?.restriction}
          </Typography>
        </Box>
      </Box>
    </>
  );

  const renderExcursionGeographicData = (
    <Box>
      <Box sx={styles.headerContentContainer}>
        <Typography variant="h6" fontWeight="bold">
          {`${excursion?.city} - ${excursion?.state} (${excursion?.region}) | ${excursion?.country}`}
        </Typography>
        <Box display="flex" gap={1} alignItems="center">
          <NearMeIcon />
          <Box>
            <Typography fontWeight="bold">
              Rota da viagem:{" "}
              <span style={{ fontWeight: "normal" }}>
                {excursion?.router.map((route, index) => {
                  return index === excursion?.router.length - 1
                    ? `${route}.`
                    : `${route}, `;
                })}
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <ClientNavbar hideMidButtons />

      {!loading ? (
        <Box sx={styles.mainSectionContainer}>
          {renderExcursionData}
          <Divider />
          {renderExcursionDetails}
          <Divider />
          {renderExcursionGeographicData}
        </Box>
      ) : (
        <Box sx={{...styles.mainSectionContainer, alignItems: "center", marginTop: "100px"}}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

const styles = {
  mainSectionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    margin: "40px 100px 50px 100px",
  } as SxProps,

  singleSection: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    alignItems: "center",
  } as SxProps,

  excursionShowcase: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 4,
  } as SxProps,

  excursionShowcaseContent: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  } as SxProps,

  excursionImage: {
    width: "100%",
  } as SxProps,

  excursionRelevantInfoContainer: {
    width: "50%",
    margin: "0px 50px",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  } as SxProps,

  excursionRelevantInfo: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
  } as SxProps,

  excursionDetailsContainer: {
    display: "flex",
    gap: 15,
    justifyContent: "center",
  } as SxProps,

  excursionServicesContainer: {
    display: "flex",
    gap: 3,
  } as SxProps,

  headerContentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
  } as SxProps,

  vacanciesBar: {
    height: 10,
  },
};
