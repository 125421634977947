import {
  Avatar,
  Box,
  FormControl,
  IconButton,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Dayjs } from "dayjs";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import appointmentApi from "../../../../services/api/Appointment";
import employeeApi from "../../../../services/api/Employee";
import serviceStoreApi from "../../../../services/api/ServiceStore";
import { IStore } from "../../../../services/api/Store/types";
import { generalDarkGrey } from "../../../../styles/colors";
import { createAppointmentModalSchema } from "../../../../utils/yupSchemas";
import CloseIcon from "@mui/icons-material/Close";

interface CreateAppointmentModalProps {
  userId: string;
  userStores: IStore[];
  selectedDate: Dayjs | null;
  defaultStoreId?: string;
  onClose?: () => void;
}

const CreateAppointmentModal = (props: CreateAppointmentModalProps) => {
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectedServiceStoreId, setSelectedServiceStoreId] = useState("");

  const [selectedAppointmentTime, setSelectedAppointmentTime] = useState("");

  const [selectedStoreEmployees, setSelectedStoreEmployees] = useState([]);
  const [selectedEmployeeServiceStores, setSelectedEmployeeServiceStores] =
    useState([]);

  const [
    selectedEmployeeServiceEmployees,
    setSelectedEmployeeServiceEmployees,
  ] = useState([]);

  const [
    selectedServiceEmployeeAvailableTimes,
    setSelectedServiceEmployeeAvailableTimes,
  ] = useState([]);

  const clientNameRef = useRef<HTMLInputElement>(null);

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(createAppointmentModalSchema) });

  const handleCreateAppointment = async () => {
    try {
      const serviceEmployeeId = selectedEmployeeServiceEmployees.filter(
        (serviceEmployee) =>
          serviceEmployee.serviceStoreId === selectedServiceStoreId
      )[0].id;
      const clientName = clientNameRef?.current?.value;
      const appointmentDate = selectedAppointmentTime.value;

      const createdAppointmentResponse = await appointmentApi.createAppointment(
        {
          serviceEmployeeId: serviceEmployeeId,
          clientName: clientName,
          date: appointmentDate,
        }
      );
      console.log(createdAppointmentResponse);
      location.reload();
    } catch (error) {
      console.warn("Error creating an appointment: ", error);
    }
  };

  const checkIfUserIsSelectedStoreManager = () => {
    const selectedStoreManagerId = props.userStores.filter(
      (store) => store.id === selectedStoreId
    )[0].managerId;

    return props.userId === selectedStoreManagerId;
  };

  const fetchStoreEmployees = async () => {
    try {
      const employeesResponse =
        await employeeApi.getEmployeesFromStoreId(selectedStoreId);

      if (checkIfUserIsSelectedStoreManager()) {
        setSelectedStoreEmployees(employeesResponse);
        setSelectedEmployeeId(employeesResponse[0].id);
      } else {
        const userSelectedStoreEmployee = employeesResponse.filter(
          (employee) => employee.userId === props.userId
        );
        setSelectedStoreEmployees(userSelectedStoreEmployee);
        setSelectedEmployeeId(userSelectedStoreEmployee[0].id);
      }
    } catch (error) {
      console.warn("Error fetching selected store employees: ", error);
    }
  };

  const fetchServiceStoresFromEmployee = async () => {
    try {
      const selectedEmployeeServiceEmployeesFiltered =
        selectedStoreEmployees.filter(
          (employee) => employee.id === selectedEmployeeId
        )[0].ServiceEmployee;

      setSelectedEmployeeServiceEmployees(
        selectedEmployeeServiceEmployeesFiltered
      );
      const serviceStores = [];
      let iterableServiceStore = null;
      for (
        let i = 0;
        i < selectedEmployeeServiceEmployeesFiltered.length;
        i++
      ) {
        iterableServiceStore = await serviceStoreApi.getServiceStore(
          selectedEmployeeServiceEmployeesFiltered[i].serviceStoreId
        );
        serviceStores.push(iterableServiceStore);
      }
      setSelectedEmployeeServiceStores(serviceStores);
    } catch (error) {
      console.warn("Error fetching service stores from employee: ", error);
    }
  };

  const fetchAvailableTimesFromAServiceEmployee = async () => {
    try {
      const formattedDate = props.selectedDate?.toISOString();
      const serviceEmployeeId = selectedEmployeeServiceEmployees.filter(
        (serviceEmployee) =>
          serviceEmployee.serviceStoreId === selectedServiceStoreId
      )[0].id;

      const serviceEmployeeAvailableTimesResponse =
        await appointmentApi.getServiceEmployeeAvailableTimesInADate(
          formattedDate,
          serviceEmployeeId
        );

      const availableTimes = serviceEmployeeAvailableTimesResponse.filter(
        (time) => time.available
      );

      setSelectedServiceEmployeeAvailableTimes(availableTimes);
    } catch (error) {
      console.warn("Error fetching service employee available times: ", error);
    }
  };

  useEffect(() => {
    fetchStoreEmployees();
  }, [selectedStoreId]);

  useEffect(() => {
    fetchServiceStoresFromEmployee();
  }, [selectedEmployeeId]);

  useEffect(() => {
    fetchAvailableTimesFromAServiceEmployee();
  }, [selectedServiceStoreId]);

  useEffect(() => {
    if (
      props.userStores.some((store) => store.id === props.defaultStoreId) &&
      props.defaultStoreId !== "-"
    ) {
      setSelectedStoreId(props.defaultStoreId);
    }
  }, []);
  return (
    <form
      onSubmit={onSubmit(handleCreateAppointment)}
      style={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <Box margin="-20px " alignSelf="flex-end">
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography variant="h5" fontWeight="bold">
        Criação de agendamento
      </Typography>
      <Typography color={generalDarkGrey}>
        Para {props.selectedDate?.format("DD/MM/YYYY")}
      </Typography>
      <Box display="flex" flexDirection="column" gap={2} margin="25px 0px">
        <FormControl size="small" fullWidth sx={{ height: "50px" }}>
          <InputLabel>Estabelecimento *</InputLabel>
          <Select
            label="Estabelecimento *"
            value={selectedStoreId}
            onChange={(e) => {
              setSelectedStoreId(e.target.value);
            }}
          >
            {props.userStores.map((store, index) => (
              <MenuItem value={props.userStores[index]?.id} key={store.id}>
                <ListItem disablePadding>
                  <ListItemText primary={store.name} />
                </ListItem>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          disabled={
            selectedStoreId === "" || !checkIfUserIsSelectedStoreManager()
          }
          fullWidth
          sx={{ height: "73px" }}
        >
          <InputLabel>Funcionário *</InputLabel>
          <Select
            label="Funcionário *"
            value={selectedEmployeeId}
            onChange={(e) => {
              setSelectedEmployeeId(e.target.value);
            }}
          >
            {selectedStoreEmployees.map((employee, index) => (
              <MenuItem
                value={selectedStoreEmployees[index]?.id}
                key={employee.id}
              >
                <ListItem disablePadding>
                  <ListItemAvatar>
                    <Avatar src={employee.User.imagePreview} />
                  </ListItemAvatar>
                  <ListItemText primary={employee.User.name} />
                </ListItem>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small" fullWidth sx={{ height: "50px" }}>
          <InputLabel>Serviço *</InputLabel>
          <Select
            label="Serviço *"
            value={selectedServiceStoreId}
            onChange={(e) => {
              setSelectedServiceStoreId(e.target.value);
            }}
          >
            {selectedEmployeeServiceStores.map((serviceStore, index) => (
              <MenuItem value={serviceStore.id} key={index}>
                <ListItem disablePadding>
                  <ListItemText primary={serviceStore.Service.name} />
                </ListItem>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{ height: "65px" }}
          size="small"
          fullWidth
          disabled={selectedServiceEmployeeAvailableTimes.length <= 0}
        >
          <InputLabel>Horário *</InputLabel>
          <Select
            label="Horário *"
            value={selectedAppointmentTime}
            onChange={(e) => {
              setSelectedAppointmentTime(e.target.value);
            }}
          >
            {selectedServiceEmployeeAvailableTimes.map((time, index) => (
              <MenuItem value={time} key={index}>
                <ListItem disablePadding>
                  <ListItemText primary={time.time} />
                </ListItem>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Nome do cliente *"
          fullWidth
          size="small"
          inputRef={clientNameRef}
          {...register("clientName")}
          error={!!errors.clientName}
          helperText={errors.clientName?.message}
          sx={{ height: "50px" }}
        />
      </Box>

      <LoadingButton
        variant="contained"
        disableElevation
        fullWidth
        sx={{ textTransform: "none" }}
        type="submit"
        startIcon={<EventAvailableIcon />}
      >
        Confirmar agendamento
      </LoadingButton>
    </form>
  );
};

export default CreateAppointmentModal;
