import { LoadingButton } from "@mui/lab";
import { SxProps } from "@mui/material";
import { ReactNode } from "react";

interface PrimaryButtonProps{
  label: string;
  onClickAction?: any;
  isLoading?: boolean;
  icon?: ReactNode;
  isDisabled?: boolean;
  styles?: SxProps;
}

export const PrimaryButton = (props: PrimaryButtonProps) => {
  return (
    <LoadingButton
      variant="contained"
      disableElevation
      sx={{ ...props.styles, textTransform: "none", height: "50px" }}
      startIcon={props.icon}
      loading={props.isLoading}
      loadingPosition="start"
      type="submit"
      fullWidth
      onClick={props.onClickAction}
      disabled={props.isDisabled}
    >
      {props.label}
    </LoadingButton>
  );
};
