import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { useNavigate } from "react-router-dom";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import { Button, Tooltip, Typography } from "@mui/material";
import { generalBlack, generalWhite } from "../../../../styles/colors";
import { UserRole } from "../../../../types/types";
import {
  CURRENT_SUBDOMAIN,
  DEMANDORIA_SUBDOMAINS,
} from "../../../../utils/stringUtils";
import { PrimaryButton } from "../../buttons/PrimaryButton";

interface MainNavBarProps {
  isUserComercial?: boolean;
  hideMidButtons?: boolean;
}

export default function ClientNavbar(props: MainNavBarProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const auth = useAuth();
  const navigate = useNavigate();

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMyProfileClick = () => {
    navigate("/user/details");
  };

  const handleLogoutClick = () => {
    const confirm: boolean = window.confirm("Tem certeza que deseja sair?");

    if (confirm) {
      handleMenuClose();
      auth.logout();
      localStorage.removeItem("token");
      navigate("/login");
    }
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMyProfileClick();
        }}
        sx={{ display: "flex", gap: "10px" }}
      >
        <PersonIcon />
        Minha conta
      </MenuItem>
      <hr />
      {!props && (
        <MenuItem onClick={handleMenuClose}>Histórico de serviços</MenuItem>
      )}
      <MenuItem
        onClick={() => {
          handleLogoutClick();
        }}
        sx={{ display: "flex", gap: "10px", color: "red" }}
      >
        <LogoutIcon />
        Sair
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {CURRENT_SUBDOMAIN !== DEMANDORIA_SUBDOMAINS[2]? <MenuItem
        onClick={() => {
          navigate("/my-appointments");
        }}
      >
        <IconButton size="large">
          <CalendarMonthIcon sx={{ color: generalBlack }} />
        </IconButton>
        <p>Agendamentos</p>
      </MenuItem> : null}
      {!props.isUserComercial && (
        <MenuItem
          onClick={() => {
            navigate("/cart");
          }}
        >
          <IconButton size="large">
            <ShoppingCartIcon sx={{ color: generalBlack }} />
          </IconButton>
          <p>Carrinho de compras</p>
        </MenuItem>
      )}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton size="large">
          <AccountCircle sx={{ color: generalBlack }} />
        </IconButton>
        <p>Meu perfil</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "primary.main",
          width: "100vw",
        }}
      >
        <Toolbar>
          <Button
            sx={{ textTransform: "none", color: generalWhite }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => {
              auth.user.role === UserRole.CLIENT
                ? navigate("/home")
                : CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[0] ||
                    CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[1]
                  ? navigate("/stores")
                  : navigate("/agencies");
            }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ "@media(max-width:500px)": { display: "none" } }}
            >
              Demandoria
            </Typography>
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ "@media(min-width:501px)": { display: "none" } }}
            >
              D
            </Typography>
          </Button>

          <Box sx={{ flexGrow: 1 }} />
          {!props.hideMidButtons ? (
            <>
              <Box>
                <PrimaryButton
                  label="Busca avançada"
                  icon={<ManageSearchIcon />}
                  onClickAction={() => {
                    navigate("/search");
                  }}
                  styles={{
                    backgroundColor: "secondary.main",
                    color: "primary.main",
                    ":hover": {
                      color: generalWhite,
                    },
                    height: "45px"
                  }}
                />
              </Box>
            </>
          ) : (
            <></>
          )}

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
          {CURRENT_SUBDOMAIN !== DEMANDORIA_SUBDOMAINS[2]?
            <Tooltip title="Agendamentos">
              <IconButton
                size="large"
                onClick={() => {
                  navigate("/my-appointments");
                }}
              >
                <CalendarMonthIcon sx={{ color: generalWhite }} />
              </IconButton>
            </Tooltip> : null}
            {!props.isUserComercial && (
              <Tooltip title="Carrinho de compras">
                <IconButton
                  size="large"
                  onClick={() => {
                    navigate("/cart");
                  }}
                >
                  <ShoppingCartIcon sx={{ color: generalWhite }} />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Minha conta">
              <IconButton size="large" onClick={handleProfileMenuOpen}>
                <AccountCircle sx={{ color: generalWhite }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton size="large" onClick={handleMobileMenuOpen}>
              <MoreIcon sx={{ color: generalWhite }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
