import { Box, Card, CardContent, Typography } from "@mui/material";
import { ExcursionServiceType } from "../../../types/types";
import { generalDarkGrey } from "../../../styles/colors";
import HotelIcon from "@mui/icons-material/Hotel";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import RestaurantIcon from "@mui/icons-material/Restaurant";

interface ExcursionServiceCardProps {
  serviceType: ExcursionServiceType;
  service: string;
}
const ExcursionServiceCard = (props: ExcursionServiceCardProps) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            display="flex"
            flexDirection="column"
            gap={1.5}
            alignItems="center"
          >
            {props.serviceType === ExcursionServiceType.ACCOMMODATION ? (
              <>
                <HotelIcon />
                <Box>
                  <Typography fontWeight="bold" textAlign="center">
                    {props.service}
                  </Typography>
                  <Typography textAlign="center" color={generalDarkGrey}>
                    Hospedagem
                  </Typography>
                </Box>
              </>
            ) : props.serviceType === ExcursionServiceType.FOOD ? (
              <>
                <RestaurantIcon />
                <Box>
                  <Typography fontWeight="bold" textAlign="center">
                    {props.service}
                  </Typography>
                  <Typography textAlign="center" color={generalDarkGrey}>
                    Alimentação
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <DirectionsBusIcon />
                <Box>
                  <Typography fontWeight="bold" textAlign="center">
                    {props.service}
                  </Typography>
                  <Typography textAlign="center" color={generalDarkGrey}>
                    Transporte
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ExcursionServiceCard;
