import { Box, Typography, TextField, Button } from "@mui/material";
import { useRef, useState } from "react";
import GenericNavbar from "../../../components/general/Navbars/GenericNavbar";
import { dmPrimaryOrange } from "../../../styles/colors";
import InputMask from "react-input-mask";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import PasswordIcon from "@mui/icons-material/Password";
import PasswordRecoveryIcon from "../../../assets/img/PasswordRecovery.png";

import {
  firstStepPasswordRecoverySchema,
  secondStepPasswordRecoverySchema,
} from "../../../utils/yupSchemas";
import { useNavigate } from "react-router-dom";
import { userApi } from "../../../services";
import { PrimaryButton } from "../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../components/general/buttons/SecondaryButton";
import BackPageButton from "../../../components/general/buttons/BackPageButton";

const PasswordRecovery = () => {
  const navigate = useNavigate();

  const [
    selectedPasswordEditionStepIndex,
    setSelectedPasswordEditionStepIndex,
  ] = useState(0);

  const [selectedEmailForPasswordEdition, setSelectedEmailForPasswordEdition] =
    useState("");

  const passwordEditionEmailRef = useRef<HTMLInputElement>(null);

  const [isVerificationCodeValid, setIsVerificationCodeValid] =
    useState<boolean>(true);
  const [isEmailAddressValid, setIsEmailAddressValid] = useState<boolean>(true);

  const verificationCodeRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      selectedPasswordEditionStepIndex === 0
        ? firstStepPasswordRecoverySchema
        : secondStepPasswordRecoverySchema
    ),
  });

  const handleFirstStepSubmit = async () => {
    setIsEmailAddressValid(true);
    setSelectedEmailForPasswordEdition(passwordEditionEmailRef.current?.value);
    const emailRequestBody = { email: passwordEditionEmailRef.current?.value };
    try {
      const response = await userApi.sendPasswordRecoveryCode(emailRequestBody);
      console.log(response);
      setSelectedPasswordEditionStepIndex(1);
    } catch (error) {
      console.warn(
        `Error sending verification code to ${selectedEmailForPasswordEdition}: `,
        error
      );
      setIsEmailAddressValid(false);
    }
  };

  const handleSecondStepSubmit = async () => {
    const formattedVerificationCode = verificationCodeRef.current?.value
      .replaceAll("-", "")
      .replaceAll(" ", "")
      .replaceAll("_", "");

    if (formattedVerificationCode?.length !== 6) {
      setIsVerificationCodeValid(false);
    } else {
      setIsVerificationCodeValid(true);

      const validationRequestBody = {
        token: formattedVerificationCode,
        email: selectedEmailForPasswordEdition,
        password: newPasswordRef.current?.value,
      };

      try {
        const response = await userApi.validatePasswordRecovery(
          validationRequestBody
        );
        console.log(response);
        navigate("/login");
        alert("Senha alterada com sucesso!");
      } catch (error) {
        console.warn(`Error confirming the new password: `, error);
        setIsVerificationCodeValid(false);
      }
    }
  };

  const renderFirstStepPasswordEdition = (
    <form onSubmit={onSubmit(handleFirstStepSubmit)}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Typography sx={{ marginBottom: "50px" }}>
          Digite o email para enviarmos o código de verificação
        </Typography>

        <Box display="flex" flexDirection="column" width="90%" gap={2}>
          <TextField
            label="Digite seu email"
            inputRef={passwordEditionEmailRef}
            {...register("email")}
            error={!!errors.email ? errors.email : !isEmailAddressValid}
            helperText={
              errors.email?.message
                ? errors.email?.message
                : !isEmailAddressValid
                  ? "Email não cadastrado."
                  : ""
            }
            sx={{ height: "60px" }}
          />
          <PrimaryButton
            label="Confirmar"
            styles={{ height: "50px", marginTop: "20px" }}
          />
        </Box>
      </Box>
    </form>
  );

  const renderSecondStepPasswordEdition = (
    <form onSubmit={onSubmit(handleSecondStepSubmit)}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Typography sx={{ color: "primary.main", fontWeight: "bold" }}>
          {selectedEmailForPasswordEdition}
        </Typography>

        <Box margin="30px 0px" width="90%">
          <InputMask
            mask="9  -  9  -  9  -  9  -  9  -  9"
            disabled={false}
            maskchar="_"
            inputRef={verificationCodeRef}
            error={!isVerificationCodeValid}
            helperText={
              !isVerificationCodeValid
                ? "Código de verificação inválido."
                : null
            }
          >
            <TextField
              label="Código de verificação"
              fullWidth
              sx={{ height: "80px" }}
            />
          </InputMask>

          <TextField
            label="Digite a nova senha"
            type="password"
            {...register("password")}
            error={!!errors.password}
            helperText={errors.password?.message}
            inputRef={newPasswordRef}
            sx={{ height: "60px" }}
            fullWidth
          />
        </Box>

        <Box display="flex" flexDirection="column" gap={1} width="90%">
          <PrimaryButton
            label="Alterar senha"
            icon={<PasswordIcon />}
            styles={{ height: "50px" }}
          />
          <SecondaryButton
            label="Voltar"
            onClickAction={() => {
              setSelectedPasswordEditionStepIndex(0);
              setIsVerificationCodeValid(true);
            }}
          />
        </Box>
      </Box>
    </form>
  );
  return (
    <Box
      sx={{
        overflowX: "hidden",
        height: "100vh",
      }}
    >
      <GenericNavbar noInteractions />
      <BackPageButton containerStyles={undefined} iconStyles={undefined}/>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        margin="70px 0px"
      >
        <img
          src={PasswordRecoveryIcon}
          alt="password recovery"
          width={150}
          style={{ marginBottom: "20px" }}
        />
        <Typography variant="h5" fontWeight="bold" margin="10px 0px">
          {selectedPasswordEditionStepIndex === 0
            ? "Recuperação de senha"
            : "Verifique o código enviado para:"}
        </Typography>
        {selectedPasswordEditionStepIndex === 0
          ? renderFirstStepPasswordEdition
          : selectedPasswordEditionStepIndex === 1
            ? renderSecondStepPasswordEdition
            : null}
      </Box>
    </Box>
  );
};

export default PasswordRecovery;
