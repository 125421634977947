import { Chip } from "@mui/material";
import { AppointmentStatus } from "../../../types/types";
import { generalBrightGrey, dmYellow } from "../../../styles/colors";

interface AppointmentStatusChip {
  status: string;
}

const AppointmentStatusChip = (props: AppointmentStatusChip) => {
  let chipLabel = "";
  let chipColor = "";

  switch (props.status) {
    case AppointmentStatus.PENDENT:
      chipLabel = "Pendente";
      chipColor = dmYellow;
      break;
    case AppointmentStatus.CANCELED:
      chipLabel = "Cancelado";
      chipColor = "#FCACC5";
      break;
    case AppointmentStatus.CONFIRMED:
      chipLabel = "Confirmado";
      chipColor = "#ACFCD9";
      break;
    case AppointmentStatus.FINISHED:
      chipLabel = "Finalizado";
      chipColor = generalBrightGrey;
      break;
  }

  return (
    <Chip
      label={chipLabel}
      sx={{ backgroundColor: chipColor, fontWeight: "bold" }}
    />
  );
};

export default AppointmentStatusChip;
