import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Alert, Box, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { uploadImagesToFirebase } from "../../../../../utils/firebase";
import CEODrawer from "../../../../../components/turism/CEODrawer";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import WorkingScaleSelector from "../../../../../components/comercial/WorkingScaleSelector";
import storeApi from "../../../../../services/api/Store";
import {
  generalDarkGrey,
} from "../../../../../styles/colors";
import {
  AddressProps,
  getCoordinatesByAddress,
} from "../../../../../hooks/getCoodinatesByAddress";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  DEFAULT_WORKING_TIME,
  POSSIBLE_WORKING_TIMES,
} from "../../workingTimeConstants";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../../../components/general/buttons/SecondaryButton";
import ImageGalleryHeader from "../../../../../components/comercial/ImageGallery/ImageGalleryHeader";
import ImageGallery from "../../../../../components/comercial/ImageGallery";
import {
  checkIfCepInputWasFilledAfterFetching,
  fetchAddressByCEP,
} from "../../../../../utils/cepUtils";
import { BrazilianAddress } from "../../../../../types/types";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import printResponseError from "../../../../../utils/printResponseError";
import { IStore } from "../../../../../services/api/Store/types";
import { addAndEditStoreSchema, showCustomInputFieldError, validateCnpj, validatePhoneNumber } from "../../../../petAndBeauty/CEOPages/StoresPages/storeValidationSchemas";
import WorkingScaleCaption from "../../../../../components/comercial/WorkingScaleSelector/WorkingScaleCaption";
import { CURRENT_SUBDOMAIN } from "../../../../../utils/stringUtils";


const AddAgency = () => {
  const navigate = useNavigate();

  const storeNameRef = useRef<HTMLInputElement>(null);
  const [cnpj, setCnpj] = useState<string>("");
  const cnpjRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const [phone, setPhone] = useState<string>("");
  const phoneRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);

  const streetRef = useRef<HTMLInputElement>(null);
  const numberRef = useRef<HTMLInputElement>(null);
  const districtRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);

  const [cep, setCep] = useState<string>("");

  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);

  const [images, setImages] = useState<HTMLCanvasElement[]>([]);

  const [originalImages, setOriginalImages] = useState<HTMLCanvasElement[]>([]);
  const [previewImages, setPreviewImages] = useState<HTMLCanvasElement[]>([]);

  const originalImageUrls = [];
  const previewImageUrls = [];

  const [timeMonday, setTimeMonday] = useState<string[]>(DEFAULT_WORKING_TIME);
  const [timeTuesday, setTimeTuesday] =
    useState<string[]>(DEFAULT_WORKING_TIME);
  const [timeWednesday, setTimeWednesday] =
    useState<string[]>(DEFAULT_WORKING_TIME);
  const [timeThursday, setTimeThursday] =
    useState<string[]>(DEFAULT_WORKING_TIME);
  const [timeFriday, setTimeFriday] = useState<string[]>(DEFAULT_WORKING_TIME);
  const [timeSaturday, setTimeSaturday] = useState<string[]>([]);
  const [timeSunday, setTimeSunday] = useState<string[]>([]);

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  const [wasCepFetched, setWasCepFetched] = useState(false);

  const [generalErrorMessage, setGeneralErrorMessage] = useState("");

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addAndEditStoreSchema) });

  const handleStoreCreation = async () => {
    setGeneralErrorMessage("");

    if (submittedOnce === false) setSubmittedOnce(true);

    const isCnpjValid = validateCnpj(cnpj);
    const isPhoneValid = validatePhoneNumber(phone);

    if (isCnpjValid && isPhoneValid) {
      setButtonsDisabled(!buttonsDisabled);
      try {
        const coordinatesResponse = await getCoordinatesByAddress({
          street: streetRef?.current?.value,
          number: numberRef?.current?.value,
          district: districtRef?.current?.value,
          city: cityRef?.current?.value,
          state: stateRef?.current?.value,
          country: "Brazil",
          postalCode: cep,
        } as AddressProps);

        const latitude = coordinatesResponse[0];
        const longitude = coordinatesResponse[1];

        const newStore: IStore = {
          name: storeNameRef?.current?.value,
          email: emailRef?.current?.value,
          CNPJ: cnpj,
          phone: phoneRef?.current?.value,
          description: descriptionRef?.current?.value,
          logradouro: streetRef?.current?.value,
          number: numberRef?.current?.value,
          district: districtRef?.current?.value,
          city: cityRef?.current?.value,
          state: stateRef?.current?.value,
          CEP: cep,
          country: "Brazil",
          lat: Number(latitude),
          lng: Number(longitude),
          imageOriginal: [],
          imagePreview: [],
          listTags: "",
          timeMonday,
          timeTuesday,
          timeWednesday,
          timeThursday,
          timeFriday,
          timeSaturday,
          timeSunday,
        };

        const createdStoreResponse = await storeApi.createStore(newStore);

        if (images.length > 0) {
          const originalUuids = await uploadImagesToFirebase(
            originalImages,
            originalImageUrls,
            `${CURRENT_SUBDOMAIN}/stores/${createdStoreResponse.name}_${createdStoreResponse.id}/store-images`,
            "_original",
            []
          );
          await uploadImagesToFirebase(
            previewImages,
            previewImageUrls,
            `${CURRENT_SUBDOMAIN}/stores/${createdStoreResponse.name}_${createdStoreResponse.id}/store-images`,
            "_preview",
            originalUuids
          );

          newStore.imageOriginal = originalImageUrls;
          newStore.imagePreview = previewImageUrls;

          await storeApi.updateStore(createdStoreResponse.id, newStore);
        }

        navigate("/agencies");
      } catch (error) {
        setButtonsDisabled(false);
        printResponseError("Error adding store: ", error);
        setGeneralErrorMessage(error.response.data.message);
      }
    } else {
      !isCnpjValid ? cnpjRef.current.focus() : null;
      !isPhoneValid ? phoneRef.current.focus() : null;
    }
  };

  const cleanAddressFields = () => {
    streetRef.current.value = "";
    districtRef.current.value = "";
    cityRef.current.value = "";
    stateRef.current.value = "";
  };

  useEffect(() => {
    const fetchAddress = async () => {
      if (cep.replaceAll("_", "").length === 9) {
        try {
          const fetchedAddress: BrazilianAddress = await fetchAddressByCEP(cep);

          streetRef.current.value = fetchedAddress.street;
          districtRef.current.value = fetchedAddress.neighborhood;
          cityRef.current.value = fetchedAddress.city;
          stateRef.current.value = fetchedAddress.state;

          setWasCepFetched(true);
        } catch (error) {
          console.error("Error fetching address from CEP: ", error);
        }
      } else {
        setWasCepFetched(false);
        cleanAddressFields();
      }
    };

    fetchAddress();
  }, [cep]);

  const renderStoreData = (
    <>
      <CEOInterfaceHeader title="Dados do estabelecimento" />
      <Box display="flex" flexDirection="column" gap={2} margin="25px">
        <Box
          display="flex"
          gap={2}
          sx={{
            "@media (max-width:820px)": {
              flexDirection: "column",
            },
          }}
        >
          <TextField
            size="small"
            label="Nome do estabelecimento *"
            inputRef={storeNameRef}
            {...register("storeName")}
            error={!!errors.storeName}
            helperText={errors.storeName?.message}
            fullWidth
          />
          <InputMask
            mask="99.999.999/9999-99"
            disabled={false}
            maskChar="_"
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
            error={showCustomInputFieldError(
              !validateCnpj(cnpj),
              submittedOnce
            )}
            helperText={
              showCustomInputFieldError(!validateCnpj(cnpj), submittedOnce)
                ? "CNPJ inválido."
                : ""
            }
            inputRef={cnpjRef}
          >
            <TextField size="small" fullWidth label="CNPJ *" />
          </InputMask>
        </Box>

        <Box
          display="flex"
          gap={2}
          sx={{
            "@media (max-width:820px)": {
              flexDirection: "column",
            },
          }}
        >
          <InputMask
            mask="(99) 9.9999-9999"
            disabled={false}
            maskChar="_"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            error={showCustomInputFieldError(
              !validatePhoneNumber(phone),
              submittedOnce
            )}
            helperText={
              showCustomInputFieldError(
                !validatePhoneNumber(phone),
                submittedOnce
              )
                ? "Telefone inválido."
                : ""
            }
            inputRef={phoneRef}
          >
            <TextField label="Telefone *" size="small" fullWidth />
          </InputMask>
          <TextField
            size="small"
            label="Email para contato *"
            placeholder="Ex.: contato@email.com"
            fullWidth
            inputRef={emailRef}
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Box>
        <TextField
          size="small"
          label="Descrição"
          placeholder="Descreva brevemente seu estabelecimento..."
          fullWidth
          multiline
          rows={5}
          inputRef={descriptionRef}
        />
      </Box>
    </>
  );

  const renderAddressData = (
    <>
      <CEOInterfaceHeader title="Dados geográficos do estabelecimento" />
      <Box display="flex" flexDirection="column" gap={2} margin="25px">
        <InputMask
          mask="99999-999"
          disabled={false}
          maskChar="_"
          value={cep}
          onChange={(e) => {
            setCep(e.target.value);
          }}
        >
          {<TextField size="small" label="CEP" fullWidth />}
        </InputMask>
        <Box display="flex" width="100%" gap={2}>
          <TextField
            size="small"
            label="Logradouro"
            inputRef={streetRef}
            sx={{ width: "75%" }}
            InputLabelProps={{ shrink: true }}
            disabled={checkIfCepInputWasFilledAfterFetching(
              wasCepFetched,
              streetRef
            )}
          />
          <TextField
            size="small"
            label="N°"
            inputRef={numberRef}
            sx={{ width: "25%" }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <TextField
          size="small"
          label="Bairro"
          fullWidth
          inputRef={districtRef}
          InputLabelProps={{ shrink: true }}
          disabled={checkIfCepInputWasFilledAfterFetching(
            wasCepFetched,
            districtRef
          )}
        />
        <TextField
          size="small"
          label="Cidade"
          fullWidth
          inputRef={cityRef}
          InputLabelProps={{ shrink: true }}
          disabled={checkIfCepInputWasFilledAfterFetching(
            wasCepFetched,
            cityRef
          )}
        />

        <Box display="flex" width="100%" gap={2}>
          <TextField
            size="small"
            label="Estado"
            fullWidth
            inputRef={stateRef}
            InputLabelProps={{ shrink: true }}
            disabled={checkIfCepInputWasFilledAfterFetching(
              wasCepFetched,
              stateRef
            )}
          />
        </Box>
      </Box>
    </>
  );

  const renderImageGalery = (
    <>
      <ImageGalleryHeader
        label="Adicionar"
        icon={<AddPhotoAlternateIcon />}
        setImages={setImages}
        originalImages={originalImages}
        previewImages={previewImages}
      />
      <Box display="flex" flexDirection="column" gap={2} margin="25px">
        <ImageGallery
          images={images}
          setImages={setImages}
          setOriginalImages={setOriginalImages}
          setPreviewImages={setPreviewImages}
          interable
          emptyGalleryText={
            <Typography>
              Adicione imagens do seu estabelecimento!
            </Typography>
          }
        />
      </Box>
    </>
  );

  const renderWorkScaleSelector = (
    <>
      <CEOInterfaceHeader title="Horários de funcionamento" />
      <Box color={generalDarkGrey}>
        <Typography>
          Horário padrão de funcionamento: <b>7:00-11:00</b> e{" "}
          <b>13:00-17:00</b>.
        </Typography>
        <Box display="flex" gap={0.5}>
          <Typography>Sinta-se à vontade para personalizá-lo!</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        margin="25px 0px"
        maxWidth="87vw"
      >
        <WorkingScaleSelector
          timeMonday={timeMonday}
          setTimeMonday={setTimeMonday}
          timeTuesday={timeTuesday}
          setTimeTuesday={setTimeTuesday}
          timeWednesday={timeWednesday}
          setTimeWednesday={setTimeWednesday}
          timeThursday={timeThursday}
          setTimeThursday={setTimeThursday}
          timeFriday={timeFriday}
          setTimeFriday={setTimeFriday}
          timeSaturday={timeSaturday}
          setTimeSaturday={setTimeSaturday}
          timeSunday={timeSunday}
          setTimeSunday={setTimeSunday}
          possibleMondayTimes={POSSIBLE_WORKING_TIMES}
          possibleTuesdayTimes={POSSIBLE_WORKING_TIMES}
          possibleWednesdayTimes={POSSIBLE_WORKING_TIMES}
          possibleThursdayTimes={POSSIBLE_WORKING_TIMES}
          possibleFridayTimes={POSSIBLE_WORKING_TIMES}
          possibleSaturdayTimes={POSSIBLE_WORKING_TIMES}
          possibleSundayTimes={POSSIBLE_WORKING_TIMES}
        />
        <WorkingScaleCaption />
      </Box>
    </>
  );

  const renderAddStorePageContent = (
    <form onSubmit={onSubmit(handleStoreCreation)}>
      {renderStoreData}
      {renderAddressData}
      {renderImageGalery}
      {renderWorkScaleSelector}
      {generalErrorMessage !== "" ? (
        <Alert severity="error">{generalErrorMessage}</Alert>
      ) : null}
      <Box
        display="flex"
        justifyContent="space-between"
        gap={2}
        sx={{
          margin: "40px 0px",
          "@media (max-width: 800px)": {
            flexDirection: "column",
          },
        }}
      >
        <PrimaryButton
          label="Confirmar adição"
          isDisabled={buttonsDisabled}
          isLoading={buttonsDisabled}
          icon={<AddBusinessIcon />}
        />
        <SecondaryButton
          label="Cancelar"
          onClickAction={() => navigate("/agencies")}
          isDisabled={buttonsDisabled}
        />
      </Box>
    </form>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderAddStorePageContent} />
    </Box>
  );
};

export default AddAgency;
