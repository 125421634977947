import { ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./context/AuthProvider";
import AppRoutes from "./routes";
import { beautyTheme, petTheme, turismTheme } from "./styles/themes";
import { Box } from "@mui/material";
import {
  DEMANDORIA_SUBDOMAINS,
  getDemandoriaSubdomain,
} from "./utils/stringUtils";

const App = () => {
  const currentSubdomain = getDemandoriaSubdomain();

  return (
    <Box>
      <AuthProvider>
        {/* geralmente o erro de styled_function (Popper.js) 
        é derivado da mudança de localização do ThemeProvider,
        para correção, realizar a reimportação. */}
        <ThemeProvider
          theme={
            currentSubdomain === DEMANDORIA_SUBDOMAINS[0]
              ? beautyTheme
              : currentSubdomain === DEMANDORIA_SUBDOMAINS[1]
                ? petTheme
                : turismTheme
          }
        >
          <AppRoutes />
        </ThemeProvider>
      </AuthProvider>
    </Box>
  );
};

export default App;
