import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Rating,
  Select,
  SxProps,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { useEffect, useState } from "react";
import { ImageCarousel } from "../../../../components/general/ImageCarousel";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { userApi } from "../../../../services";
import employeeApi from "../../../../services/api/Employee";
import { IEmployee } from "../../../../services/api/Employee/types";
import serviceEmployeeApi from "../../../../services/api/ServiceEmployee";
import { IServiceEmployee } from "../../../../services/api/ServiceEmployee/types";
import { IServiceStore } from "../../../../services/api/ServiceStore/types";
import { IUser } from "../../../../services/api/User/types";
import { priceFormatter } from "../../../../utils/priceFormatter";
import DescriptionWrapper from "../../../../components/general/DescriptionWrapper";
import BackPageButton from "../../../../components/general/buttons/BackPageButton";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import ratingApi from "../../../../services/api/Rating";
import { IRating } from "../../../../services/api/Rating/types";
import { PrimaryButton } from "../../../../components/general/buttons/PrimaryButton";
import { IStore } from "../../../../services/api/Store/types";
import {
  fetchServiceStore,
  fetchServiceStoreRating,
  fetchStore,
} from "../../../../utils/fetchEntities";

export const ServicePage = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const defaultEmployeeIdParam = searchParams.get("employeeId");

  const serviceId = searchParams.get("id");

  const [selectedServiceStore, setSelectedServiceStore] =
    useState<IServiceStore | null>(null);
  const [store, setStore] = useState<IStore | null>(null);

  const selectedDateParam = searchParams.get("selectedDate");

  const CURRENT_DAY_INDEX = new Date().getDay();

  const [serviceEmployeeUsers, setServiceEmployeeUsers] = useState<IUser[]>([]);
  const [serviceEmployees, setServiceEmployees] = useState<IEmployee[]>([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(
    defaultEmployeeIdParam || ""
  );
  const [ratings, setRatings] = useState<IRating[]>([]);

  const [areEmployeesLoading, setAreEmployeesLoading] = useState(true);
  const [serviceLoading, setServiceLoading] = useState(true);

  const [selectedEmployeeError, setSelectedEmployeeError] = useState(false);

  const handleStoreClick = () => {
    navigate(`/store?id=${selectedServiceStore?.storeId}`);
  };

  const fetchServiceEmployees = async () => {
    try {
      const serviceEmployeeRes: IServiceEmployee[] =
        await serviceEmployeeApi.getServiceEmployeeByServiceStoreId(serviceId);

      let iterableUser = null;
      const updatedServiceEmployeeUsers = [];
      const updatedServiceEmployees = [];

      for (let i = 0; i < serviceEmployeeRes.length; i++) {
        updatedServiceEmployees.push(serviceEmployeeRes[i].Employee);

        iterableUser = await userApi.getUser(
          serviceEmployeeRes[i].Employee.userId
        );

        updatedServiceEmployeeUsers.push(iterableUser);
      }

      setServiceEmployees(updatedServiceEmployees);
      setServiceEmployeeUsers(updatedServiceEmployeeUsers);

      if (!defaultEmployeeIdParam && updatedServiceEmployees.length > 0)
        setSelectedEmployeeId(updatedServiceEmployees[0].id);
    } catch (error) {
      console.warn("Error fetching service-employee: ", error);
    }

    setAreEmployeesLoading(false);
  };

  const handleScheduleServiceClick = async () => {
    if (selectedEmployeeId.length > 0) {
      setSelectedEmployeeError(false);
      try {
        const employeeRes = await employeeApi.getEmployee(selectedEmployeeId);
        const employeeUserRes = await userApi.getUser(employeeRes.userId);

        localStorage.setItem(
          "selectedEmployeeName",
          JSON.stringify(employeeUserRes.name)
        );
        selectedDateParam
          ? navigate(
              `/scheduiling?serviceId=${selectedServiceStore?.id}&employee=${employeeRes.id}&selectedDate=${selectedDateParam}`
            )
          : navigate(
              `/scheduiling?serviceId=${selectedServiceStore?.id}&employee=${employeeRes.id}`
            );
      } catch (error) {
        console.warn(error);
      }
    } else setSelectedEmployeeError(true);
  };

  useEffect(() => {
    const fetchStoreData = async () => {
      await Promise.all([
        fetchServiceStoreRating(serviceId!, setRatings!),
        fetchServiceEmployees(),
        fetchServiceStore(serviceId!, setSelectedServiceStore!),
      ]);
    };

    fetchStoreData();
  }, []);

  useEffect(() => {
    if (selectedServiceStore?.storeId)
      fetchStore(selectedServiceStore?.storeId!, setStore!);

    setServiceLoading(false);
  }, [selectedServiceStore]);

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <ClientNavbar hideMidButtons />
      <BackPageButton
        containerStyles={styles.backButton}
        iconStyles={styles.backIcon}
      />
      {!serviceLoading ? (
        <>
          <Box
            width="100vw"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={8}
            margin="30px 0px"
            sx={{
              "@media(max-width: 930px)": {
                flexDirection: "column",
                gap: 4,
              },
            }}
          >
            {selectedServiceStore?.imageOriginal!.length > 0 ? (
              <Box width="650px" height="450px">
                <ImageCarousel
                  imageUrls={selectedServiceStore?.imageOriginal!}
                />
              </Box>
            ) : (
              <></>
            )}

            <Box width={400}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="h4" fontWeight="bold">
                  {selectedServiceStore?.Service?.name!}
                </Typography>
                <Typography color="text.secondary" textAlign="justify">
                  <DescriptionWrapper
                    text={selectedServiceStore?.description!}
                    max={200}
                  />
                </Typography>
                <Typography variant="h5" fontWeight="bold" color="primary">
                  {`R$ ${priceFormatter(
                    selectedServiceStore?.priceDay[CURRENT_DAY_INDEX]!
                  )}`}
                </Typography>
                {!areEmployeesLoading ? (
                  <FormControl
                    sx={{ margin: "15px 0px", minHeight: "65px" }}
                    size="small"
                  >
                    <InputLabel>Selecione o profissional</InputLabel>
                    <Select
                      label="Selecione o profissional"
                      value={selectedEmployeeId}
                      onChange={(e) => {
                        setSelectedEmployeeId(e.target.value);
                      }}
                      defaultValue={
                        defaultEmployeeIdParam! ?? selectedEmployeeId
                      }
                      error={!!selectedEmployeeError}
                    >
                      {serviceEmployeeUsers
                        .filter((user) => user.id !== auth.user.id)
                        .map((employeeUser: IUser, index) => (
                          <MenuItem
                            key={employeeUser.id}
                            value={serviceEmployees[index].id}
                          >
                            <ListItem disablePadding>
                              <ListItemAvatar>
                                <Avatar src={employeeUser.imagePreview} />
                              </ListItemAvatar>
                              <ListItemText primary={employeeUser.name} />
                            </ListItem>
                          </MenuItem>
                        ))}
                    </Select>
                    {selectedEmployeeError ? (
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        É necessário selecionar um profissional para o serviço.
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    margin="10px 0px 20px 0px"
                  >
                    <CircularProgress />
                  </Box>
                )}

                <PrimaryButton
                  styles={{ height: "50px" }}
                  label="Agendar serviço"
                  icon={<EventNoteIcon />}
                  onClickAction={handleScheduleServiceClick}
                />
              </Box>
            </Box>
          </Box>
          <Box
            width="100vw"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap={2}
            marginBottom="30px"
            marginTop="50px"
          >
            <Typography>Serviço oferecido por: </Typography>

            <ListItemButton onClick={handleStoreClick} style={styles.storeItem}>
              <Avatar src={store?.imagePreview[0]} style={styles.storeAvatar} />

              <ListItemText
                primary={store?.name}
                secondary={store?.listTags}
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
            </ListItemButton>
          </Box>
          <Box width="100%">
            <Divider />
          </Box>
          <Box
            marginTop="30px "
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="h5" fontWeight="bold">
              Avaliações dos usuários
            </Typography>
          </Box>
          <Box>
            {ratings.length > 0 ? (
              <Box
                display="flex"
                flexDirection="column"
                gap={4}
                margin="0px 30px 50px 30px"
              >
                {ratings.map((rating, index) => (
                  <Box
                    key={index}
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Box display="flex" gap={1} alignItems="center">
                      <Avatar src={rating?.User.imagePreview} />

                      <Box display="flex" flexDirection="column">
                        <Typography fontWeight="bold">
                          {rating?.User.name}
                        </Typography>
                        <Rating value={rating?.rating} readOnly />
                      </Box>
                    </Box>
                    <Box>
                      <Typography>{rating?.comment}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                margin="20px 0px 50px 0px"
              >
                <Typography color="text.secondary">
                  Ainda não há avaliações para este serviço.
                </Typography>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin="100px 0px"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

const styles = {
  backButton: {
    margin: "5px",
  },

  backIcon: {
    width: "30px",
    height: "30px",
  },

  storeAvatar: {
    width: "70px",
    height: "70px",
  } as SxProps,

  storeItem: {
    display: "flex",
    gap: 10,
  } as SxProps,
};
