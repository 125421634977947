import { Avatar, Box, Typography } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CEODrawer from "../../../../../components/pet-beauty/CEODrawer";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import TimeRangeInterpreter from "../../../../../components/comercial/TimeRangeInterpreter";
import { IEmployee } from "../../../../../services/api/Employee/types";
import { DocType } from "../../../../../types/types";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";

const EmployeeView = () => {
  const selectedEmployee: IEmployee = JSON.parse(
    localStorage.getItem("selectedEmployee")
  );

  const renderEmployeeData = (
    <Box>
      <CEOInterfaceHeader title="Dados do funcionário" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "25px",
          gap: { xs: 2, md: 3 },
        }}
      >
        <Box display="flex" alignItems="center">
          <Avatar
            alt="Imagem do estabelecimento"
            src={selectedEmployee.User.imageOriginal}
            sx={{
              width: "140px",
              height: "140px",
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography fontWeight="bold" variant="h6">
            {selectedEmployee.User.name}
          </Typography>
          <Typography variant="body1" textAlign="justify">
            {selectedEmployee.User.docType === DocType.CPF
              ? `${selectedEmployee.User.docNumber} (CPF)`
              : `${selectedEmployee.User.docNumber} (Passaporte)`}
          </Typography>
        </Box>

        <Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" flexDirection="column" gap={1}>
              <Box display="flex" gap={1}>
                <FmdGoodOutlinedIcon sx={{ color: "primary.main" }} />
                <Typography>{`${selectedEmployee.User.logradouro}, ${
                  selectedEmployee.User.number || "s/n"
                } - ${selectedEmployee.User.district}, ${
                  selectedEmployee.User.city
                } - ${selectedEmployee.User.state}, ${
                  selectedEmployee.User.CEP
                }`}</Typography>
              </Box>
              <Box display="flex" gap={3}>
                {selectedEmployee.User.phone ? (
                  <Box display="flex" gap={1}>
                    <LocalPhoneIcon sx={{ color: "primary.main" }} />
                    <Typography variant="body1">
                      {selectedEmployee.User.phone}
                    </Typography>
                  </Box>
                ) : null}
                {selectedEmployee.User.email ? (
                  <Box display="flex" gap={1}>
                    <AlternateEmailIcon sx={{ color: "primary.main" }} />
                    <Typography variant="body1">
                      {selectedEmployee.User.email}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const renderWorkScale = (
    <Box>
      <CEOInterfaceHeader title="Horários de funcionamento" />
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        margin="25px"
        gap={1}
      >
        <TimeRangeInterpreter
          timeMonday={selectedEmployee.timeMonday}
          timeTuesday={selectedEmployee.timeTuesday}
          timeWednesday={selectedEmployee.timeWednesday}
          timeThursday={selectedEmployee.timeThursday}
          timeFriday={selectedEmployee.timeFriday}
          timeSaturday={selectedEmployee.timeSaturday}
          timeSunday={selectedEmployee.timeSunday}
          onChange={function (
            week: Array<{ day: string; hours: string[] }>
          ): void {
            throw new Error("Function not implemented.");
          }}
        />
      </Box>
    </Box>
  );

  const renderAddStorePageContent = (
    <Box>
      {renderEmployeeData}
      {renderWorkScale}
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderAddStorePageContent} />
    </Box>
  );
};

export default EmployeeView;
