import { CSSProperties } from "react";
import backgroundImage from "../../../../assets/img/pet-landing-page.jpg";
import { SxProps } from "@mui/material";
import { generalWhite } from "../../../../styles/colors";

const styles = {
  firstSectionContainer: {
    width: "100%",
    height: "660px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    color: generalWhite,
  } as CSSProperties,

  firstSectionHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px",
    gap: 1,
  } as SxProps,

  alreadyRegisteredContainer: {
    display: "flex",
    gap: 2,
    alignItems: "center",
    marginRight: "15px",
  } as SxProps,

  alreadyRegisteredLabel: {
    "@media(max-width:800px)": {
      display: "none",
    },
  } as SxProps,

  loginButton: {
    textTransform: "none",
  } as SxProps,

  presentationContainer: {
    height: "82%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    margin: "0px 20px",
    textAlign: "center",
  } as SxProps,

  registerButton: {
    marginTop: "20px",
    textTransform: "none",
  } as SxProps,

  secondSectionContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: generalWhite,
    margin: "50px 0px",
  } as CSSProperties,

  secondSectionHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 1,
    textAlign: "center",
  } as SxProps,

  roleCardContainer: {
    display: "flex",
    margin: "70px 20px",
    gap: 15,
    "@media(max-width:900px)": { flexDirection: "column", gap: 7 },
    textAlign: "center",
  } as SxProps,

  roleCard: {
    width: "300px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 1,
  } as SxProps,

  roleCardImage: {
    width: "120px",
    height: "120px",
  } as CSSProperties,
};

export default styles;
