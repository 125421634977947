import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Avatar,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import defaultStoreImage from "../../../../assets/svg/defaultStore.svg";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { cleanLocalStorage } from "../../../../utils/cleanLocalStorage";
import CEODrawer from "../../../../components/pet-beauty/CEODrawer";
import CEOInterfaceHeader from "../../../../components/comercial/CEOInterfaceHeader";
import employeeApi from "../../../../services/api/Employee";
import { IEmployee } from "../../../../services/api/Employee/types";
import { IStore } from "../../../../services/api/Store/types";
import { generalDarkGrey, generalBrightGrey } from "../../../../styles/colors";

const MyWorkscales = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  cleanLocalStorage();

  const [userEmployees, setUserEmployees] = useState<IEmployee[]>([]);
  const [userStores, setUserStores] = useState<IStore[]>([]);
  const [areStoresLoading, setAreStoresLoading] = useState(true);

  const fetchUserEmployee = async () => {
    try {
      const userEmployeeResponse = await employeeApi.getEmployeeFromUserId(
        auth.user.id
      );
      setUserEmployees(userEmployeeResponse);
    } catch (error) {
      console.warn("Error fetching user employee: ", error);
    }
  };

  const fetchStoresFromAllUserEmployees = () => {
    try {
      let employeeStores = [];
      for (let i = 0; i < userEmployees.length; i++) {
        employeeStores.push(userEmployees[i].store);
      }
      setUserStores(employeeStores);
    } catch (error) {
      console.warn("Error getting stores from employee: ", error);
    }
    setAreStoresLoading(false);
  };

  useEffect(() => {
    fetchUserEmployee();
  }, [auth]);

  useEffect(() => {
    if (userEmployees.length > 0) fetchStoresFromAllUserEmployees();
  }, [userEmployees]);

  const handleSelectEmployee = (employee: IEmployee) => {
    localStorage.setItem("selectedEmployee", JSON.stringify(employee));
  };

  const handleEditEmployeeClick = (employee: IEmployee, store: IStore) => {
    handleSelectEmployee(employee);
    localStorage.setItem("selectedStore", JSON.stringify(store));
    navigate("/employees/edit");
  };

  const renderStoresPageContent = (
    <Box>
      <CEOInterfaceHeader title="Estabelecimentos em que você trabalha" />
      {!areStoresLoading ? (
        <Box width="100%">
          <TableContainer
            sx={{
              margin: "30px 0px",
              width: "100%",
              border: `1px solid ${generalBrightGrey}`,
              borderRadius: "15px",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Estabelecimento</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userStores.length !== 0 ? (
                  userStores.map((store, index) => (
                    <TableRow
                      key={store.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        component="th"
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        align="left"
                      >
                        <Avatar
                          src={store.imagePreview[0] ?? defaultStoreImage}
                        />

                        <ListItemText
                          primary={`${store.name}`}
                          secondary={`CNPJ: ${store?.CNPJ}`}
                        />
                      </TableCell>

                      <TableCell align="right">
                        <Tooltip title="Editar escala de trabalho">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              handleEditEmployeeClick(
                                userEmployees[index],
                                store
                              );
                            }}
                          >
                            <EditCalendarIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography color={generalDarkGrey} variant="body2">
                        Você não está associado a nenhum estabelecimento
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderStoresPageContent} />
    </Box>
  );
};

export default MyWorkscales;

const styles = {
  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "50px 0px",
  },
};
