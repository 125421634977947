import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import defaultEmployeeImage from "../../../../../assets/svg/defaultUser.svg";
import CEODrawer from "../../../../../components/pet-beauty/CEODrawer";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import WorkingScaleSelector from "../../../../../components/comercial/WorkingScaleSelector";
import CustomCard from "../../../../../components/general/CustomCard";
import { userApi } from "../../../../../services";
import employeeApi from "../../../../../services/api/Employee";
import { IStore } from "../../../../../services/api/Store/types";
import { IUser } from "../../../../../services/api/User/types";
import {
  generalDarkGrey,
  petPrimaryGreen,
  dmPrimaryOrange,
} from "../../../../../styles/colors";
import { IEmployee } from "../../../../../services/api/Employee/types";
import {
  CURRENT_SUBDOMAIN,
  DEMANDORIA_SUBDOMAINS,
} from "../../../../../utils/stringUtils";
import WorkingScaleCaption from "../../../../../components/comercial/WorkingScaleSelector/WorkingScaleCaption";

const AddEmployee = () => {
  const navigate = useNavigate();

  const [foundedUser, setFoundedUser] = useState<IUser>({} as IUser);

  const selectedStore: IStore = JSON.parse(
    localStorage.getItem("selectedStore")
  );

  const [docNumber, setDocNumber] = useState("");

  const [searchingEmployee, setSearchingEmployee] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [timeMonday, setTimeMonday] = useState<string[]>(
    selectedStore.timeMonday
  );
  const [timeTuesday, setTimeTuesday] = useState<string[]>(
    selectedStore.timeTuesday
  );
  const [timeWednesday, setTimeWednesday] = useState<string[]>(
    selectedStore.timeWednesday
  );
  const [timeThursday, setTimeThursday] = useState<string[]>(
    selectedStore.timeThursday
  );
  const [timeFriday, setTimeFriday] = useState<string[]>(
    selectedStore.timeFriday
  );
  const [timeSaturday, setTimeSaturday] = useState<string[]>(
    selectedStore.timeSaturday
  );
  const [timeSunday, setTimeSunday] = useState<string[]>(
    selectedStore.timeSunday
  );

  const handleSearchUserByDocNumber = async () => {
    setSearchingEmployee(true);
    try {
      const userRes = await userApi.getUserByDocNumber(docNumber);
      setFoundedUser(userRes);
    } catch (error) {
      alert("Profissional não encontrado!");
      console.warn(
        "Error searching for a user by the document number: ",
        error
      );
    }
    setSearchingEmployee(false);
  };

  const handleUserAndEmployeeCreation = async () => {
    setSearchingEmployee(!searchingEmployee);
    try {
      await employeeApi.createEmployee({
        userId: foundedUser.id,
        storeId: selectedStore.id,
        timeMonday,
        timeTuesday,
        timeWednesday,
        timeThursday,
        timeFriday,
        timeSaturday,
        timeSunday,
      } as IEmployee);
      navigate("/employees");
    } catch (error) {
      alert("Não foi possível adicionar o funcionário.");
      setSearchingEmployee(false);
      console.warn("Error creating a employee: ", error);
    }
  };

  useEffect(() => {
    const unformattedDocumentNumber = docNumber.replace(/\D/g, "");

    if (unformattedDocumentNumber.length === 11) {
      handleSearchUserByDocNumber();
    } else setFoundedUser({} as IUser);
  }, [docNumber]);

  const CpfField = (
    <InputMask
      mask="999.999.999-99"
      disabled={false}
      maskChar="_"
      value={docNumber}
      onChange={(e) => setDocNumber(e.target.value)}
    >
      {<TextField label="CPF*" fullWidth size="small" />}
    </InputMask>
  );

  const renderEmployeeData = (
    <Box>
      <CEOInterfaceHeader title="Busque funcionário pelo CPF" />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        margin="25px"
        gap={3}
      >
        <Box display="flex" width="100%" gap={2}>
          {CpfField}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        margin="25px"
        marginTop="-15px"
        gap={3}
      >
        <Button
          variant="text"
          sx={{ minWidth: "30%", textTransform: "none" }}
          onClick={() => {
            navigate("/register");
          }}
        >
          Funcionário não cadastrado? Cadastre agora!
        </Button>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        margin="25px"
        gap={3}
        alignItems="center"
      >
        {!searchingEmployee ? (
          <>
            {Object.entries(foundedUser).length !== 0 && (
              <>
                <Typography fontWeight="bold">
                  Funcionário selecionado:
                </Typography>
                <CustomCard
                  photoURL={foundedUser.imageOriginal}
                  defaultPhoto={defaultEmployeeImage}
                  header={foundedUser.name}
                  primaryText={foundedUser.docNumber}
                  secondaryText={""}
                />
              </>
            )}
          </>
        ) : (
          <Box display="flex" gap={2} alignItems="center">
            <CircularProgress />
            <Typography color="text.secondary">
              Buscando profissional...
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );

  const renderWorkScaleSelector = (
    <Box display="flex" flexDirection="column" width="100%">
      <CEOInterfaceHeader title="Defina a escala de trabalho" />
      <Box color="text.secondary">
        <Typography>
          Escala de trabalho disponível varia de acordo com os horários do
          estabelecimento.
          <br />
          Sinta-se a vontade para{" "}
          <Typography
            sx={{
              fontWeight: "bold",
              color: "primary.main",
            }}
          >
            personalizar
          </Typography>{" "}
          este intervalo.
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        margin="25px 0px"
        maxWidth="87vw"
      >
        <WorkingScaleSelector
          timeMonday={timeMonday}
          setTimeMonday={setTimeMonday}
          timeTuesday={timeTuesday}
          setTimeTuesday={setTimeTuesday}
          timeWednesday={timeWednesday}
          setTimeWednesday={setTimeWednesday}
          timeThursday={timeThursday}
          setTimeThursday={setTimeThursday}
          timeFriday={timeFriday}
          setTimeFriday={setTimeFriday}
          timeSaturday={timeSaturday}
          setTimeSaturday={setTimeSaturday}
          timeSunday={timeSunday}
          setTimeSunday={setTimeSunday}
          possibleMondayTimes={selectedStore.timeMonday}
          possibleTuesdayTimes={selectedStore.timeTuesday}
          possibleWednesdayTimes={selectedStore.timeWednesday}
          possibleThursdayTimes={selectedStore.timeThursday}
          possibleFridayTimes={selectedStore.timeFriday}
          possibleSaturdayTimes={selectedStore.timeSaturday}
          possibleSundayTimes={selectedStore.timeSunday}
        />
        <WorkingScaleCaption />
      </Box>
    </Box>
  );

  const renderOptionButtons = (
    <Box
      display="flex"
      justifyContent="space-between"
      gap={2}
      sx={{
        margin: "40px 0px",
        "@media (max-width: 800px)": {
          flexDirection: "column",
        },
      }}
    >
      <LoadingButton
        variant="contained"
        fullWidth
        onClick={handleUserAndEmployeeCreation}
        startIcon={<PersonAddIcon />}
        loadingPosition="start"
        loading={searchingEmployee}
        disabled={searchingEmployee}
        disableElevation
        sx={{ textTransform: "none" }}
      >
        Confirmar adição
      </LoadingButton>
      <Button
        variant="outlined"
        onClick={() => {
          navigate("/employees");
        }}
        fullWidth
        disabled={searchingEmployee}
        sx={{ textTransform: "none" }}
      >
        Cancelar
      </Button>
    </Box>
  );
  const renderAddStorePageContent = (
    <Box>
      {renderEmployeeData}
      {renderWorkScaleSelector}
      {renderOptionButtons}
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderAddStorePageContent} />
    </Box>
  );
};

export default AddEmployee;
