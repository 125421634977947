import { createContext, useState, useEffect } from "react";
import {
  type IContext,
  type IAuthProvider,
  type IUserTokenData,
} from "./types";
import { getTokenLocalStorage, setTokenLocalStorage } from "./util";
import jwt_decode from "jwt-decode";
import { googleLogin, login } from "../../services/api/Auth";
import { type IUser } from "../../services/api/User/types";
import { userApi } from "../../services";
import { cleanLocalStorage } from "../../utils/cleanLocalStorage";

export const AuthContext = createContext<IContext>({} as IContext);

export const AuthProvider = ({ children }: IAuthProvider) => {
  const [user, setUser] = useState<IUser>({} as IUser);
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const updateCurrentUser = (updatedUser: IUser) => {
    setUser(updatedUser);
  };

  useEffect(() => {
    setLoading(true);
    const userToken = getTokenLocalStorage();
    if (userToken) {
      const userData: IUserTokenData | null = decodeUser(userToken);
      if (userData) {
        getCurrentUser(userData.user_id);
        setIsLoggedIn(true);
      }
    } else setLoading(false);
  }, [isLoggedIn]);

  const getCurrentUser = async (id: string) => {
    const currentUser = await userApi.getUser(id);
    setUser(currentUser);
    setLoading(false);
  };

  const authenticate = async (email: string, password: string) => {
    const data = await login(email, password);
    const userData = decodeUser(data.access_token);
    if (userData) {
      setTokenLocalStorage(data.access_token);
      getCurrentUser(userData?.user_id);
      setIsLoggedIn(true);
    }
  };

  const authenticateWithGoogle = async (
    token: string,
    name: string,
    email: string,
    imageOriginal: string
  ) => {
    const accessToken = await googleLogin(token, name, email, imageOriginal);
    const userData = decodeUser(accessToken);
    if (userData) {
      setTokenLocalStorage(accessToken);
      getCurrentUser(userData?.user_id);
      setIsLoggedIn(true);
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    cleanLocalStorage();
    setIsLoggedIn(false);
    setUser({} as IUser);
  };

  const decodeUser = (token: string): IUserTokenData | null => {
    try {
      const userTokenData: IUserTokenData = jwt_decode(token);
      return userTokenData;
    } catch (err) {
      return null;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        authenticate,
        authenticateWithGoogle,
        logout,
        isLoggedIn,
        updateCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
