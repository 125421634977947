import { type IEmployee } from "../Employee/types";
import { type IServiceStore } from "../ServiceStore/types";
import requests from "../util";
import { type IStore } from "./types";

const STORE_URL = "/store";

const storeApi = {
  getStore: async (id: string): Promise<IStore> =>
    await requests.get(`${STORE_URL}/${id}`),

  getManagerStores: async (managerId?: string): Promise<IStore[]> =>
    await requests.get(`${STORE_URL}?managerId=${managerId}`),

  getServiceStoresFromStore: async (
    storeId: string
  ): Promise<IServiceStore[]> =>
    await requests.get(`/service-store?storeId=${storeId}`),

  getEmployeesFromStore: async (storeId: string): Promise<IEmployee[]> =>
    await requests.get(`/employee?storeId=${storeId}`),

  getStoresFromStoreName: async (name: string): Promise<IStore[]> =>
    await requests.get(`${STORE_URL}?name=${name}&limit=100`),

  createStore: async (store: IStore): Promise<IStore> =>
    await requests.post(STORE_URL, store),

  updateStore: async (id: string, updatedStore: IStore): Promise<IStore> =>
    await requests.patch(`${STORE_URL}/${id}`, updatedStore),

  deleteStore: async (id: string): Promise<void> =>
    await requests.delete(`${STORE_URL}/${id}`),

  searchStores: async (searchTerm: string): Promise<IStore[]> =>
    await requests.get(`${STORE_URL}/search?searchTerm=${searchTerm}`),

  getNearbyStores: async (
    latitude: number,
    longitude: number
  ): Promise<IStore[]> =>
    await requests.get(
      `${STORE_URL}/list/location?lat=${latitude}&lng=${longitude}`
    ),
};

export default storeApi;
