import {
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";

interface headerProps {
  title: string;
  buttonLabel?: string;
  buttonIcon?: any;
  buttonAction?: any;
}

const CEOInterfaceHeader = ({
  title,
  buttonLabel,
  buttonIcon,
  buttonAction: buttonAction,
}: headerProps) => {
  const isMobile = useMediaQuery("(max-width:800px)");
  return (
    <Box display="flex" flexDirection="column" marginBottom="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight="bold">
          {title}
        </Typography>
        <Button
          onClick={buttonAction}
          disableRipple
          sx={{ textTransform: "none" }}
        >
          <IconButton disableRipple>
            <Icon color="primary">{buttonIcon}</Icon>
          </IconButton>
          {!isMobile && buttonLabel}
        </Button>
      </Box>
      <Divider />
    </Box>
  );
};

export default CEOInterfaceHeader;
