import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid, SxProps } from "@mui/material";
import {
  generalBrightGrey,
  beautySecondaryPurple,
  petSecondaryGreen,
  dmBrighterOrange,
} from "../../../styles/colors";
import { priceFormatter } from "../../../utils/priceFormatter";
import {
  CURRENT_SUBDOMAIN,
  DEMANDORIA_SUBDOMAINS,
  shortenLargeTexts,
} from "../../../utils/stringUtils";

interface CardProps {
  photoURL: string;
  defaultPhoto: string;
  header: number | string;
  primaryText: string;
  secondaryText: string;
  isSelected?: boolean;
}

const CustomCard = (props: CardProps) => {
  let formattedHeader: string | number = props.header;

  if (typeof props.header === "number") {
    formattedHeader = `R$ ${priceFormatter(props.header)}`;
  }

  return (
    <Card
      sx={{
        ...styles.cardContainer,
        border: props.isSelected
          ? `3px solid ${
              CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[0]
                ? beautySecondaryPurple
                : CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[1]
                  ? petSecondaryGreen
                  : dmBrighterOrange
            }`
          : `1px solid ${generalBrightGrey}`,
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          image={props.photoURL ? props.photoURL : props.defaultPhoto}
          alt="card image"
          height={225}
        />
        <CardContent>
          <Grid container gap={2}>
            <Typography
              variant="h5"
              color="primary.main"
              fontWeight="bold"
              gutterBottom
            >
              {shortenLargeTexts(String(formattedHeader), 28)}
            </Typography>
          </Grid>
          {props.primaryText ? (
            <Typography
              gutterBottom
              variant="body1"
              color="text.primary"
              fontWeight="bold"
            >
              {shortenLargeTexts(props.primaryText, 33)}
            </Typography>
          ) : null}
          {props.secondaryText ? (
            <Typography
              variant="body1"
              color="text.secondary"
              fontWeight="regular"
            >
              {shortenLargeTexts(props.secondaryText, 34)}
            </Typography>
          ) : null}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CustomCard;

const styles = {
  cardContainer: {
    minWidth: 300,
    maxWidth: 350,
    boxShadow: "none",
  } as SxProps,
};
