import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CEODrawer from "../../../../../components/pet-beauty/CEODrawer";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import WorkingScaleSelector from "../../../../../components/comercial/WorkingScaleSelector";
import employeeApi from "../../../../../services/api/Employee";
import { IEmployee } from "../../../../../services/api/Employee/types";
import { IStore } from "../../../../../services/api/Store/types";
import WorkingScaleCaption from "../../../../../components/comercial/WorkingScaleSelector/WorkingScaleCaption";

const EditEmployee = () => {
  const navigate = useNavigate();

  const selectedEmployee: IEmployee = JSON.parse(
    localStorage.getItem("selectedEmployee")
  );
  const selectedStore: IStore = JSON.parse(
    localStorage.getItem("selectedStore")
  );

  const [timeMonday, setTimeMonday] = useState<string[]>(
    selectedEmployee.timeMonday
  );
  const [timeTuesday, setTimeTuesday] = useState<string[]>(
    selectedEmployee.timeTuesday
  );
  const [timeWednesday, setTimeWednesday] = useState<string[]>(
    selectedEmployee.timeWednesday
  );
  const [timeThursday, setTimeThursday] = useState<string[]>(
    selectedEmployee.timeThursday
  );
  const [timeFriday, setTimeFriday] = useState<string[]>(
    selectedEmployee.timeFriday
  );
  const [timeSaturday, setTimeSaturday] = useState<string[]>(
    selectedEmployee.timeSaturday
  );
  const [timeSunday, setTimeSunday] = useState<string[]>(
    selectedEmployee.timeSunday
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleEmployeeEdition = async () => {
    setIsLoading(!isLoading);
    try {
      const employeeRes = await employeeApi.updateEmployee(
        selectedEmployee.id,
        {
          timeMonday,
          timeTuesday,
          timeWednesday,
          timeThursday,
          timeFriday,
          timeSaturday,
          timeSunday,
        }
      );

      window.alert(`${selectedEmployee.User.name} editado com sucesso!`);
      navigate("/employees");
    } catch (error) {
      setIsLoading(false);
      alert("Não foi possível editar o funcionário.");
      console.warn("Error editing a employee: ", error);
    }
  };

  const renderWorkScaleSelector = (
    <Box display="flex" flexDirection="column" width="100%">
      <CEOInterfaceHeader
        title={`Escala de trabalho de ${selectedEmployee.User.name} - ${selectedEmployee.store.name}`}
      />
      <Box
        display="flex"
        flexDirection="column"
        margin="25px 0px"
        maxWidth="87vw"
      >
        <WorkingScaleSelector
          timeMonday={timeMonday}
          setTimeMonday={setTimeMonday}
          timeTuesday={timeTuesday}
          setTimeTuesday={setTimeTuesday}
          timeWednesday={timeWednesday}
          setTimeWednesday={setTimeWednesday}
          timeThursday={timeThursday}
          setTimeThursday={setTimeThursday}
          timeFriday={timeFriday}
          setTimeFriday={setTimeFriday}
          timeSaturday={timeSaturday}
          setTimeSaturday={setTimeSaturday}
          timeSunday={timeSunday}
          setTimeSunday={setTimeSunday}
          possibleMondayTimes={selectedStore.timeMonday}
          possibleTuesdayTimes={selectedStore.timeTuesday}
          possibleWednesdayTimes={selectedStore.timeWednesday}
          possibleThursdayTimes={selectedStore.timeThursday}
          possibleFridayTimes={selectedStore.timeFriday}
          possibleSaturdayTimes={selectedStore.timeSaturday}
          possibleSundayTimes={selectedStore.timeSunday}
        />
        <WorkingScaleCaption />
      </Box>
    </Box>
  );

  const renderOptionButtons = (
    <Box
      display="flex"
      justifyContent="space-between"
      gap={2}
      sx={{
        margin: "40px 0px",
        "@media (max-width: 800px)": {
          flexDirection: "column",
        },
      }}
    >
      <LoadingButton
        variant="contained"
        fullWidth
        onClick={handleEmployeeEdition}
        startIcon={<PersonAddIcon />}
        loadingPosition="start"
        loading={isLoading}
        disabled={isLoading}
        disableElevation
        sx={{ textTransform: "none" }}
      >
        Confirmar edição
      </LoadingButton>
      <Button
        variant="outlined"
        onClick={() => {
          navigate("/employees");
        }}
        fullWidth
        disabled={isLoading}
        sx={{ textTransform: "none" }}
      >
        Cancelar
      </Button>
    </Box>
  );
  const renderAddStorePageContent = (
    <Box>
      {renderWorkScaleSelector}
      {renderOptionButtons}
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderAddStorePageContent} />
    </Box>
  );
};

export default EditEmployee;
