import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import defaultEmployeeImage from "../../../../../assets/svg/defaultUser.svg";
import AddIcon from "@mui/icons-material/Add";
import {
  createServiceEmployee,
  validateServiceStorePrices,
} from "../usualFunctions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import CEODrawer from "../../../../../components/pet-beauty/CEODrawer";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import ImageGallery from "../../../../../components/comercial/ImageGallery";
import ImageGalleryHeader from "../../../../../components/comercial/ImageGallery/ImageGalleryHeader";
import CustomCard from "../../../../../components/general/CustomCard";
import { IEmployee } from "../../../../../services/api/Employee/types";
import serviceApi from "../../../../../services/api/Service";
import { IService } from "../../../../../services/api/Service/types";
import serviceStoreApi from "../../../../../services/api/ServiceStore";
import storeApi from "../../../../../services/api/Store";
import { IStore } from "../../../../../services/api/Store/types";
import { generalDarkGrey } from "../../../../../styles/colors";
import { uploadImagesToFirebase } from "../../../../../utils/firebase";
import { IServiceStore } from "../../../../../services/api/ServiceStore/types";
import PriceInput from "../../../../../components/general/PriceInput";
import { addServiceSchema } from "../../../../../utils/yupSchemas";
import DynamicPriceInput from "../DynamicPriceInput";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import { CURRENT_SUBDOMAIN } from "../../../../../utils/stringUtils";

const AddService = () => {
  const navigate = useNavigate();

  const selectedStore: IStore = JSON.parse(
    localStorage.getItem("selectedStore")
  );

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addServiceSchema) });

  const [serviceName, setServiceName] = useState<string>("");
  const [servicesAutocomplete, setServiceAutocomplete] = useState<IService[]>(
    []
  );

  const descriptionRef = useRef<HTMLInputElement>(null);

  const [dynamicPriceAllowed, setIsDynamicPriceAllowed] = useState(false);

  const [fixedPrice, setFixedPrice] = useState<number | null>(null);

  const [sundayPrice, setSundayPrice] = useState<number | null>(null);
  const [mondayPrice, setMondayPrice] = useState<number | null>(null);
  const [tuesdayPrice, setTuesdayPrice] = useState<number | null>(null);
  const [wednesdayPrice, setWednesdayPrice] = useState<number | null>(null);
  const [thursdayPrice, setThursdayPrice] = useState<number | null>(null);
  const [fridayPrice, setFridayPrice] = useState<number | null>(null);
  const [saturdayPrice, setSaturdayPrice] = useState<number | null>(null);

  const [images, setImages] = useState<HTMLCanvasElement[]>([]);

  const [originalImages, setOriginalImages] = useState<HTMLCanvasElement[]>([]);
  const [previewImages, setPreviewImages] = useState<HTMLCanvasElement[]>([]);

  const originalImageUrls = [];
  const previewImageUrls = [];

  const [assignableEmployees, setAssignableEmployees] = useState<IEmployee[]>(
    []
  );

  const [durationOfService, setDurationOfService] = useState<number>(1);

  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);

  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>([]);

  const [noEmployeesSelected, setNoEmployeesSelected] =
    useState<boolean>(false);

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  const [generalErrorMessage, setGeneralErrorMessage] = useState("");

  const fetchEmployeesToService = async () => {
    try {
      const res = await storeApi.getEmployeesFromStore(selectedStore.id);
      setAssignableEmployees(res);
    } catch (error) {
      console.warn("Error fetching employees: ", error);
    }
  };

  const fetchServicesForAutocomplete = async () => {
    if (serviceName.length > 0) {
      try {
        const res = await serviceApi.searchServices(serviceName);
        setServiceAutocomplete(res);
      } catch (error) {
        console.warn("Error fetching services: ", error);
      }
    }
  };

  useEffect(() => {
    fetchEmployeesToService();
  }, []);

  useEffect(() => {
    fetchServicesForAutocomplete();
  }, [serviceName]);

  useEffect(() => {
    selectedEmployeeIds.length > 0
      ? setNoEmployeesSelected(false)
      : setNoEmployeesSelected(true);
  }, [selectedEmployeeIds]);

  useEffect(() => {
    setGeneralErrorMessage("");
  }, [serviceName]);

  const checkIfPricesAreValid = () => {
    return validateServiceStorePrices(
      fixedPrice,
      mondayPrice,
      tuesdayPrice,
      wednesdayPrice,
      thursdayPrice,
      fridayPrice,
      saturdayPrice,
      sundayPrice,
      dynamicPriceAllowed
    );
  };

  const handleServiceAndServiceStoreCreation = async () => {
    if (serviceName.length > 0 && checkIfPricesAreValid()) {
      const maximumWeekPrice = Math.max(
        mondayPrice,
        tuesdayPrice,
        wednesdayPrice,
        thursdayPrice,
        fridayPrice,
        saturdayPrice,
        sundayPrice
      );

      if (!noEmployeesSelected) {
        setButtonsDisabled(!buttonsDisabled);

        try {
          let serviceId = "";

          if (servicesAutocomplete.length === 0) {
            const serviceRes = await serviceApi.createService({
              name: serviceName,
            } as IService);

            serviceId = serviceRes.id;
          } else serviceId = servicesAutocomplete[0].id;

          const newServiceStore: IServiceStore = {
            serviceId,
            storeId: selectedStore.id,
            description: descriptionRef?.current?.value,
            price: !dynamicPriceAllowed ? fixedPrice : maximumWeekPrice,
            imageOriginal: [],
            imagePreview: [],
            priceDay: dynamicPriceAllowed
              ? [
                  sundayPrice,
                  mondayPrice,
                  tuesdayPrice,
                  wednesdayPrice,
                  thursdayPrice,
                  fridayPrice,
                  saturdayPrice,
                ]
              : [
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                ],
            durationOfService,
          };

          const createdServiceStoreResponse =
            await serviceStoreApi.createServiceStore(newServiceStore);

          for (let i = 0; i < selectedEmployeeIds.length; i++) {
            await createServiceEmployee(
              selectedEmployeeIds[i],
              createdServiceStoreResponse.id
            );
          }

          if (images.length > 0) {
            const originalUuids = await uploadImagesToFirebase(
              originalImages,
              originalImageUrls,
              `${CURRENT_SUBDOMAIN}/stores/${selectedStore.name}_${selectedStore.id}/services/${serviceName}_${createdServiceStoreResponse.id}`,
              "_original",
              []
            );

            await uploadImagesToFirebase(
              previewImages,
              previewImageUrls,
              `${CURRENT_SUBDOMAIN}/stores/${selectedStore.name}_${selectedStore.id}/services/${serviceName}_${createdServiceStoreResponse.id}`,
              "_preview",
              originalUuids
            );

            newServiceStore.imageOriginal = originalImageUrls;
            newServiceStore.imagePreview = previewImageUrls;
            
            delete newServiceStore.serviceId;
            delete newServiceStore.storeId;

            await serviceStoreApi.updateServiceStore(
              createdServiceStoreResponse.id,
              newServiceStore
            );
          }

          window.alert(`Serviço adicionado com sucesso!`);
          navigate("/services");
        } catch (error) {
          setButtonsDisabled(false);
          console.error("Error creating a service or services store: ", error);
          setGeneralErrorMessage(error.response.data.message);
        }
      }
    }
  };

  const handleSelectEmployee = (employeeId: string) => {
    !selectedEmployeeIds.includes(employeeId)
      ? setSelectedEmployeeIds((prevSelectedEmployeeIds) => [
          ...prevSelectedEmployeeIds,
          employeeId,
        ])
      : setSelectedEmployeeIds((prevSelectedEmployeeIds) =>
          prevSelectedEmployeeIds.filter((id) => id !== employeeId)
        );
  };

  const handleToggleDynamicPrices = () => {
    setIsDynamicPriceAllowed(!dynamicPriceAllowed);
  };

  const renderServiceData = (
    <>
      <CEOInterfaceHeader title="Preencha os dados do serviço" />
      <Box
        display="flex"
        justifyContent="space-around"
        gap={5}
        sx={{
          "@media(max-width: 1020px)": {
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          width="60%"
          sx={{
            "@media(max-width: 1020px)": {
              width: "100%",
            },
          }}
        >
          <Box width="100%" display="flex" gap={2}>
            <Autocomplete
              freeSolo
              options={servicesAutocomplete.map((service) => service.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nome do serviço * "
                  size="small"
                  value={serviceName}
                  onChange={(e) => {
                    setServiceName(e.target.value);
                  }}
                  {...register("name")}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
              fullWidth
              onInputChange={(_, value) => {
                setServiceName(value);
              }}
            />
            <TextField
              size="small"
              label="Duração (horas)"
              type="number"
              autoComplete="off"
              value={durationOfService}
              onChange={(e) => setDurationOfService(Number(e.target.value))}
              InputProps={{ inputProps: { min: 1 } }}
            />
          </Box>

          <TextField
            size="small"
            label="Descrição"
            multiline
            rows={4}
            fullWidth
            inputRef={descriptionRef}
            placeholder="Descreva brevemente seu serviço..."
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          width="40%"
          sx={{
            "@media(max-width: 1020px)": {
              width: "100%",
            },
          }}
        >
          {!dynamicPriceAllowed ? (
            <PriceInput
              label="Preço fixo (R$)"
              size="small"
              placeholder="Ex.: R$ 50,00"
              value={fixedPrice}
              onChange={(e) => setFixedPrice(Number(e.target.value))}
              error={!checkIfPricesAreValid() && submittedOnce}
              helperText={
                !checkIfPricesAreValid() && submittedOnce
                  ? "O preço deve ser maior que R$ 0,00"
                  : null
              }
              autoComplete="off"
              inputRef={(input) => {
                if (input && !checkIfPricesAreValid() && submittedOnce)
                  input.focus();
              }}
            />
          ) : null}
          <Box>
            <FormControlLabel
              control={<Checkbox onChange={handleToggleDynamicPrices} />}
              label="Preço dinâmico"
            />
            <Typography textAlign="justify" color={generalDarkGrey}>
              O preço dinâmico possibilita preços diferentes a cada dia da
              semana.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );

  const renderDynamicPrice = (
    <>
      {dynamicPriceAllowed ? (
        <Box>
          <CEOInterfaceHeader title="Preço dinâmico (R$)" />
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            gap={1}
            margin="20px 0px"
            alignItems="center"
            ref={(ref) => {
              if (ref && !checkIfPricesAreValid() && submittedOnce)
                ref.querySelector('input[aria-invalid="true"]').focus();
            }}
          >
            <DynamicPriceInput
              value={mondayPrice}
              setValue={(value) => setMondayPrice(value)}
              weekdayIndex={0}
              submittedOnce={submittedOnce}
            />
            <DynamicPriceInput
              value={tuesdayPrice}
              setValue={(value) => setTuesdayPrice(value)}
              weekdayIndex={1}
              submittedOnce={submittedOnce}
            />
            <DynamicPriceInput
              value={wednesdayPrice}
              setValue={(value) => setWednesdayPrice(value)}
              weekdayIndex={2}
              submittedOnce={submittedOnce}
            />
            <DynamicPriceInput
              value={thursdayPrice}
              setValue={(value) => setThursdayPrice(value)}
              weekdayIndex={3}
              submittedOnce={submittedOnce}
            />
            <DynamicPriceInput
              value={fridayPrice}
              setValue={(value) => setFridayPrice(value)}
              weekdayIndex={4}
              submittedOnce={submittedOnce}
            />
            <DynamicPriceInput
              value={saturdayPrice}
              setValue={(value) => setSaturdayPrice(value)}
              weekdayIndex={5}
              submittedOnce={submittedOnce}
            />
            <DynamicPriceInput
              value={sundayPrice}
              setValue={(value) => setSundayPrice(value)}
              weekdayIndex={6}
              submittedOnce={submittedOnce}
            />
          </Box>
        </Box>
      ) : null}
    </>
  );

  const renderExcursionImageGallery = (
    <>
      <ImageGalleryHeader
        label="Adicionar"
        icon={<AddPhotoAlternateIcon />}
        setImages={setImages}
        originalImages={originalImages}
        previewImages={previewImages}
      />
      <Box display="flex" flexDirection="column" gap={2} margin="25px">
        <ImageGallery
          images={images}
          setImages={setImages}
          setOriginalImages={setOriginalImages}
          setPreviewImages={setPreviewImages}
          interable
          emptyGalleryText={
            <Typography textAlign="justify">
              Adicione
              <span
                style={{
                  color: "primary.main",
                  fontWeight: "bold",
                }}
              >
                {" "}
                imagens{" "}
              </span>
              do seu serviço!
            </Typography>
          }
        />
      </Box>
    </>
  );

  const renderEmployeeSelection = (
    <Box>
      <CEOInterfaceHeader title="Selecione funcionário(s) para o serviço" />
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="center"
        gap={1}
        alignItems="center"
      >
        <Box
          display="grid"
          maxWidth="100%"
          gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))"
          columnGap={4}
          rowGap={6}
          sx={{
            "@media only screen and (max-width: 600px)": {
              gridTemplateColumns: "2fr",
            },
          }}
        >
          {assignableEmployees.length > 0 ? (
            assignableEmployees.map((employee) => (
              <Box
                onClick={() => {
                  handleSelectEmployee(employee.id);
                }}
                key={`${employee.id}_key`}
              >
                <CustomCard
                  photoURL={employee.User.imagePreview}
                  defaultPhoto={defaultEmployeeImage}
                  header={""}
                  primaryText={employee.User.name}
                  secondaryText={""}
                  isSelected={!!selectedEmployeeIds.includes(employee.id)}
                />
              </Box>
            ))
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="35px"
        marginTop="30px"
      >
        <Typography
          color="error"
          sx={{ display: noEmployeesSelected ? "flex" : "none" }}
        >
          É necessário selecionar pelo menos um funcionário.
        </Typography>
      </Box>
    </Box>
  );

  const renderAddServicePageContent = (
    <form onSubmit={onSubmit(handleServiceAndServiceStoreCreation)}>
      {renderServiceData}
      {renderDynamicPrice}
      {renderExcursionImageGallery}
      {renderEmployeeSelection}
      <Box marginBottom="35px">
        {generalErrorMessage !== "" ? (
          <Alert severity="error">{generalErrorMessage}</Alert>
        ) : null}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        gap={2}
        sx={{
          "@media (max-width: 800px)": {
            flexDirection: "column",
          },
        }}
      >
        <PrimaryButton
          label="Confirmar adição"
          isLoading={buttonsDisabled}
          icon={<AddIcon />}
          onClickAction={() => {
            if (!submittedOnce) setSubmittedOnce(true);
          }}
        />

        <Button
          variant="outlined"
          onClick={() => {
            navigate("/services");
          }}
          fullWidth
          disabled={buttonsDisabled}
          sx={{ textTransform: "none" }}
        >
          Cancelar
        </Button>
      </Box>
    </form>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderAddServicePageContent} />
    </Box>
  );
};

export default AddService;
