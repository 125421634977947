import { Api } from "../..";
import { oneClickPlatform } from "../../../types/types";
import { responseBody } from "../util";

export const login = async (email: string, password: string) => {
  return await Api.post("/login", { email, password }).then(responseBody);
};

export const refreshToken = async (oldToken: string) => {
  return await Api.put("token/refresh", { oldToken }).then(responseBody);
};

const thirdPartyLogin = async (
  oneClickPlatform: string,
  token: string,
  name: string,
  email: string,
  imageOriginal: string
) => {
  return await Api.post("/login-third-party", {
    oneClickPlatform,
    token,
    name,
    email,
    imageOriginal,
  }).then(responseBody);
};

export const googleLogin = async (
  token: string,
  name: string,
  email: string,
  imageOriginal: string
) => {
  const response = await thirdPartyLogin(
    oneClickPlatform.GOOGLE,
    token,
    name,
    email,
    imageOriginal
  );
  console.log(response);
  return response.access_token;
};
