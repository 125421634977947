import { Box, Divider, Typography } from "@mui/material";
import AddImageInput from "../AddImageInput";
import { ReactNode } from "react";

interface ImageGalleryHeaderProps {
  label: string;
  icon?: ReactNode;
  setImages: React.Dispatch<React.SetStateAction<HTMLCanvasElement[]>>;
  originalImages: HTMLCanvasElement[];
  previewImages: HTMLCanvasElement[];
}

const ImageGalleryHeader = (props: ImageGalleryHeaderProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight="bold">
          Galeria de fotos
        </Typography>
        <AddImageInput
          label={props.label}
          setImages={props.setImages}
          originalImages={props.originalImages}
          previewImages={props.previewImages}
          icon={props.icon}
        />
      </Box>
      <Divider />
    </Box>
  );
};

export default ImageGalleryHeader;
