import { type AxiosResponse } from "axios";
import { Api } from "../..";

const responseBody = (response: AxiosResponse) => response.data;

const doRequest = {
  get: async (url: string) => await Api.get(url).then(responseBody),
  post: async (url: string, body: {}) =>
    await Api.post(url, body).then(responseBody),
  put: async (url: string, body: {}) =>
    await Api.put(url, body).then(responseBody),
  patch: async (url: string, body: {}) =>
    await Api.patch(url, body).then(responseBody),
  delete: async (url: string) => await Api.delete(url).then(responseBody),
};

export { doRequest as default, responseBody };
