import {
  Avatar,
  Box,
  CircularProgress,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { useNavigate } from "react-router-dom";
import CEODrawer from "../../../../../components/turism/CEODrawer";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import { useEffect, useState } from "react";
import storeApi from "../../../../../services/api/Store";
import { type IStore } from "../../../../../services/api/Store/types";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { useAuth } from "../../../../../context/AuthProvider/useAuth";
import {
  generalBrightGrey,
} from "../../../../../styles/colors";
import defaultStoreImage from "../../../../../assets/svg/defaultStore.svg";
import WebIcon from "@mui/icons-material/Web";
import { deleteImageFromFirebase } from "../../../../../utils/firebase";

const Agencies = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [stores, setStores] = useState<IStore[]>([]);
  const [areStoresLoading, setAreStoresLoading] = useState(true);

  const fetchStores = async () => {
    try {
      const allStores = await storeApi.getManagerStores(auth.user.id);
      setStores(allStores);
      setAreStoresLoading(false);
    } catch (error) {
      console.warn("Error getting all stores: ", error);
    }
  };

  useEffect(() => {
    fetchStores();
  }, [auth]);

  const handleSelectStore = (store: IStore): void => {
    localStorage.setItem("selectedStore", JSON.stringify(store));
  };

  const handleSeeStoreDetailsClick = (store: IStore): void => {
    handleSelectStore(store);
    navigate("/agencies/details");
  };

  const handleEditStoreClick = (store: IStore): void => {
    handleSelectStore(store);
    navigate("/agencies/edit");
  };

  const handleDeleteStore = async (store: IStore) => {
    const wantToDelete: boolean = window.confirm(
      `Tem certeza que deseja excluir ${store.name}?`
    );

    if (wantToDelete) {
      try {
        if(store.imageOriginal.length > 0){
        const deleteImagePromises = [];

        for (let i = 0; i < store.imageOriginal.length; i++) {
          deleteImagePromises.push(deleteImageFromFirebase(store.imageOriginal[i]));
          deleteImagePromises.push(deleteImageFromFirebase(store.imagePreview[i]));
        }

  await Promise.all(deleteImagePromises);}
        await storeApi.deleteStore(store.id);

        fetchStores();
      } catch (error) {
        console.error("Error deleting the store: ", error);
      }
    }
  };

  const renderStoresPageContent = (
    <Box>
      <CEOInterfaceHeader
        title="Suas agências"
        buttonIcon={<AddBusinessIcon />}
        buttonLabel="Adicionar"
        buttonAction={() => {
          navigate("/agencies/add");
        }}
      />
      <Box width="100%">
        {!areStoresLoading ? (
          <TableContainer sx={styles.agencyItemContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Agência</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stores.length !== 0 ? (
                  stores.map((store) => (
                    <TableRow key={store.id} sx={styles.agencyItemContainer}>
                      <TableCell align="left">
                        <Box display="flex" gap={2} alignItems="center">
                          <Avatar
                            src={store.imagePreview[0] ?? defaultStoreImage}
                            sx={styles.avatarStyle}
                          />

                          <ListItemText
                            primary={`${store.name}`}
                            secondary={`CNPJ: ${store?.CNPJ}`}
                          />
                        </Box>
                      </TableCell>

                      <TableCell align="right">
                        <Box>
                          <Tooltip title="Visualizar página">
                            <IconButton
                              onClick={() => {
                                handleSeeStoreDetailsClick(store);
                              }}
                              color="primary"
                            >
                              <WebIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Editar">
                            <IconButton
                              onClick={() => {
                                handleEditStoreClick(store);
                              }}
                              color="primary"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Excluir">
                            <IconButton
                              onClick={() => {
                                handleDeleteStore(store);
                              }}
                              color="primary"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography color="text.secondary" variant="body2">
                        Ainda não há agências cadastrados
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={styles.loadingContainer}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderStoresPageContent} />
    </Box>
  );
};

export default Agencies;

const styles = {
  agencyItemContainer: {
    margin: "30px 0px",
    width: "100%",
    border: `1px solid ${generalBrightGrey}`,
    borderRadius: "15px",
  },

  avatarStyle: {
    width: "80px",
    height: "80px",
    borderRadius: 0,
  },

  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "50px 0px",
  },
};
