import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CEODrawer from "../../../../../components/pet-beauty/CEODrawer";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import { useAuth } from "../../../../../context/AuthProvider/useAuth";
import { userApi } from "../../../../../services";
import serviceEmployeeApi from "../../../../../services/api/ServiceEmployee";
import serviceStoreApi from "../../../../../services/api/ServiceStore";
import { IServiceStore } from "../../../../../services/api/ServiceStore/types";
import storeApi from "../../../../../services/api/Store";
import { IStore } from "../../../../../services/api/Store/types";
import { IUser } from "../../../../../services/api/User/types";
import {
  generalBrightGrey,
  dmPrimaryOrange,
  generalDarkGrey,
  petPrimaryGreen,
  beautyPrimaryPurple,
} from "../../../../../styles/colors";
import { cleanLocalStorage } from "../../../../../utils/cleanLocalStorage";
import { priceFormatter } from "../../../../../utils/priceFormatter";
import {
  CURRENT_SUBDOMAIN,
  DEMANDORIA_SUBDOMAINS,
} from "../../../../../utils/stringUtils";
import { deleteImageFromFirebase } from "../../../../../utils/firebase";

const CURRENT_DAY_INDEX = new Date().getDay();

const ServicesPage = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  cleanLocalStorage();

  const [stores, setStores] = useState<IStore[]>([]);
  const [serviceStores, setServiceStores] = useState<
    Record<string, IServiceStore[]>
  >({});
  const [areStoresLoading, setAreStoresLoading] = useState(true);

  const fetchUserStores = async (): Promise<void> => {
    try {
      const allStores = await storeApi.getManagerStores(auth.user.id);
      setStores(allStores);
    } catch (error) {
      console.warn("Error getting all stores: ", error);
    }
    setAreStoresLoading(false);
  };

  const fetchServiceStoresFromAStore = async (
    storeId: string
  ): Promise<void> => {
    try {
      const specificServiceStores =
        await storeApi.getServiceStoresFromStore(storeId);
      setServiceStores((prevServiceStore) => ({
        ...prevServiceStore,
        [storeId]: specificServiceStores,
      }));
    } catch (error) {
      console.warn("Error getting service stores:", error);
    }
  };

  useEffect(() => {
    fetchUserStores();
  }, [auth]);

  useEffect(() => {
    stores.forEach((store) => {
      fetchServiceStoresFromAStore(store.id);
    });
  }, [stores]);

  const handleSelectStoreId = (store: IStore) => {
    localStorage.setItem("selectedStore", JSON.stringify(store));
  };

  const handleSelectServiceStore = (serviceStore: IServiceStore) => {
    localStorage.setItem("selectedServiceStore", JSON.stringify(serviceStore));
  };

  const handleSeeServiceStoreDetailsClick = async (
    serviceStore: IServiceStore
  ) => {
    handleSelectServiceStore(serviceStore);

    try {
      const serviceEmployeeResponse =
        await serviceEmployeeApi.getServiceEmployeeByServiceStoreId(
          serviceStore.id
        );

      const serviceEmployeeUsers: IUser[] = [];

      let iterableUser = null;
      for (let i = 0; i < serviceEmployeeResponse.length; i++) {
        iterableUser = await userApi.getUser(
          serviceEmployeeResponse[i].Employee.userId
        );
        serviceEmployeeUsers.push(iterableUser);
      }

      localStorage.setItem(
        "selectedServiceEmployeeUsers",
        JSON.stringify(serviceEmployeeUsers)
      );
    } catch (error) {
      console.warn("Error fetching service-employees: ", error);
    }

    navigate("/services/details");
  };

  const handleAddServiceClick = (store: IStore) => {
    handleSelectStoreId(store);
    navigate("/services/add");
  };

  const handleEditServiceStoreClick = async (serviceStore: IServiceStore) => {
    handleSelectServiceStore(serviceStore);

    try {
      const serviceEmployeeResponse =
        await serviceEmployeeApi.getServiceEmployeeByServiceStoreId(
          serviceStore.id
        );

      const serviceEmployeeEmployeeIds: string[] = [];

      let iterableEmployee = null;
      for (let i = 0; i < serviceEmployeeResponse.length; i++) {
        iterableEmployee = serviceEmployeeResponse[i].employeeId;
        serviceEmployeeEmployeeIds.push(iterableEmployee);
      }

      localStorage.setItem(
        "selectedServiceEmployeeEmployeeIds",
        JSON.stringify(serviceEmployeeEmployeeIds)
      );
    } catch (error) {
      console.warn("Error fetching service-employee: ", error);
    }

    navigate("/services/edit");
  };

  const handleDeleteServiceStore = async (serviceStore: IServiceStore) => {
    const wantToDelete: boolean = window.confirm(
      `Tem certeza que deseja excluir ${serviceStore.Service.name}?`
    );

    if (wantToDelete) {
      try {
       if(serviceStore.imageOriginal.length > 0){
        const deleteImagePromises = [];

        for (let i = 0; i < serviceStore.imageOriginal.length; i++) {
          deleteImagePromises.push(deleteImageFromFirebase(serviceStore.imageOriginal[i]));
          deleteImagePromises.push(deleteImageFromFirebase(serviceStore.imagePreview[i]));
        }

  await Promise.all(deleteImagePromises);
       }
        await serviceStoreApi.deleteServiceStore(serviceStore.id);
        console.log(`Service-store ${serviceStore.id} deleted with success.`);
        fetchServiceStoresFromAStore(serviceStore.storeId);
      } catch (error) {
        console.error("Error deleting the service-store: ", error);
      }
    }
  };

  const renderServicesPageContent = (
    <Box>
      {!areStoresLoading ? (
        <>
          {stores.length !== 0 ? (
            stores.map((store) => (
              <Box key={store.id}>
                <CEOInterfaceHeader
                  title={`Serviços de ${store.name}`}
                  buttonLabel="Adicionar serviço"
                  buttonIcon={<AddIcon />}
                  buttonAction={() => {
                    handleAddServiceClick(store);
                  }}
                />
                {serviceStores[store.id] &&
                serviceStores[store.id].length > 0 ? (
                  <Box width="100%">
                    <TableContainer sx={styles.serviceItemContainer}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Serviço</TableCell>
                            <TableCell
                              align="center"
                              sx={styles.priceHeaderContainer}
                            >
                              Preço (hoje)
                            </TableCell>
                            <TableCell align="right">Ações</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {serviceStores[store.id]?.map((serviceStore) => (
                            <TableRow key={serviceStore.id}>
                              <TableCell>{serviceStore.Service.name}</TableCell>
                              <TableCell
                                align="center"
                                sx={styles.priceHeaderContainer}
                              >{`R$ ${priceFormatter(
                                serviceStore.priceDay[CURRENT_DAY_INDEX]
                              )}`}</TableCell>
                              <TableCell align="right">
                                <Tooltip title="Detalhes">
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      handleSeeServiceStoreDetailsClick(
                                        serviceStore
                                      );
                                    }}
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Editar">
                                  <IconButton
                                    color="primary"
                                    onClick={async () => {
                                      await handleEditServiceStoreClick(
                                        serviceStore
                                      );
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Excluir">
                                  <IconButton
                                    color="primary"
                                    onClick={async () => {
                                      await handleDeleteServiceStore(
                                        serviceStore
                                      );
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ) : (
                  <Box sx={styles.noStoreRegisteredContainer}>
                    <Typography color={generalDarkGrey}>
                      Este estabelecimento ainda não possui nenhum serviço.
                    </Typography>
                  </Box>
                )}
              </Box>
            ))
          ) : (
            <>
              <Typography color={generalDarkGrey}>
                Nenhum estabelecimento cadastrado. Para cadastrar serviços é
                necessário{" "}
                <span
                  style={{
                    color:
                      CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[0]
                        ? beautyPrimaryPurple
                        : petPrimaryGreen,
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/stores/add");
                  }}
                >
                  cadastrar um estabelecimento
                </span>
                .
              </Typography>
            </>
          )}
        </>
      ) : (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderServicesPageContent} />
    </Box>
  );
};

export default ServicesPage;

const styles = {
  serviceItemContainer: {
    margin: "30px 0px",
    width: "100%",
    border: `1px solid ${generalBrightGrey}`,
    borderRadius: "15px",
  },

  priceHeaderContainer: {
    "@media(max-width:900px)": { display: "none" },
  },

  avatarStyle: {
    width: "80px",
    height: "80px",
    borderRadius: 0,
  },

  noStoreRegisteredContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px 30px 0px",
  },

  registerAStoreSpan: {
    color: dmPrimaryOrange,
    fontWeight: "bold",
    cursor: "pointer",
  },

  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "50px 0px",
  },
};
