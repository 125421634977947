import { Dispatch, SetStateAction } from "react";
import { AppointmentStatus } from "../../../types/types";

export const getPendentConfirmedCanceledAndFinishedAppointmentDatesFromUserAppointments =
  (
    filteredAppointments: any[],
    setPendentAppointments: Dispatch<SetStateAction<any[]>>,
    setConfirmedAppointments: Dispatch<SetStateAction<any[]>>,
    setFinishedAppointments: Dispatch<SetStateAction<any[]>>,
    setCanceledAppointments: Dispatch<SetStateAction<any[]>>
  ) => {
    const auxiliarPendentAppointmentDays = new Set<string>();
    const auxiliarConfirmedAppointmentDays = new Set<string>();
    const auxiliarCanceledAppointmentDays = new Set<string>();
    const auxiliarFinishedAppointmentDays = new Set<string>();

    let iterableAppointment = null;
    let iterableAppointmentDate = "";

    for (let i = 0; i < filteredAppointments.length; i++) {
      iterableAppointment = filteredAppointments[i];
      iterableAppointmentDate = iterableAppointment.date.split("T")[0];

      switch (iterableAppointment.status) {
        case AppointmentStatus.PENDENT:
          auxiliarPendentAppointmentDays.add(iterableAppointmentDate);
          break;
        case AppointmentStatus.CONFIRMED:
          auxiliarConfirmedAppointmentDays.add(iterableAppointmentDate);
          break;
        case AppointmentStatus.CANCELED:
          auxiliarCanceledAppointmentDays.add(iterableAppointmentDate);
          break;
        case AppointmentStatus.FINISHED:
          auxiliarFinishedAppointmentDays.add(iterableAppointmentDate);
          break;
      }
    }

    setPendentAppointments(Array.from(auxiliarPendentAppointmentDays));
    setConfirmedAppointments(Array.from(auxiliarConfirmedAppointmentDays));
    setCanceledAppointments(Array.from(auxiliarCanceledAppointmentDays));
    setFinishedAppointments(Array.from(auxiliarFinishedAppointmentDays));
  };
