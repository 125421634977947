import requests from "../util";
import { IService } from "./types";

const url = "service"

const serviceApi = {
  getService: (id: string): Promise<IService> => requests.get(`${url}/${id}`),
  getAllServices: (): Promise<IService[]> => requests.get(url),
  createService: (service: IService): Promise<IService> => requests.post(url, service),
  updateService: (id: string, service: {}): Promise<IService> =>
    requests.patch(`${url}/${id}`, service),
  deleteService: (id: string): Promise<void> => requests.delete(`${url}/${id}`),
  searchServices: (searchTerm: string): Promise<IService[]> => requests.get(`${url}/search/${searchTerm}`)
}

export default serviceApi