export const handleNullEntityAtribute = (atribute: string): string => {
  return atribute.length > 0 ? atribute : "-";
};

export const getDemandoriaSubdomain = () => {
  return window.location.host.split(".")[0];
};

export const shortenLargeTexts = (text: string, limitIndex: number): string => {
  let shortVersion = text;

  if (text.length > limitIndex)
    shortVersion = text.substring(0, limitIndex) + "...";

  return shortVersion;
};

export const CURRENT_SUBDOMAIN = getDemandoriaSubdomain();

export const DEMANDORIA_SUBDOMAINS = ["beleza", "pet", "turismo"];
