import { Box, SxProps, Typography } from "@mui/material";
import {
  generalDarkGrey,
  invalidAvailability,
  invalidAvailabilityBorder,
  validAvailability,
  validAvailabilityBorder,
} from "../../../styles/colors";

const WorkingScaleCaption = () => {
  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.captionContainer}>
        <Box
          sx={{
            ...styles.captionRectangle,
            backgroundColor: validAvailability,
            border: `1px solid ${validAvailabilityBorder}`,
          }}
        />
        <Typography>Disponível</Typography>
      </Box>
      <Box sx={styles.captionContainer}>
        <Box
          sx={{
            ...styles.captionRectangle,
            backgroundColor: invalidAvailability,
            border: `1px solid ${invalidAvailabilityBorder}`,
          }}
        />
        <Typography>Ocupado</Typography>
      </Box>
    </Box>
  );
};

export default WorkingScaleCaption;

const styles = {
  mainContainer: {
    display: "flex",
    gap: 3,
    alignItems: "center",
  } as SxProps,

  captionContainer: {
    display: "flex",
    gap: 1,
    alignItems: "center",
    color: generalDarkGrey,
  } as SxProps,

  captionRectangle: {
    width: "30px",
    height: "20px",
  } as SxProps,
};
