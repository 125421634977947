import { CSSProperties } from "react";
import { generalBlack } from "../../../styles/colors";
import { SxProps } from "@mui/material";

const styles = {
  footerSection: {
    backgroundColor: generalBlack,
  } as CSSProperties,

  footerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 0px",
    gap: 2,
  } as SxProps,

  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  } as SxProps,

  logoContainer: {
    display: "flex",
    gap: 0.5,
  } as SxProps,

  contactEmail: {
    textDecoration: "none",
    fontWeight: "bold",
  } as CSSProperties,
};

export default styles;
