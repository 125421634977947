import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  WEEKDAY_LABELS,
  WEEKDAY_LABELS_SHORT,
} from "../../../pages/turism/CEOPages/workingTimeConstants";
import { useState } from "react";
import {
  generalDarkGrey,
  invalidAvailabilityBorder,
  invalidAvailability as invalidAvailabilityColor,
  validAvailabilityBorder,
  validAvailability as validAvailabilityColor,
} from "../../../styles/colors";

interface WorkingScaleSelectorProps {
  timeMonday: string[];
  setTimeMonday: (time: string[]) => void;
  timeTuesday: string[];
  setTimeTuesday: (time: string[]) => void;
  timeWednesday: string[];
  setTimeWednesday: (time: string[]) => void;
  timeThursday: string[];
  setTimeThursday: (time: string[]) => void;
  timeFriday: string[];
  setTimeFriday: (time: string[]) => void;
  timeSaturday: string[];
  setTimeSaturday: (time: string[]) => void;
  timeSunday: string[];
  setTimeSunday: (time: string[]) => void;
  possibleMondayTimes: string[];
  possibleTuesdayTimes: string[];
  possibleWednesdayTimes: string[];
  possibleThursdayTimes: string[];
  possibleFridayTimes: string[];
  possibleSaturdayTimes: string[];
  possibleSundayTimes: string[];
}

const WorkingScaleSelector = (props: WorkingScaleSelectorProps) => {
  const [selectedWeekDayIndex, setSelectedWeekDayIndex] = useState(0);

  const selectedWeekDayTimes = [
    props.timeMonday,
    props.timeTuesday,
    props.timeWednesday,
    props.timeThursday,
    props.timeFriday,
    props.timeSaturday,
    props.timeSunday,
  ];

  const setSelectedWeekDayTimes = [
    props.setTimeMonday,
    props.setTimeTuesday,
    props.setTimeWednesday,
    props.setTimeThursday,
    props.setTimeFriday,
    props.setTimeSaturday,
    props.setTimeSunday,
  ];

  const possibleWeekTimes = [
    props.possibleMondayTimes,
    props.possibleTuesdayTimes,
    props.possibleWednesdayTimes,
    props.possibleThursdayTimes,
    props.possibleFridayTimes,
    props.possibleSaturdayTimes,
    props.possibleSundayTimes,
  ];

  const handleChangeSelectedWeekDayIndex = (
    _: React.MouseEvent<HTMLElement>,
    newIndex: number
  ) => {
    if (newIndex != null) setSelectedWeekDayIndex(newIndex);
  };

  const handleChangeTimeAvailability = (dayTimeLabel: string) => {
    const temporaryDayTimeArray = [
      ...selectedWeekDayTimes[selectedWeekDayIndex],
    ];

    if (!temporaryDayTimeArray.includes(dayTimeLabel)) {
      temporaryDayTimeArray.push(dayTimeLabel);
    } else {
      const index = temporaryDayTimeArray.indexOf(dayTimeLabel);
      temporaryDayTimeArray.splice(index, 1);
    }

    temporaryDayTimeArray.sort((a, b) => {
      const parsedA = Number(a.split(":")[0]);
      const parsedB = Number(b.split(":")[0]);
      return parsedA - parsedB;
    });

    setSelectedWeekDayTimes[selectedWeekDayIndex](temporaryDayTimeArray);
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      gap={2}
      margin="20px 0px 40px 0px"
    >
      <Box
        sx={{
          overflowX: "auto",
          padding: "8px 0px",
        }}
      >
        <ToggleButtonGroup
          size="large"
          fullWidth
          value={selectedWeekDayIndex}
          onChange={handleChangeSelectedWeekDayIndex}
          exclusive
          color="primary"
        >
          {WEEKDAY_LABELS_SHORT.map((dayLabel, index) => (
            <ToggleButton
              key={index}
              sx={{ textTransform: "none" }}
              value={index}
            >
              <Typography variant="body2">{dayLabel}</Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>

      <Box
        sx={{
          overflowX: "auto",
          padding: "8px 0px",
        }}
      >
        <ToggleButtonGroup
          size="large"
          fullWidth
          sx={{ display: "flex", gap: 0.2 }}
        >
          {possibleWeekTimes[selectedWeekDayIndex].length > 0 ? (
            possibleWeekTimes[selectedWeekDayIndex].map((dayLabel, index) => {
              const isTimeAvailable =
                selectedWeekDayTimes[selectedWeekDayIndex].includes(dayLabel);

              return (
                <ToggleButton
                  key={index}
                  sx={{
                    textTransform: "none",
                    backgroundColor: isTimeAvailable
                      ? validAvailabilityColor
                      : invalidAvailabilityColor,
                    border: `1px solid ${
                      isTimeAvailable
                        ? validAvailabilityBorder
                        : invalidAvailabilityBorder
                    }`,
                    minWidth: "100px",
                    height: "80px",

                    ":hover": {
                      backgroundColor: isTimeAvailable
                        ? validAvailabilityColor
                        : invalidAvailabilityColor,
                    },
                  }}
                  onClick={() => handleChangeTimeAvailability(dayLabel)}
                >
                  <Typography
                    color={
                      isTimeAvailable
                        ? validAvailabilityBorder
                        : invalidAvailabilityBorder
                    }
                    fontSize="13pt"
                  >
                    {dayLabel}
                  </Typography>
                </ToggleButton>
              );
            })
          ) : (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography color={generalDarkGrey}>
                Nenhum horário disponível para{" "}
                {WEEKDAY_LABELS[selectedWeekDayIndex].toLowerCase()}
              </Typography>
            </Box>
          )}
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default WorkingScaleSelector;
