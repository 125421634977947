import { object, string } from "yup";

export const addAndEditStoreSchema = object({
  storeName: string().required("Campo obrigatório."),
  email: string()
    .required("Campo obrigatório.")
    .email("Email digitado não é um endereço válido."),
});

export const validateCnpj = (cnpj: string) => {
  return cnpj.replace(/[./\-_]/g, "").length === 14;
};

export const validatePhoneNumber = (phone: string) => {
  return phone.replace(/[()\s._-]/g, "").length === 11;
};

export const showCustomInputFieldError = (
  isFieldValid: boolean,
  submittedOnce: boolean
) => {
  return isFieldValid && submittedOnce;
};
