import { useEffect, useRef, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  FormControl,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import EmailConfirmation from "../../../assets/svg/email-confirmation.svg";
import { ArrowBack } from "@mui/icons-material/";
import InputMask from "react-input-mask";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckIcon from "@mui/icons-material/Check";
import { PrimaryButton } from "../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../components/general/buttons/SecondaryButton";
import { userApi } from "../../../services";
import { DocType, BrazilianAddress, UserRole } from "../../../types/types";
import { fetchAddressByCEP } from "../../../utils/cepUtils";
import { registerSchema } from "../../../utils/yupSchemas";
import {
  DEMANDORIA_SUBDOMAINS,
  getDemandoriaSubdomain,
} from "../../../utils/stringUtils";

const steps = ["Dados pessoais", "Endereço", "Confirmar email"];

const currentSubdomain = getDemandoriaSubdomain();

const Register = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const roleSearchParam = searchParams.get("role");

  const [submittedOnce, setSubmittedOnce] = useState(false);

  const nameRef = useRef<HTMLInputElement>(null);
  const birthDateRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const [docNumber, setDocNumber] = useState("");
  const roleRef = useRef<HTMLSelectElement>(null);
  const docTypeRef = useRef<HTMLSelectElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmedPasswordRef = useRef<HTMLInputElement>(null);

  const [personalData, setPersonalData] = useState({
    name: "",
    yearBirth: "",
    roleRef: null,
    docType: null,
    docNumber: "",
    phoneNumber: "",
    email: "",
    password: "",
  });

  const streetRef = useRef<HTMLInputElement>(null);
  const numberRef = useRef<HTMLInputElement>(null);
  const districtRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);

  const [cep, setCep] = useState<string>("");

  const [emailAlreadyRegistered, setEmailAlreadyRegistered] =
    useState<boolean>(false);

  const [areAddressInputsDisabled, setAreAddressInputsDisabled] =
    useState(false);

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(registerSchema) });

  const handleUserCreation = async () => {
    setEmailAlreadyRegistered(false);

    try {
      await userApi.createUser({
        name: personalData.name,
        email: personalData?.email,
        password: personalData?.password,
        docNumber: personalData?.docNumber,
        docType: personalData?.docType,
        role: personalData?.role,
        phone: personalData?.phoneNumber,
        logradouro: streetRef.current.value,
        number: numberRef.current.value,
        district: districtRef.current.value,
        city: cityRef.current.value,
        state: stateRef.current.value,
        country: "Brasil",
        CEP: cep,
        yearBirth: personalData?.yearBirth,
        imageOriginal: "",
        imagePreview: "",
      });

      setActiveStep(2);
    } catch (error) {
      setActiveStep(0);
      console.warn("Error creating user: ", error);

      if (error.response.data.message === "Email já cadastrado!") {
        setEmailAlreadyRegistered(true);
        setActiveStep(0);
      }
    }
  };

  const handleFirstStepSubmit = () => {
    if (!submittedOnce) setSubmittedOnce(true);

    if (submittedOnce && isCPFValid()) {
      const userPersonalData = {
        name: nameRef.current.value,
        yearBirth: birthDateRef.current.value,
        role: roleRef.current.value,
        docType: docTypeRef.current.value,
        docNumber: docNumber,
        phoneNumber: phoneNumberRef.current.value,
        email: emailRef.current.value,
        password: passwordRef.current.value,
      };

      setPersonalData(userPersonalData);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleStepBackClick = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const cleanAddressFields = () => {
    streetRef.current.value = "";
    districtRef.current.value = "";
    cityRef.current.value = "";
    stateRef.current.value = "";
  };

  useEffect(() => {
    const fetchAddress = async () => {
      if (cep.replaceAll("_", "").length === 9) {
        try {
          const fetchedAddress: BrazilianAddress = await fetchAddressByCEP(cep);

          streetRef.current.value = fetchedAddress.street;
          districtRef.current.value = fetchedAddress.neighborhood;
          cityRef.current.value = fetchedAddress.city;
          stateRef.current.value = fetchedAddress.state;

          setAreAddressInputsDisabled(true);
        } catch (error) {
          console.error("Error fetching address from CEP: ", error);
        }
      } else {
        setAreAddressInputsDisabled(false);
        cleanAddressFields();
      }
    };

    fetchAddress();
  }, [cep]);

  const isCPFValid = () => {
    return docNumber.replace(/[._-]/g, "").length === 11;
  };

  const CpfField = (
    <InputMask
      mask="999.999.999-99"
      disabled={false}
      maskChar="_"
      value={docNumber}
      onChange={(e) => setDocNumber(e.target.value)}
    >
      <TextField
        label="CPF*"
        fullWidth
        autoComplete="off"
        defaultValue={personalData.docNumber ?? ""}
        error={submittedOnce && !isCPFValid()}
        helperText={submittedOnce && !isCPFValid() ? "CPF inválido." : ""}
      />
    </InputMask>
  );

  const renderFirstStepContent = (
    <form onSubmit={onSubmit(handleFirstStepSubmit)}>
      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          label="Nome completo *"
          type="text"
          inputRef={nameRef}
          fullWidth
          {...register("name")}
          error={!!errors.name}
          helperText={errors.name?.message}
        />

        <FormControl fullWidth>
          <InputLabel>Tipo de conta *</InputLabel>
          <Select
            label="Tipo de conta"
            inputRef={roleRef}
            defaultValue={roleSearchParam ?? UserRole.COMERCIAL}
          >
            <MenuItem value={UserRole.CLIENT}>Cliente</MenuItem>
            <MenuItem value={UserRole.COMERCIAL}>Comercial</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Tipo de documento *</InputLabel>
          <Select
            label="Tipo de documento"
            inputRef={docTypeRef}
            defaultValue={DocType.CPF}
          >
            <MenuItem value={DocType.CPF}>CPF</MenuItem>
            <MenuItem value={DocType.PASSPORT}>Passaporte</MenuItem>
          </Select>
        </FormControl>

        {docTypeRef.current?.value === DocType.CPF ? CpfField : CpfField}

        <TextField
          label="Data de nascimento *"
          type="date"
          inputRef={birthDateRef}
          {...register("yearBirth")}
          error={!!errors.yearBirth}
          helperText={errors.yearBirth?.message}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />

        <InputMask mask="(99) 9.9999-9999" disabled={false} maskchar="_">
          <TextField
            label="Telefone"
            type="phone"
            fullWidth
            autoComplete="off"
            inputRef={phoneNumberRef}
            defaultValue={personalData.phoneNumber ?? ""}
          />
        </InputMask>

        <TextField
          label="Email *"
          type="email"
          inputRef={emailRef}
          placeholder="exemplo@email.com"
          fullWidth
          {...register("email")}
          error={!!errors.email || emailAlreadyRegistered}
          helperText={
            errors.email?.message || emailAlreadyRegistered
              ? "Email já cadastrado."
              : ""
          }
        />

        <TextField
          label="Senha *"
          type="password"
          inputRef={passwordRef}
          fullWidth
          {...register("password")}
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        <TextField
          label="Confirme sua senha *"
          type="password"
          inputRef={confirmedPasswordRef}
          fullWidth
          {...register("confirmedPassword")}
          error={!!errors.confirmedPassword}
          helperText={errors.confirmedPassword?.message}
        />
      </Box>
      <Box marginTop="40px">
        <PrimaryButton
          label="Prosseguir"
          icon={<CheckIcon />}
          styles={{ height: "50px" }}
        />
      </Box>
    </form>
  );

  const renderSecondStepContainer = (
    <form onSubmit={onSubmit(handleUserCreation)}>
      <Box display="flex" flexDirection="column" gap={2}>
        <InputMask
          mask="99999-999"
          disabled={false}
          maskchar="_"
          value={cep}
          onChange={(e) => {
            setCep(e.target.value);
          }}
        >
          {<TextField label="CEP" fullWidth />}
        </InputMask>

        <Box display="flex" gap={2}>
          <TextField
            label="Logradouro"
            type="text"
            inputRef={streetRef}
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled={areAddressInputsDisabled}
          />
          <TextField
            label="N°"
            type="text"
            inputRef={numberRef}
            InputLabelProps={{ shrink: true }}
            fullWidth
            sx={{ maxWidth: "25%" }}
          />
        </Box>

        <TextField
          label="Bairro"
          type="text"
          inputRef={districtRef}
          InputLabelProps={{ shrink: true }}
          fullWidth
          disabled={areAddressInputsDisabled}
        />

        <Box display="flex" gap={2}>
          <TextField
            label="Cidade"
            type="text"
            inputRef={cityRef}
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled={areAddressInputsDisabled}
          />
          <FormControl sx={{ maxWidth: "25%" }}>
            <TextField
              label="Estado"
              type="text"
              inputRef={stateRef}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={areAddressInputsDisabled}
            />
          </FormControl>
        </Box>
        <Box marginTop="30px">
          <PrimaryButton
            label="Confirmar endereço"
            icon={<CheckIcon />}
            styles={{ height: "50px" }}
          />
        </Box>
      </Box>
    </form>
  );

  const renderThirdStepContainer = (
    <Box display="flex" flexDirection="column" gap={8}>
      <Box display="flex" flexDirection="column" alignItems="center" gap={6}>
        <Typography variant="h5" fontWeight="bold" textAlign="center">
          Bem-vindo ao Demandoria!
        </Typography>
        <Box>
          <img
            src={EmailConfirmation}
            alt="email confirmation icon"
            width="120px"
          />
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h6" fontWeight="bold" textAlign="center">
            Verifique seu email para concluir.
          </Typography>

          <Box>
            <Typography variant="body2" textAlign="center">
              Enviamos o link de confirmação para&nbsp;
              <b>{personalData.email}</b>.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <PrimaryButton
          label="Ir para login"
          icon={<LoginIcon />}
          onClickAction={() => navigate("/login")}
          styles={{ height: "50px" }}
        />
      </Box>
    </Box>
  );

  const renderContentByStep = () => {
    if (activeStep === 0) return renderFirstStepContent;
    else if (activeStep === 1) return renderSecondStepContainer;
    else return renderThirdStepContainer;
  };

  return (
    <Box height="100vh">
      <Box alignSelf="flex-start" marginBottom={-1}>
        <IconButton
          size="large"
          onClick={() => {
            navigate(-1);
          }}
          color="primary"
        >
          <ArrowBack />
        </IconButton>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4" fontWeight="bold">
          {currentSubdomain === DEMANDORIA_SUBDOMAINS[0]
            ? "Demandoria Beleza"
            : currentSubdomain === DEMANDORIA_SUBDOMAINS[1]
              ? "Demandoria PET"
              : "Demandoria Turismo"}
        </Typography>
        <Box display="flex" justifyContent="center" margin="15px 0px 30px 0px">
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>

        <Box
          minWidth="350px"
          padding="0px 20px"
          marginBottom="50px"
          display="flex"
          flexDirection="column"
          gap={1}
        >
          {renderContentByStep()}

          {activeStep === 2 ? null : (
            <SecondaryButton
              label="Voltar"
              isDisabled={activeStep == 0}
              onClickAction={handleStepBackClick}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
