import requests from "../util";
import { type ICartItem } from "./type";

const URL = "cart-item";

const cartItemApi = {
  getCartItem: async (id: string): Promise<ICartItem> =>
    await requests.get(`${URL}/${id}`),
  deleteCartItem: async (id: string): Promise<ICartItem> =>
    await requests.delete(`${URL}/${id}`),
};

export default cartItemApi;
