import requests from "../util";
import { IUser } from "./types";

const url = "users";

const userApi = {
  getUser: (id: string): Promise<IUser> => requests.get(`${url}/${id}`),
  getAllUsers: (): Promise<IUser[]> => requests.get(url),
  getUserByDocNumber: (docNumber: string): Promise<IUser> =>
    requests.get(`${url}/docNumber/${docNumber}`),
  createUser: (user: IUser): Promise<IUser> => requests.post(url, user),
  updateUser: (id: string, user: {}): Promise<IUser> =>
    requests.put(`${url}/${id}`, user),
  deleteUser: (id: string): Promise<void> => requests.delete(`${url}/${id}`),
  sendPasswordRecoveryCode: (emailRequestBody: Object) =>
    requests.post("/recovery-password", emailRequestBody),
  validatePasswordRecovery: (validationRequestBody: Object) =>
    requests.post("/recovery-password/validate-token", validationRequestBody),
};

export default userApi;
