import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import defaultEmployeeImage from "../../../../../assets/svg/defaultUser.svg";
import {
  createServiceEmployee,
  validateServiceStorePrices,
} from "../usualFunctions";
import CEODrawer from "../../../../../components/pet-beauty/CEODrawer";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import ImageGalleryHeader from "../../../../../components/comercial/ImageGallery/ImageGalleryHeader";
import CustomCard from "../../../../../components/general/CustomCard";
import { IEmployee } from "../../../../../services/api/Employee/types";
import serviceEmployeeApi from "../../../../../services/api/ServiceEmployee";
import serviceStoreApi from "../../../../../services/api/ServiceStore";
import { IServiceStore } from "../../../../../services/api/ServiceStore/types";
import storeApi from "../../../../../services/api/Store";
import { generalDarkGrey, generalWhite } from "../../../../../styles/colors";
import {
  deleteImageFromFirebase,
  uploadImagesToFirebase,
} from "../../../../../utils/firebase";
import PriceInput from "../../../../../components/general/PriceInput";
import DynamicPriceInput from "../DynamicPriceInput";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../../../components/general/buttons/SecondaryButton";
import { CURRENT_SUBDOMAIN } from "../../../../../utils/stringUtils";

const EditService = () => {
  const navigate = useNavigate();

  const selectedServiceEmployeeEmployeeIds: string[] = JSON.parse(
    localStorage.getItem("selectedServiceEmployeeEmployeeIds")
  );

  const selectedServiceStore: IServiceStore = JSON.parse(
    localStorage.getItem("selectedServiceStore")
  );

  const descriptionRef = useRef<HTMLInputElement>(null);

  const [dynamicPriceAllowed, setDynamicPriceAllowed] = useState(
    !selectedServiceStore.priceDay.every(
      (price) => price === selectedServiceStore.priceDay[0]
    )
  );

  selectedServiceStore.price = selectedServiceStore.price / 100;

  for (let i = 0; i < selectedServiceStore.priceDay?.length; i++) {
    selectedServiceStore.priceDay[i] = selectedServiceStore.priceDay[i] / 100;
  }

  const [newImages, setNewImages] = useState<HTMLCanvasElement[]>([]);

  const [originalImages, setOriginalImages] = useState<HTMLCanvasElement[]>([]);
  const [previewImages, setPreviewImages] = useState<HTMLCanvasElement[]>([]);

  const [originalImageUrls, setOriginalImageUrls] = useState<string[]>(
    selectedServiceStore.imageOriginal
  );
  const [previewImageUrls, setPreviewImageUrls] = useState<string[]>(
    selectedServiceStore.imagePreview
  );

  const [assignableEmployees, setAssignableEmployees] = useState<IEmployee[]>(
    []
  );

  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);

  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>(
    selectedServiceEmployeeEmployeeIds
  );

  const [noEmployeesSelected, setNoEmployeesSelected] =
    useState<boolean>(false);

  const [fixedPrice, setFixedPrice] = useState<number | null>(
    Number(selectedServiceStore.price)
  );

  const [sundayPrice, setSundayPrice] = useState<number | null>(
    Number(selectedServiceStore.priceDay[0])
  );
  const [mondayPrice, setMondayPrice] = useState<number | null>(
    Number(selectedServiceStore.priceDay[1])
  );
  const [tuesdayPrice, setTuesdayPrice] = useState<number | null>(
    Number(selectedServiceStore.priceDay[2])
  );
  const [wednesdayPrice, setWednesdayPrice] = useState<number | null>(
    Number(selectedServiceStore.priceDay[3])
  );
  const [thursdayPrice, setThursdayPrice] = useState<number | null>(
    Number(selectedServiceStore.priceDay[4])
  );
  const [fridayPrice, setFridayPrice] = useState<number | null>(
    Number(selectedServiceStore.priceDay[5])
  );
  const [saturdayPrice, setSaturdayPrice] = useState<number | null>(
    Number(selectedServiceStore.priceDay[6])
  );

  const [durationOfService, setDurationOfService] = useState<number>(
    selectedServiceStore.durationOfService
  );

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  const fetchEmployeesToService = async () => {
    try {
      const res = await storeApi.getEmployeesFromStore(
        selectedServiceStore.storeId
      );
      setAssignableEmployees(res);
    } catch (error) {
      console.warn("Error fetching employees: ", error);
    }
  };

  useEffect(() => {
    fetchEmployeesToService();
  }, []);

  const checkIfPricesAreValid = () => {
    return validateServiceStorePrices(
      fixedPrice,
      mondayPrice,
      tuesdayPrice,
      wednesdayPrice,
      thursdayPrice,
      fridayPrice,
      saturdayPrice,
      sundayPrice,
      dynamicPriceAllowed
    );
  };

  const handleUpdateServiceEmployee = async () => {
    try {
      const serviceEmployeeResponse =
        await serviceEmployeeApi.getServiceEmployeeByServiceStoreId(
          selectedServiceStore.id
        );
      for (let i = 0; i < serviceEmployeeResponse.length; i++) {
        await serviceEmployeeApi.deleteServiceEmployee(
          serviceEmployeeResponse[i].id
        );
      }

      for (let i = 0; i < selectedEmployeeIds.length; i++) {
        await createServiceEmployee(
          selectedEmployeeIds[i],
          selectedServiceStore.id
        );
      }
    } catch (error) {
      console.warn("Error updating service-employee: ", error);
    }
  };

  const handleServiceAndServiceStoreEdition = async () => {
    if (!submittedOnce) setSubmittedOnce(true);

    if (checkIfPricesAreValid()) {
      const maximumWeekPrice = Math.max(
        mondayPrice,
        tuesdayPrice,
        wednesdayPrice,
        thursdayPrice,
        fridayPrice,
        saturdayPrice,
        sundayPrice
      );

      if (!noEmployeesSelected) {
        setButtonsDisabled(!buttonsDisabled);

        await handleUpdateServiceEmployee();

        let iterableOriginalImageUrl = "";
        let iterablePreviewImageUrl = "";

        for (let i = 0; i < selectedServiceStore.imageOriginal.length; i++) {
          iterableOriginalImageUrl = selectedServiceStore.imageOriginal[i];
          iterablePreviewImageUrl = selectedServiceStore.imagePreview[i];

          if (!originalImageUrls.includes(iterableOriginalImageUrl))
            await deleteImageFromFirebase(iterableOriginalImageUrl);

          if (!previewImageUrls.includes(iterablePreviewImageUrl))
            await deleteImageFromFirebase(iterablePreviewImageUrl);
        }

        try {
          const originalUuids = await uploadImagesToFirebase(
            originalImages,
            originalImageUrls,
            `${CURRENT_SUBDOMAIN}/stores/${selectedServiceStore.Store.name}_${selectedServiceStore.Store.id}/services/${selectedServiceStore.Service.name}_${selectedServiceStore.id}`,
            "_original",
            []
          );

          await uploadImagesToFirebase(
            previewImages,
            previewImageUrls,
            `${CURRENT_SUBDOMAIN}/stores/${selectedServiceStore.Store.name}_${selectedServiceStore.Store.id}/services/${selectedServiceStore.Service.name}_${selectedServiceStore.id}`,
            "_preview",
            originalUuids
          );

          const updatedServiceStore = {
            description: descriptionRef?.current?.value,
            price: !dynamicPriceAllowed ? fixedPrice : maximumWeekPrice,
            imageOriginal: originalImageUrls,
            imagePreview: previewImageUrls,
            priceDay: dynamicPriceAllowed
              ? [
                  sundayPrice,
                  mondayPrice,
                  tuesdayPrice,
                  wednesdayPrice,
                  thursdayPrice,
                  fridayPrice,
                  saturdayPrice,
                ]
              : [
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                ],
            durationOfService,
          };

          await serviceStoreApi.updateServiceStore(
            selectedServiceStore.id,
            updatedServiceStore
          );

          window.alert(
            `${selectedServiceStore.Service.name} editado com sucesso!`
          );
          navigate("/services");
        } catch (error) {
          setButtonsDisabled(false);
          console.warn("Error editing a service or services store: ", error);
        }
      }
    }
  };

  const handleToggleDynamicPrices = () => {
    setDynamicPriceAllowed(!dynamicPriceAllowed);
  };

  const handleDeleteExistingImageClick = async (index: number) => {
    setOriginalImageUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
    setPreviewImageUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  const handleRemoveNewImageClick = (index: number) => {
    if (newImages.length > 0)
      setNewImages((previousImages) =>
        previousImages.filter((_, i) => i !== index)
      );
  };

  useEffect(() => {
    selectedEmployeeIds.length > 0
      ? setNoEmployeesSelected(false)
      : setNoEmployeesSelected(true);
  }, [selectedEmployeeIds]);

  const handleSelectEmployee = (employeeId: string) => {
    if (!selectedEmployeeIds.includes(employeeId))
      setSelectedEmployeeIds((prevSelectedEmployeeIds) => [
        ...prevSelectedEmployeeIds,
        employeeId,
      ]);
    else
      setSelectedEmployeeIds((prevSelectedEmployeeIds) =>
        prevSelectedEmployeeIds.filter((id) => id !== employeeId)
      );
  };

  const renderServiceData = (
    <Box>
      <CEOInterfaceHeader title="Dados do serviço" />
      <Box
        display="flex"
        justifyContent="space-around"
        margin="25px"
        gap={5}
        sx={{
          "@media(max-width: 1020px)": {
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          width="60%"
          sx={{
            "@media(max-width: 1020px)": {
              width: "100%",
            },
          }}
        >
          <Box width="100%" display="flex" gap={2}>
            <TextField
              size="small"
              label="Nome do serviço*"
              value={selectedServiceStore.Service.name}
              fullWidth
              disabled
            />
            <TextField
              size="small"
              label="Duração (horas)"
              type="number"
              autoComplete="off"
              value={durationOfService}
              onChange={(e) => setDurationOfService(Number(e.target.value))}
              InputProps={{ inputProps: { min: 1 } }}
            />
          </Box>

          <TextField
            size="small"
            label="Descrição"
            multiline
            rows={4}
            fullWidth
            inputRef={descriptionRef}
            defaultValue={selectedServiceStore.description}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          width="40%"
          sx={{
            "@media(max-width: 1020px)": {
              width: "100%",
            },
          }}
        >
          {!dynamicPriceAllowed ? (
            <PriceInput
              label="Preço fixo (R$)"
              size="small"
              placeholder="Ex.: R$ 50,00"
              value={fixedPrice.toFixed(2)}
              onChange={(e) => setFixedPrice(Number(e.target.value))}
              error={!checkIfPricesAreValid() && submittedOnce}
              helperText={
                !checkIfPricesAreValid() && submittedOnce
                  ? "O preço deve ser maior que R$ 0,00"
                  : null
              }
              autoComplete="off"
              inputRef={(input) => {
                if (input && !checkIfPricesAreValid() && submittedOnce)
                  input.focus();
              }}
            />
          ) : null}

          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleToggleDynamicPrices}
                  checked={dynamicPriceAllowed}
                />
              }
              label="Preço dinâmico"
            />
            <Typography textAlign="justify" color={generalDarkGrey}>
              O preço dinâmico possibilita preços diferentes em diferentes dias
              da semana.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const renderDynamicPrice = (
    <>
      {dynamicPriceAllowed ? (
        <Box>
          <CEOInterfaceHeader title="Preço dinâmico (R$)" />
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            margin="25px"
            gap={1}
            alignItems="center"
            ref={(ref) => {
              if (ref && !checkIfPricesAreValid() && submittedOnce)
                ref.querySelector('input[aria-invalid="true"]').focus();
            }}
          >
            <DynamicPriceInput
              value={mondayPrice.toFixed(2)}
              setValue={(value) => setMondayPrice(value)}
              weekdayIndex={0}
              submittedOnce={submittedOnce}
            />
            <DynamicPriceInput
              value={tuesdayPrice.toFixed(2)}
              setValue={(value) => setTuesdayPrice(value)}
              weekdayIndex={1}
              submittedOnce={submittedOnce}
            />
            <DynamicPriceInput
              value={wednesdayPrice.toFixed(2)}
              setValue={(value) => setWednesdayPrice(value)}
              weekdayIndex={2}
              submittedOnce={submittedOnce}
            />
            <DynamicPriceInput
              value={thursdayPrice.toFixed(2)}
              setValue={(value) => setThursdayPrice(value)}
              weekdayIndex={3}
              submittedOnce={submittedOnce}
            />
            <DynamicPriceInput
              value={fridayPrice.toFixed(2)}
              setValue={(value) => setFridayPrice(value)}
              weekdayIndex={4}
              submittedOnce={submittedOnce}
            />
            <DynamicPriceInput
              value={saturdayPrice.toFixed(2)}
              setValue={(value) => setSaturdayPrice(value)}
              weekdayIndex={5}
              submittedOnce={submittedOnce}
            />
            <DynamicPriceInput
              value={sundayPrice.toFixed(2)}
              setValue={(value) => setSundayPrice(value)}
              weekdayIndex={6}
              submittedOnce={submittedOnce}
            />
          </Box>
        </Box>
      ) : null}
    </>
  );

  const renderExcursionImageGallery = (
    <>
      <ImageGalleryHeader
        label="Adicionar"
        icon={<AddPhotoAlternateIcon />}
        setImages={setNewImages}
        originalImages={originalImages}
        previewImages={previewImages}
      />
      <Box display="flex" flexDirection="column" gap={2} margin="25px">
        {newImages.length > 0 || originalImageUrls.length > 0 ? (
          <ImageList
            sx={{ marginBottom: "40px" }}
            cols={3}
            variant="quilted"
            rowHeight={300}
          >
            {originalImageUrls.length > 0 ? (
              originalImageUrls.map((imageUrl, index) => (
                <ImageListItem key={index}>
                  <img src={imageUrl} alt={`Image ${index}`} loading="lazy" />
                  <ImageListItemBar
                    sx={{
                      background:
                        "linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, " +
                        "rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%)",
                    }}
                    position="top"
                    actionIcon={
                      <IconButton
                        sx={{ color: generalWhite }}
                        onClick={() => handleDeleteExistingImageClick(index)}
                      >
                        <ClearIcon />
                      </IconButton>
                    }
                    actionPosition="left"
                  />
                </ImageListItem>
              ))
            ) : (
              <></>
            )}
            {newImages.map((image, index) => (
              <ImageListItem key={index}>
                <img
                  src={image.toDataURL()}
                  alt={`Image ${index}`}
                  loading="lazy"
                />

                <ImageListItemBar
                  sx={{
                    background:
                      "linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, " +
                      "rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%)",
                  }}
                  position="top"
                  actionIcon={
                    <IconButton
                      sx={{ color: generalWhite }}
                      onClick={() => handleRemoveNewImageClick(index)}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                  actionPosition="left"
                />
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={2}
            color={generalDarkGrey}
            margin="70px 0px"
          >
            <AddPhotoAlternateIcon fontSize="large" />
            <Box>
              <Typography textAlign="justify">
                Adicione
                <span
                  style={{
                    color: "primary.main",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  imagens{" "}
                </span>
                do seu serviço!
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );

  const renderEmployeeSelection = (
    <Box>
      <CEOInterfaceHeader title="Funcionários para o serviço" />
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="center"
        margin="25px"
        gap={1}
        alignItems="center"
      >
        <Box
          display="grid"
          maxWidth="100%"
          gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))"
          columnGap={4}
          rowGap={6}
          marginBottom="50px"
          sx={{
            "@media only screen and (max-width: 600px)": {
              gridTemplateColumns: "2fr",
            },
          }}
          marginTop="30px"
        >
          {assignableEmployees.length > 0 ? (
            assignableEmployees.map((employee) => (
              <Box
                onClick={() => {
                  handleSelectEmployee(employee.id);
                }}
                key={`${employee.id}_key`}
              >
                <CustomCard
                  photoURL={employee.User.imagePreview}
                  defaultPhoto={defaultEmployeeImage}
                  header={""}
                  primaryText={employee.User.name}
                  secondaryText={""}
                  isSelected={selectedEmployeeIds.includes(employee.id)}
                />
              </Box>
            ))
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="35px"
        marginBottom="20px"
      >
        <Typography
          color="error"
          sx={{ display: noEmployeesSelected ? "flex" : "none" }}
        >
          É necessário selecionar pelo menos um funcionário.
        </Typography>
      </Box>
    </Box>
  );

  const renderAddServicePageContent = (
    <form onSubmit={handleServiceAndServiceStoreEdition}>
      {renderServiceData}
      {renderDynamicPrice}
      {renderExcursionImageGallery}
      {renderEmployeeSelection}
      <Box
        display="flex"
        justifyContent="space-between"
        gap={2}
        sx={{
          "@media (max-width: 800px)": {
            flexDirection: "column",
          },
        }}
      >
        <PrimaryButton
          label="Confirmar edição"
          isLoading={buttonsDisabled}
          icon={<EditIcon />}
        />

        <SecondaryButton
          label="Cancelar"
          onClickAction={() => {
            navigate("/services");
          }}
          disabled={buttonsDisabled}
        />
      </Box>
    </form>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderAddServicePageContent} />
    </Box>
  );
};

export default EditService;
