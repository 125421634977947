// @ts-nocheck
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import {
  invalidAvailability as invalidAvailabilityColor,
  validAvailability as validAvailabilityColor,
} from "../../../styles/colors";

interface AvailabilityCardProps {
  isAvailable?: boolean;
  time: string;
  function?: any;
}

export const AvailabilityCard = (props: AvailabilityCardProps) => {
  return (
    <Card
      sx={{
        height: "220px",
        width: "180px",
        backgroundColor: props.isAvailable
          ? validAvailabilityColor
          : invalidAvailabilityColor,
        borderRadius: "10px",
      }}
      onClick={props.isAvailable ? props.function : null}
    >
      <CardActionArea disabled={props.isAvailable ? false : true}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "30px",
            gap: 2,
          }}
        >
          <Box>
            {props.isAvailable ? (
              <CheckCircleIcon fontSize="large" />
            ) : (
              <ClearIcon fontSize="large" />
            )}
          </Box>
          <Typography gutterBottom variant="h5" fontWeight="bold">
            {props.time}
          </Typography>
          <Typography
            variant="body2"
            color={props.isAvailable ? "#4D994F" : "#9A275A"}
            fontWeight="bold"
          >
            {props.isAvailable ? "LIVRE" : "OCUPADO"}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
