import {
  Box,
  ImageList,
  ImageListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import defaultUserImage from "../../../../../assets/svg/defaultUser.svg";
import CEODrawer from "../../../../../components/pet-beauty/CEODrawer";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import CustomCard from "../../../../../components/general/CustomCard";
import { IServiceStore } from "../../../../../services/api/ServiceStore/types";
import { IUser } from "../../../../../services/api/User/types";
import { generalDarkGrey } from "../../../../../styles/colors";
import { priceFormatter } from "../../../../../utils/priceFormatter";

const ServiceView = () => {
  const selectedServiceStore: IServiceStore = JSON.parse(
    localStorage.getItem("selectedServiceStore")
  );

  const selectedServiceEmployeeUsers: IUser[] = JSON.parse(
    localStorage.getItem("selectedServiceEmployeeUsers")
  );

  const imageUrls: string[] = selectedServiceStore.imageOriginal ?? [""];

  const isMobile = useMediaQuery("(max-width: 1000px)");
  const isMediumSize = useMediaQuery("(max-width: 1600px)");

  const cols = isMobile ? 1 : isMediumSize ? 2 : 4;

  const renderServiceData = (
    <Box>
      <CEOInterfaceHeader title="Dados do serviço" />
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        margin="25px"
        gap={4}
      >
        <Box display="flex" gap={4}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box>
              <Typography fontWeight="bold" variant="h6">
                Nome do serviço:
              </Typography>
              <Typography variant="body1">
                {selectedServiceStore.Service.name}
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight="bold" variant="h6">
                Descrição:
              </Typography>
              <Typography variant="body1" textAlign="justify">
                {selectedServiceStore.description.length > 0 ? (
                  selectedServiceStore.description
                ) : (
                  <Typography color={generalDarkGrey}>
                    Este serviço não possui descrição.
                  </Typography>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const renderPrices = (
    <Box>
      <CEOInterfaceHeader title="Preços" />
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        margin="25px"
        gap={1}
      >
        <Box>
          <Typography variant="body1" fontWeight="bold">
            Segunda:
          </Typography>
          <Typography variant="body1">{`R$ ${priceFormatter(
            selectedServiceStore.priceDay[1]
          )}`}</Typography>
        </Box>
        <Box>
          <Typography variant="body1" fontWeight="bold">
            Terça:
          </Typography>
          <Typography variant="body1">{`R$ ${priceFormatter(
            selectedServiceStore.priceDay[2]
          )}`}</Typography>
        </Box>
        <Box>
          <Typography variant="body1" fontWeight="bold">
            Quarta:
          </Typography>
          <Typography variant="body1">{`R$ ${priceFormatter(
            selectedServiceStore.priceDay[3]
          )}`}</Typography>
        </Box>
        <Box>
          <Typography variant="body1" fontWeight="bold">
            Quinta:
          </Typography>
          <Typography variant="body1">{`R$ ${priceFormatter(
            selectedServiceStore.priceDay[4]
          )}`}</Typography>
        </Box>
        <Box>
          <Typography variant="body1" fontWeight="bold">
            Sexta:
          </Typography>
          <Typography variant="body1">{`R$ ${priceFormatter(
            selectedServiceStore.priceDay[5]
          )}`}</Typography>
        </Box>
        <Box>
          <Typography variant="body1" fontWeight="bold">
            Sábado:
          </Typography>
          <Typography variant="body1">{`R$ ${priceFormatter(
            selectedServiceStore.priceDay[6]
          )}`}</Typography>
        </Box>
        <Box>
          <Typography variant="body1" fontWeight="bold">
            Domingo:
          </Typography>
          <Typography variant="body1">{`R$ ${priceFormatter(
            selectedServiceStore.priceDay[0]
          )}`}</Typography>
        </Box>
      </Box>
    </Box>
  );

  const renderImageGallery = (
    <Box>
      <CEOInterfaceHeader title="Galeria de fotos" />
      <Box
        display="flex"
        flexDirection="column"
        margin="20px"
        gap={3}
        alignItems="center"
      >
        {imageUrls.length !== 0 ? (
          <ImageList
            sx={{ marginBottom: "40px" }}
            cols={cols}
            gap={10}
            variant="standard"
          >
            {imageUrls.map((imageUrl, index) => (
              <ImageListItem key={index}>
                <img
                  src={imageUrl}
                  alt={`Image ${index}`}
                  loading="lazy"
                  style={{
                    width: isMobile ? 250 : isMediumSize ? 300 : 300,
                    height: isMobile ? 250 : isMediumSize ? 300 : 300,
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            margin="30px 0px"
          >
            <Typography
              textAlign="center"
              color={generalDarkGrey}
              fontWeight="bold"
            >
              Galeria vazia.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );

  const renderSelectedEmployee = (
    <Box>
      <CEOInterfaceHeader title="Funcionários do serviço" />
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        margin="25px"
        gap={4}
      >
        {selectedServiceEmployeeUsers.map((user: IUser) => (
          <Box key={`${user.id}_key`}>
            <CustomCard
              photoURL={user.imagePreview}
              defaultPhoto={defaultUserImage}
              header={""}
              primaryText={user.name}
              secondaryText={""}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );

  const renderServiceViewContent = (
    <Box>
      {renderServiceData}
      {renderPrices}
      {renderImageGallery}
      {renderSelectedEmployee}
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderServiceViewContent} />
    </Box>
  );
};

export default ServiceView;
