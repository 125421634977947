export const setTokenLocalStorage = (token: {token: string} | null) => {
    localStorage.setItem("token", JSON.stringify(token))
}

export const getTokenLocalStorage = () => {
    const json = localStorage.getItem("token")
    if(!json) 
        return null

    const token = JSON.parse(json)
    return token
}
