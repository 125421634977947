import { Box, Modal } from "@mui/material";
import { ReactNode } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,

  bgcolor: "background.paper",
  p: 4,
  "@media (max-width: 600px)": {
    width: 300,
  },
};

interface GenericModalProps {
  open: any;
  onClose: any;
  content: ReactNode;
}

const GenericModal = (props: GenericModalProps) => {
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box sx={style} borderRadius="10px">
        {props.content}
      </Box>
    </Modal>
  );
};
export default GenericModal;
