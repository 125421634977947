import PeopleIcon from "@mui/icons-material/People";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import SearchIcon from "@mui/icons-material/Search";
import StoreIcon from "@mui/icons-material/Store";
import WorkIcon from "@mui/icons-material/Work";
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SxProps,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/pt-br";
import { useEffect, useState } from "react";
import defaultEmployeeImage from "../../../../assets/svg/defaultUser.svg";
import defaultServiceImage from "../../../../assets/svg/defaultService.svg";
import defaultStoreImage from "../../../../assets/svg/defaultStore.svg";
import utc from "dayjs/plugin/utc";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomCard from "../../../../components/general/CustomCard";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import appointmentApi from "../../../../services/api/Appointment";
import employeeApi from "../../../../services/api/Employee";
import { IEmployee } from "../../../../services/api/Employee/types";
import serviceApi from "../../../../services/api/Service";
import { IService } from "../../../../services/api/Service/types";
import serviceStoreApi from "../../../../services/api/ServiceStore";
import { IServiceStore } from "../../../../services/api/ServiceStore/types";
import storeApi from "../../../../services/api/Store";
import { IStore } from "../../../../services/api/Store/types";
import { priceFormatter } from "../../../../utils/priceFormatter";
import {
  IPromotionalSearchResult,
  IPromotionalSearchResultArray,
  IPromotionObject,
} from "../../../../services/api/Appointment/types";
import DmCalendar from "../../../../components/general/DmCalendar";
import { PrimaryButton } from "../../../../components/general/buttons/PrimaryButton";
import { shortenLargeTexts } from "../../../../utils/stringUtils";

dayjs.extend(utc);

const CURRENT_DAY_INDEX = new Date().getDay();

export const SearchPage = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const serviceSearchParam = searchParams.get("service");
  const storeSearchParam = searchParams.get("store");
  const promotionalSearchStartDateParam = searchParams.get("startDate");
  const promotionalSearchEndDateParam = searchParams.get("endDate");
  const employeeSearchParam = searchParams.get("employee");

  const [hasPromotionalSearchedOnce, setHasPromotionalSearchedOnce] =
    useState(false);

  const searchTypeInitialIndex =
    Number(localStorage.getItem("searchType")) ?? 3;

  const today = dayjs();

  // ============ PROMOTIONAL ============ //
  const [promotionalSearchInput, setPromotionalSearchInput] =
    useState<string>("");
  const [promotionalSearchAutocomplete, setPromotionalSearchAutocomplete] =
    useState<IService[]>([]);
  const [mainPromotions, setMainPromotions] = useState<IPromotionObject[]>([]);
  const [allPromotionResults, setAllPromotionResults] = useState<
    IPromotionalSearchResultArray[]
  >([]);
  const [highlightedDays, setHighlightedDays] = useState([]);

  const [initialPromotionalSearchDate, setInitialPromotionalSearchDate] =
    useState<Dayjs | null>(
      promotionalSearchStartDateParam
        ? dayjs.utc(promotionalSearchStartDateParam)
        : today
    );
  const [endingPromotionalSearchDate, setEndingPromotionalSearchDate] =
    useState<Dayjs | null>(
      dayjs.utc(promotionalSearchEndDateParam ?? initialPromotionalSearchDate)
    );

  const fetchPromotinalSearchForAutocomplete = async () => {
    try {
      const res = await serviceApi.searchServices(promotionalSearchInput);
      setPromotionalSearchAutocomplete(res);
    } catch (error) {
      console.warn("Error fetching promotional services: ", error);
    }
  };

  const handlePromotionalSearch = async (
    serviceId: string | null,
    startDate: Dayjs,
    endDate: Dayjs
  ) => {
    if (!hasPromotionalSearchedOnce) setHasPromotionalSearchedOnce(true);

    if (promotionalSearchInput.length > 0) {
      setSelectedDate(startDate);
      setSearchResultsLoading(true);

      const formattedStartDate = dayjs.utc(startDate).toISOString();
      const formattedEndDate = dayjs.utc(endDate).toISOString();

      setSearchParams({
        service: promotionalSearchInput,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      });

      try {
        const intervalAppointmentsResponse =
          await appointmentApi.getIntervalAppointments(
            serviceId,
            formattedStartDate,
            formattedEndDate
          );

        const auxiliarHighlightedDays = [];

        for (let i = 0; i < intervalAppointmentsResponse.promotion.length; i++)
          auxiliarHighlightedDays.push(
            intervalAppointmentsResponse.promotion[i].date
          );

        setHighlightedDays(auxiliarHighlightedDays);

        setAllPromotionResults(intervalAppointmentsResponse.schedule);
        setMainPromotions(intervalAppointmentsResponse.promotion);
      } catch (error) {
        console.warn("Error fetching promotional services: ", error);
      }
    }

    setSearchResultsLoading(false);
  };

  const handleSelectPromotionalService = async (
    promotionalSearchResult: IPromotionalSearchResult
  ) => {
    const res = await serviceStoreApi.getServiceStoresFromServiceNameAndStoreId(
      promotionalSearchResult.serviceName,
      promotionalSearchResult.storeId
    );

    handleSelectServiceClick(res[0].id);
  };

  useEffect(() => {
    if (promotionalSearchInput.length > 0)
      fetchPromotinalSearchForAutocomplete();
  }, [promotionalSearchInput]);
  // ===================================== //

  // ============ SERVICE ============ //
  const [serviceSearchInput, setServiceSearchInput] = useState<string>("");
  const [servicesAutocomplete, setServiceAutocomplete] = useState<IService[]>(
    []
  );
  const [serviceStoreResultsArray, setServiceStoreResultsArray] = useState<
    IServiceStore[]
  >([]);

  const fetchServicesForAutocomplete = async () => {
    try {
      const res = await serviceApi.searchServices(serviceSearchInput);
      setServiceAutocomplete(res);
    } catch (error) {
      console.warn("Error fetching services: ", error);
    }
  };

  const handleSearchServiceStores = async (serviceName: string) => {
    setSearchResultsLoading(true);
    setSearchParams({ service: serviceName });
    try {
      const res =
        await serviceStoreApi.getServiceStoresFromAServiceName(serviceName);
      setServiceStoreResultsArray(res);
    } catch (error) {
      console.warn("Error searching service-stores: ", error);
    }
    setSearchResultsLoading(false);
  };

  useEffect(() => {
    if (serviceSearchInput.length > 0) {
      handleSearchServiceStores(serviceSearchInput);
      fetchServicesForAutocomplete();
    } else {
      setServiceStoreResultsArray([]);
      setServiceAutocomplete([]);
      setSearchParams({});
    }
  }, [serviceSearchInput]);

  const handleSelectServiceClick = (serviceStoreId: string) => {
    localStorage.setItem("searchType", JSON.stringify(searchTypeIndex));
    navigate(`/service?id=${serviceStoreId}&selectedDate=${selectedDate.toISOString().split("T")[0]}`);
  };
  // ================================= //

  // ============ STORE ============ //
  const [storeSearchInput, setStoreSearchInput] = useState<string>("");
  const [storesAutocomplete, setStoresAutocomplete] = useState<IStore[]>([]);
  const [storeResultsArray, setStoresResultsArray] = useState<IStore[]>([]);

  const handleSearchStores = async (storeName: string) => {
    setSearchResultsLoading(true);
    setSearchParams({ store: storeName });
    try {
      const res = await storeApi.searchStores(storeName);
      setStoresResultsArray(res);
      setStoresAutocomplete(res);
    } catch (error) {
      console.warn("Error searching stores: ", error);
    }
    setSearchResultsLoading(false);
  };

  useEffect(() => {
    if (storeSearchInput.length > 0) {
      handleSearchStores(storeSearchInput);
    } else {
      setStoresResultsArray([]);
      setStoresAutocomplete([]);
      setSearchParams({});
    }
  }, [storeSearchInput]);

  const handleSelectStoreClick = (storeId: string) => {
    localStorage.setItem("searchType", JSON.stringify(searchTypeIndex));
    navigate(`/store?id=${storeId}`);
  };
  // =============================== //

  // ============ EMPLOYEE ============ //
  const [employeeSearchInput, setEmployeeSearchInput] = useState<string>("");
  const [employeesAutocomplete, setEmployeesAutocomplete] = useState<
    IEmployee[]
  >([]);
  const [employeesResultsArray, setEmployeesResultsArray] = useState<
    IEmployee[]
  >([]);

  const handleSearchEmployees = async (employeeName: string) => {
    setSearchResultsLoading(true);
    setSearchParams({ employee: employeeName });
    try {
      const res = await employeeApi.searchEmployees(employeeName);
      setEmployeesResultsArray(res);
      setEmployeesAutocomplete(res);
    } catch (error) {
      console.warn("Error searching employees: ", error);
    }
    setSearchResultsLoading(false);
  };

  useEffect(() => {
    if (employeeSearchInput.length > 0)
      handleSearchEmployees(employeeSearchInput);
    else {
      setEmployeesResultsArray([]);
      setEmployeesAutocomplete([]);
      setSearchParams({});
    }
  }, [employeeSearchInput]);

  const handleSelectEmployeeClick = (employeeId: string) => {
    localStorage.setItem("searchType", JSON.stringify(searchTypeIndex));
    navigate(`/employee?id=${employeeId}`);
  };
  // ================================== //

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());

  const [searchTypeIndex, setSearchTypeIndex] = useState<number>(
    searchTypeInitialIndex
  );

  const [searchResultsLoading, setSearchResultsLoading] = useState(false);

  const handleChangeTabIndex = (_, newValue: number) => {
    setSearchTypeIndex(newValue);
  };

  const renderResultsFoundedTypography = (resultsNumber: number) => {
    return (
      <Typography
        variant="h6"
        fontWeight="bold"
      >{`Resultados encontrados (${resultsNumber}):`}</Typography>
    );
  };

  const promotionalSearchResults = (
    <>
      <Box sx={styles.searchResultsContainer}>
        <Box sx={styles.promotionalSearchCalendarAndPricesContainer}>
          <DmCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            highlightedDays={highlightedDays}
          />
          <Box>
            <Typography variant="h6">
              Melhores preços para {selectedDate?.format("DD/MM/YYYY")}
            </Typography>
            <Typography>Serviço: {serviceSearchParam ?? ""}</Typography>

            <List sx={styles.bestPricesPromotionalSearchList}>
              {mainPromotions
                .filter(
                  (promotionResult) =>
                    promotionResult.date ===
                    selectedDate.toISOString().split("T")[0]
                )[0]
                ?.stores.map((promotionalSearchResult) => (
                  <ListItem>
                    <ListItemText
                      primary={shortenLargeTexts(
                        promotionalSearchResult.storyName,
                        18
                      )}
                      secondary={`R$ ${priceFormatter(
                        promotionalSearchResult.price
                      )}`}
                      secondaryTypographyProps={{
                        fontWeight: "bold",
                        color: "primary",
                      }}
                    />

                    <Box>
                      <PrimaryButton
                        onClickAction={() => {
                          handleSelectPromotionalService(
                            promotionalSearchResult
                          );
                        }}
                        label="Agendar"
                      />
                    </Box>
                  </ListItem>
                ))}
            </List>
          </Box>
        </Box>
      </Box>
    </>
  );

  const promotionalSearch = (
    <Box sx={styles.searchContainer}>
      <Autocomplete
        freeSolo
        options={promotionalSearchAutocomplete.map((service) => service.name)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Qual serviço você deseja?"
            size="small"
            sx={{ width: "400px" }}
            error={
              promotionalSearchInput.length === 0 && hasPromotionalSearchedOnce
            }
            helperText={
              promotionalSearchInput.length === 0 && hasPromotionalSearchedOnce
                ? "A busca por serviço não pode ser vazia."
                : null
            }
          />
        )}
        inputValue={promotionalSearchInput}
        onInputChange={(_, value) => {
          setPromotionalSearchInput(value);
        }}
        defaultValue={serviceSearchParam ?? ""}
      />

      <Box sx={styles.datesContainer}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <Box sx={styles.datesContainer}>
            <DatePicker
              label="Data de início"
              slotProps={{
                textField: {
                  size: "small",
                  sx: {
                    width: "150px",
                  },
                },
              }}
              disablePast
              value={initialPromotionalSearchDate}
              onChange={(e) => {
                setInitialPromotionalSearchDate(e);
              }}
              dayOfWeekFormatter={(day) => {}}
            />
            →
            <DatePicker
              label="Data final"
              slotProps={{
                textField: {
                  size: "small",
                  sx: {
                    width: "150px",
                  },
                },
              }}
              minDate={initialPromotionalSearchDate}
              disablePast
              value={endingPromotionalSearchDate}
              onChange={(e) => {
                setEndingPromotionalSearchDate(e);
              }}
              dayOfWeekFormatter={(day) => {}}
            />
          </Box>
        </LocalizationProvider>
        <IconButton
          color="primary"
          sx={styles.searchIcon}
          onClick={() => {
            handlePromotionalSearch(
              promotionalSearchAutocomplete[0]?.id ?? "",
              initialPromotionalSearchDate,
              endingPromotionalSearchDate
            );
          }}
        >
          <SearchIcon />
        </IconButton>
      </Box>

      {!hasPromotionalSearchedOnce ? null : (
        <Box sx={{ margin: "20px" }}>
          {searchResultsLoading ? (
            <CircularProgress />
          ) : (
            promotionalSearchResults
          )}
        </Box>
      )}
    </Box>
  );

  const serviceSearchResults = (
    <Box sx={styles.searchResultsGrid}>
      {serviceStoreResultsArray.map((serviceStore: IServiceStore, index) => (
        <Box
          onClick={() => {
            handleSelectServiceClick(serviceStore.id);
          }}
          key={index}
        >
          <CustomCard
            photoURL={serviceStore.imagePreview[0]}
            header={`R$ ${priceFormatter(
              serviceStore.priceDay[CURRENT_DAY_INDEX]
            )}`}
            primaryText={serviceStore.Service.name}
            secondaryText={serviceStore.Store.name}
            defaultPhoto={defaultServiceImage}
          />
        </Box>
      ))}
    </Box>
  );

  const serviceSearch = (
    <Box sx={styles.searchContainer}>
      <Autocomplete
        freeSolo
        options={servicesAutocomplete.map((service) => service.name)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Qual serviço você deseja?"
            size="small"
            fullWidth
            sx={{ minWidth: "320px" }}
            value={serviceSearchInput}
          />
        )}
        inputValue={serviceSearchInput}
        onInputChange={(_, value) => {
          setServiceSearchInput(value);
        }}
        defaultValue={serviceSearchParam ?? ""}
      />

      {serviceStoreResultsArray.length > 0 ? (
        <Box sx={styles.searchResultsContainer}>
          {renderResultsFoundedTypography(serviceStoreResultsArray.length)}
          {serviceSearchResults}
        </Box>
      ) : searchResultsLoading ? (
        <>
          <CircularProgress sx={styles.searchLoadingContainer} />
        </>
      ) : serviceSearchInput.length > 0 ? (
        <Box sx={styles.searchResultsContainer}>
          <Typography variant="h6">Nenhum serviço encontrado.</Typography>
        </Box>
      ) : null}
    </Box>
  );

  const storeSearchResults = (
    <Box sx={styles.searchResultsGrid}>
      {storeResultsArray.map((store: IStore, index) => (
        <Box
          onClick={() => {
            handleSelectStoreClick(store.id);
          }}
          key={index}
        >
          <CustomCard
            photoURL={store.imagePreview[0]}
            header={store.name}
            secondaryText={store.description}
            defaultPhoto={defaultStoreImage}
            primaryText={""}
          />
        </Box>
      ))}
    </Box>
  );

  const storeSearch = (
    <Box sx={styles.searchContainer}>
      <Autocomplete
        freeSolo
        options={storesAutocomplete.map((store) => store.name)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Busque por um estabelecimento"
            size="small"
            sx={{ minWidth: "320px" }}
          />
        )}
        inputValue={storeSearchInput}
        onInputChange={(_, value) => {
          setStoreSearchInput(value);
        }}
        defaultValue={storeSearchParam ?? ""}
      />

      {storeResultsArray.length > 0 ? (
        <Box sx={styles.searchResultsContainer}>
          {renderResultsFoundedTypography(storeResultsArray.length)}
          {storeSearchResults}
        </Box>
      ) : searchResultsLoading ? (
        <>
          <CircularProgress sx={styles.searchLoadingContainer} />
        </>
      ) : storeSearchInput.length > 0 ? (
        <Box sx={styles.searchResultsContainer}>
          <Typography variant="h6">
            Nenhum estabelecimento encontrado.
          </Typography>
        </Box>
      ) : null}
    </Box>
  );

  const employeeSearchResults = (
    <Box sx={styles.searchResultsGrid}>
      {employeesResultsArray.map((employee: IEmployee, index) => (
        <Box
          key={index}
          onClick={() => {
            handleSelectEmployeeClick(employee.id);
          }}
        >
          <CustomCard
            photoURL={employee.User.imagePreview}
            header={employee.User.name}
            defaultPhoto={defaultEmployeeImage}
            primaryText={""}
            secondaryText={""}
          />
        </Box>
      ))}
    </Box>
  );

  const employeeSearch = (
    <Box sx={styles.searchContainer}>
      <Autocomplete
        freeSolo
        options={employeesAutocomplete.map((employee) => employee.User.name)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Busque por um profissional"
            size="small"
            sx={{ minWidth: "320px" }}
          />
        )}
        inputValue={employeeSearchInput}
        onInputChange={(_, value) => {
          setEmployeeSearchInput(value);
        }}
        defaultValue={employeeSearchParam ?? ""}
      />

      {employeesResultsArray.length > 0 ? (
        <Box sx={styles.searchResultsContainer}>
          {renderResultsFoundedTypography(employeesResultsArray.length)}
          {employeeSearchResults}
        </Box>
      ) : searchResultsLoading ? (
        <>
          <CircularProgress sx={styles.searchLoadingContainer} />
        </>
      ) : employeeSearchInput.length > 0 ? (
        <Box sx={styles.searchResultsContainer}>
          <Typography variant="h6">Nenhum profissional encontrado.</Typography>
        </Box>
      ) : null}
    </Box>
  );

  return (
    <Box>
      <Box sx={{ overflowX: "hidden" }}>
        <ClientNavbar hideMidButtons />

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={searchTypeIndex}
            onChange={handleChangeTabIndex}
            centered
            variant="fullWidth"
            sx={{
              width: "100vw",
              backgroundColor: "primary.main",
              justifyContent: "space-around",
            }}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab
              label="Promoções"
              icon={<PriceCheckIcon />}
              sx={{ textTransform: "none", color: "rgba(0,0,0,0.4)" }}
            />
            <Tab
              label="Estabelecimentos"
              icon={<StoreIcon />}
              sx={{
                textTransform: "none",
                minWidth: "30%",
                color: "rgba(0,0,0,0.4)",
              }}
            />
            <Tab
              label="Serviços"
              icon={<WorkIcon />}
              sx={{ textTransform: "none", color: "rgba(0,0,0,0.4)" }}
            />
            <Tab
              label="Profissionais"
              icon={<PeopleIcon />}
              sx={{ textTransform: "none", color: "rgba(0,0,0,0.4)" }}
            />
          </Tabs>
        </Box>
      </Box>
      <Box
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        {searchTypeIndex === 0
          ? promotionalSearch
          : searchTypeIndex === 1
            ? storeSearch
            : searchTypeIndex === 2
              ? serviceSearch
              : employeeSearch}
      </Box>
    </Box>
  );
};

const styles = {
  searchContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    alignItems: "center",
    margin: "40px 0px",
  } as SxProps,

  searchResultsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  } as SxProps,

  searchResultsGrid: {
    display: "grid",
    maxWidth: "100%",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    margin: "20px 0px",
    rowGap: 3,
    columnGap: 3,
  } as SxProps,

  datesContainer: {
    display: "flex",
    alignItems: "center",
    gap: 1.5,
  } as SxProps,

  searchIcon: { width: "50px", height: "50px" } as SxProps,

  bestPromotionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  } as SxProps,

  bestPromotionCardContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  } as SxProps,

  promotionalSearchResultsDivider: {
    margin: "20px 0px",
  } as SxProps,

  promotionalSearchCalendarAndPricesContainer: {
    display: "flex",
    "@media(max-width:700px)": { flexDirection: "column" },
    justifyContent: "space-between",
  } as SxProps,

  bestPricesPromotionalSearchList: {
    margin: "20px 0px",
    width: "100%",
    maxHeight: "240px",
    "@media(max-width:700px)": {
      maxHeight: "400px",
    },
    overflowY: "auto",
    boxShadow:
      "inset 0px 15px 10px -10px rgba(0, 0, 0, 0.15), inset 0px -15px 10px -10px rgba(0, 0, 0, 0.15)",
  } as SxProps,

  searchLoadingContainer: { margin: "35px 0px" } as SxProps,
};
