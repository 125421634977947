export enum DocType {
  CPF = "CPF",
  CNPJ = "CNPJ",
  PASSPORT = "PASSPORT",
}

export enum UserStatus {
  OK = "OK",
  PENDENT = "PENDENT",
}

export enum UserRole {
  GUEST = "GUEST",
  CLIENT = "CLIENT",
  COMERCIAL = "COMERCIAL",
  MODERATOR = "MODERATOR",
  ADMIN = "ADMIN",
}

export enum PrefNotify {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export enum AppointmentStatus {
  PENDENT = "PENDENT",
  CONFIRMED = "CONFIRMED",
  CANCELED = "CANCELED",
  FINISHED = "FINISHED",
}

export enum ImageOriginalResolutions {
  WIDTH = 800,
  HEIGHT = 600,
}

export enum ImagePreviewResolutions {
  WIDTH = 320,
  HEIGHT = 200,
}

export enum oneClickPlatform {
  GOOGLE = "GOOGLE",
  APPLE = "APPLE",
}

export enum PaymentMethod {
  PIX,
  CREDIT_CARD,
  DEBIT_CARD,
  BANK_SLIP,
  MONEY,
  CRIPTO,
}

export const FOOD_SERVICES = [
  "Não incluso",
  "All inclusive",
  "Café da manhã",
  "Meia pensão",
  "Pensão completa",
];

export enum ExcursionStatus {
  ACTIVE,
  CANCELED,
  FINISHED,
  SOLD_OUT,
}

export enum ExcursionServiceType {
  ACCOMMODATION,
  FOOD,
  TRANSPORT,
}

export interface BrazilianAddress {
  cep?: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  service?: string;
}
