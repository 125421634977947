import requests from "../util";
import { IPromotionalSearchResponse, type IAppointment } from "./types";

const url = "appointment";

const appointmentApi = {
  getAppointment: async (id: string): Promise<IAppointment> =>
    await requests.get(`${url}/${id}`),
  getAppointments: async (): Promise<IAppointment[]> => await requests.get(url),
  createAppointment: async (appointment: IAppointment): Promise<IAppointment> =>
    await requests.post(url, appointment),
  updateAppointment: async (
    id: string,
    appointment: Object
  ): Promise<IAppointment> => await requests.patch(`${url}/${id}`, appointment),
  deleteAppointment: async (id: string): Promise<void> =>
    await requests.delete(`${url}/${id}`),
  getIntervalAppointments: async (
    serviceId: string,
    startDate: string,
    endDate: string
  ): Promise<IPromotionalSearchResponse> =>
    await requests.get(
      `${url}/available-interval/${serviceId}/${startDate}/${endDate}`
    ),
  getServiceEmployeeAvailableTimesInADate: async (
    date: string,
    serviceEmployeeId: string
  ) => await requests.get(`available-time/${date}/${serviceEmployeeId}`),
  getAppointmentsFromClient: async (
    clientId: string,
    startDate?: Date
  ): Promise<IAppointment[]> =>
    await requests.get(`${url}?clientId=${clientId}&startDate=${startDate}`),
  getAppointmentsFromStore: async (storeId: string): Promise<IAppointment[]> =>
    await requests.get(`${url}?storeId=${storeId}&limit=50`),
  getAppointmentsFromStoreAndEmployee: async (
    storeId: string,
    employeeId: string
  ): Promise<IAppointment[]> =>
    await requests.get(
      `${url}?storeId=${storeId}&employeeId=${employeeId}&limit=50`
    ),
};

export default appointmentApi;
