import requests from "../util";
import { type IServiceEmployee } from "./types";

const url = "service-employee";

const serviceEmployeeApi = {
  createServiceEmployee: async (
    employeeId: string,
    serviceStoreId: string,
  ): Promise<IServiceEmployee> =>
    await requests.post(url, { employeeId, serviceStoreId }),
  getServiceEmployee: async (id: string): Promise<IServiceEmployee> =>
    await requests.get(`${url}/${id}`),
  getServiceEmployeeByServiceStoreId: async (
    serviceStoreId: string,
  ): Promise<IServiceEmployee[]> =>
    await requests.get(`service-employee?serviceStoreId=${serviceStoreId}`),
  getServiceEmployeeByServiceStoreIdAndEmployeeId: async (
    serviceStoreId: string,
    employeeId: string,
  ): Promise<IServiceEmployee[]> =>
    await requests.get(
      `service-employee?serviceStoreId=${serviceStoreId}&employeeId=${employeeId}`,
    ),
  getAllServiceEmployees: async (): Promise<IServiceEmployee[]> =>
    await requests.get(url),
  getServiceEmployeesFromEmployeeId: async (
    employeeId: string,
  ): Promise<IServiceEmployee[]> =>
    await requests.get(`service-employee?employeeId=${employeeId}`),
  updateServiceEmployee: async (
    id: string,
    serviceEmployee: {},
  ): Promise<IServiceEmployee> =>
    await requests.patch(`${url}/${id}`, serviceEmployee),
  deleteServiceEmployee: async (serviceEmployeeId: string): Promise<void> =>
    await requests.delete(`${url}/${serviceEmployeeId}`),
};

export default serviceEmployeeApi;
