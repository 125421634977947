import requests from "../util";
import { type ICart } from "./types";

const URL = "cart";

const cartApi = {
  getUserCart: async (userId: string): Promise<ICart> =>
    await requests.get(`${URL}/user/${userId}`),
  deleteCart: async (id: string): Promise<void> =>
    await requests.delete(`${URL}/${id}`),
};

export default cartApi;
