import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CEODrawer from "../../../../../components/turism/CEODrawer";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import { useEffect, useState } from "react";
import storeApi from "../../../../../services/api/Store";
import { type IStore } from "../../../../../services/api/Store/types";
import AddIcon from "@mui/icons-material/Add";
import { useAuth } from "../../../../../context/AuthProvider/useAuth";
import { cleanLocalStorage } from "../../../../../utils/cleanLocalStorage";
import {
  generalBrightGrey,
  generalDarkGrey,
  turismPrimary,
} from "../../../../../styles/colors";
import IExcursion from "../../../../../services/api/Excursion/IExcursion";
import excursionApi from "../../../../../services/api/Excursion";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import WebIcon from "@mui/icons-material/Web";
import defaultExcursionImage from "../../../../../assets/svg/defaultExcursion.svg";
import { deleteImageFromFirebase } from "../../../../../utils/firebase";

const Excursions = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  cleanLocalStorage();

  const [stores, setStores] = useState<IStore[]>([]);
  const [excursions, setExcursions] = useState<Record<string, IExcursion[]>>(
    {}
  );
  const [areStoresLoading, setAreStoresLoading] = useState(true);

  const fetchUserStores = async () => {
    try {
      const allStores = await storeApi.getManagerStores(auth.user.id);
      setStores(allStores);
    } catch (error) {
      console.warn("Error getting all stores: ", error);
    }
    setAreStoresLoading(false);
  };

  const fetchExcursionsFromAStore = async (storeId: string) => {
    try {
      const storeExcursionsResponse =
        await excursionApi.getExcursionsFromAStore(storeId);

      setExcursions((prevStoreExcursions) => ({
        ...prevStoreExcursions,
        [storeId]: storeExcursionsResponse,
      }));
    } catch (error) {
      console.warn("Error getting excursions from store: ", error);
    }
  };

  useEffect(() => {
    fetchUserStores();
  }, [auth]);

  useEffect(() => {
    stores.forEach((store) => {
      fetchExcursionsFromAStore(store.id);
    });
  }, [stores]);

  const handleSelectStore = (store: IStore) => {
    const selectedStore = { id: store.id, name: store.name };
    localStorage.setItem("selectedStore", JSON.stringify(selectedStore));
  };

  const handleAddExcursionClick = (store: IStore) => {
    handleSelectStore(store);
    navigate("/excursions/add");
  };

  const handleEditExcursionClick = (store: IStore, excursion: IExcursion) => {
    localStorage.setItem(
      "storeData",
      JSON.stringify({ storeName: store.name, storeId: store.id })
    );
    handleSelectExcursion(excursion);
    navigate("/excursions/edit");
  };

  const handleSelectExcursion = (excursion: IExcursion) => {
    localStorage.setItem("selectedExcursion", JSON.stringify(excursion));
  };

  const handleSeeExcursionDetailsClick = (excursion: IExcursion) => {
    handleSelectExcursion(excursion);
    navigate("/excursions/details");
  };

  const handleDeleteExcursionClick = async (
    excursion: IExcursion
  ) => {
    const wantsToDelete: boolean = window.confirm(
      `Tem certeza que deseja excluir a excursão ${excursion.title}?`
    );

    if (wantsToDelete) {
      try {
        if(excursion.imageOriginal.length > 0){
          const deleteImagePromises = [];

          for (let i = 0; i < excursion.imageOriginal.length; i++) {
            deleteImagePromises.push(deleteImageFromFirebase(excursion.imageOriginal[i]));
            deleteImagePromises.push(deleteImageFromFirebase(excursion.imagePreview[i]));
          }
  
     await Promise.all(deleteImagePromises);
        }

        await excursionApi.deleteExcursion(excursion.id);
        fetchUserStores();
      } catch (error) {
        console.warn("Error deleting an excursion: ", error);
      }
    }
  };

  const renderServicesPageContent = (
    <Box>
      {!areStoresLoading ? (
        <>
          {stores.length !== 0 ? (
            stores.map((store) => (
              <Box key={store.id}>
                <CEOInterfaceHeader
                  title={`Excursões de ${store.name}`}
                  buttonLabel="Adicionar excursão"
                  buttonIcon={<AddIcon />}
                  buttonAction={() => {
                    handleAddExcursionClick(store);
                  }}
                />
                {excursions[store.id] && excursions[store.id].length > 0 ? (
                  <Box width="100%">
                    <TableContainer sx={styles.excursionItemContainer}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Excursão</TableCell>
                            <TableCell
                              align="center"
                              sx={styles.priceHeaderContainer}
                            >
                              Preço
                            </TableCell>
                            <TableCell align="right">Ações</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {excursions[store.id]?.map((excursion) => (
                            <TableRow key={excursion.id}>
                              <TableCell align="left">
                                <Box sx={styles.excursionItemContent}>
                                  <Avatar
                                    src={
                                      excursion.imagePreview[0] ??
                                      defaultExcursionImage
                                    }
                                    sx={styles.avatarStyle}
                                  />

                                  <ListItemText
                                    primary={`${excursion.title}`}
                                    secondary={`Agente: ${excursion.agent}`}
                                  />
                                </Box>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={styles.excursionPriceContainer}
                              >{`R$ ${excursion.price.toFixed(2)}`}</TableCell>
                              <TableCell align="right">
                                <Tooltip title="Visualizar página">
                                  <IconButton
                                    onClick={() =>
                                      handleSeeExcursionDetailsClick(excursion)
                                    }
                                    color="primary"
                                  >
                                    <WebIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Editar">
                                  <IconButton
                                    onClick={() =>
                                      handleEditExcursionClick(store, excursion)
                                    }
                                    color="primary"
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Excluir">
                                  <IconButton
                                    onClick={() =>
                                      handleDeleteExcursionClick(excursion)
                                    }
                                    color="primary"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ) : (
                  <Box sx={styles.noAgencyRegisteredContainer}>
                    <Typography color={generalDarkGrey}>
                      Este estabelecimento ainda não possui nenhuma excursão.
                    </Typography>
                  </Box>
                )}
              </Box>
            ))
          ) : (
            <>
              <Typography color={generalDarkGrey}>
                Nenhuma agência cadastrada. Para cadastrar excursões é
                necessário{" "}
                <span
                  style={styles.registerAnAgencySpan}
                  onClick={() => {
                    navigate("/agencies/add");
                  }}
                >
                  cadastrar uma agência
                </span>
                .
              </Typography>
            </>
          )}
        </>
      ) : (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderServicesPageContent} />
    </Box>
  );
};

export default Excursions;

const styles = {
  excursionItemContainer: {
    margin: "30px 0px",
    width: "100%",
    border: `1px solid ${generalBrightGrey}`,
    borderRadius: "15px",
  },

  priceHeaderContainer: {
    "@media(max-width:900px)": { display: "none" },
  },

  excursionItemContent: {
    display: "flex",
    gap: 2,
    alignItems: "center",
  },

  avatarStyle: {
    width: "80px",
    height: "80px",
    borderRadius: 0,
  },

  excursionPriceContainer: {
    "@media(max-width:900px)": {
      display: "none",
    },
  },

  noAgencyRegisteredContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px 30px 0px",
  },

  registerAnAgencySpan: {
    color: turismPrimary,
    fontWeight: "bold",
    cursor: "pointer",
  },

  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "50px 0px",
  },
};
