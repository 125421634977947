import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { ISO_SERVICE_TIMESTAMPS, SERVICE_TIMESTAMPS } from "./timeUtils";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { AvailabilityCard } from "../../../../components/general/AvailabilityCard";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { ScheduilingModal } from "../../../../components/general/ScheduilingModal";
import appointmentApi from "../../../../services/api/Appointment";
import { IEmployee } from "../../../../services/api/Employee/types";
import serviceEmployeeApi from "../../../../services/api/ServiceEmployee";
import { IServiceStore } from "../../../../services/api/ServiceStore/types";
import { IAppointment } from "../../../../services/api/Appointment/types";
import BackPageButton from "../../../../components/general/buttons/BackPageButton";
import employeeApi from "../../../../services/api/Employee";
import {
  fetchEmployee,
  fetchServiceStore,
} from "../../../../utils/fetchEntities";

export const ScheduilingPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const today = dayjs();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedDate = searchParams.get("selectedDate");
  const [areTimesLoading, setAreTimeLoading] = useState(true);

  const employeeId = searchParams.get("employee");
  const serviceId = searchParams.get("serviceId");

  const [selectedServiceStore, setSelectedServiceStore] =
    useState<IServiceStore | null>(null);

  const selectedEmployeeName = JSON.parse(
    localStorage.getItem("selectedEmployeeName")
  );

  const [date, setDate] = useState<Date>(
    selectedDate ? dayjs.utc(selectedDate) : today
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedTimeDay, setSelectedTimeDay] = useState<string>("");
  const [selectedTimeAndDate, setSelectedTimeAndDate] = useState<string>("");

  const [availableTimes, setAvailableTimes] = useState<string[]>([]);

  const getServiceEmployeeId = async () => {
    const serviceEmployeeRes =
      await serviceEmployeeApi.getServiceEmployeeByServiceStoreIdAndEmployeeId(
        serviceId,
        employeeId
      );

    const serviceEmployeeId = serviceEmployeeRes[0].id;
    return serviceEmployeeId;
  };

  const fetchAvailableAppointmentTimes = async () => {
    setAreTimeLoading(true);
    const serviceEmployeeId = await getServiceEmployeeId();

    try {
      const res = await appointmentApi.getServiceEmployeeAvailableTimesInADate(
        date.toISOString(),
        serviceEmployeeId
      );

      const temporaryAvailableTimesArray: string[] = [];

      for (let index = 0; index < res.length; index++) {
        if (res[index].available)
          temporaryAvailableTimesArray.push(res[index].time);
      }
      setAvailableTimes(temporaryAvailableTimesArray);
    } catch (error) {
      console.warn("Error fetching available appointment times: ", error);
    }
    setAreTimeLoading(false);
  };

  const handleSubmitAppointment = async () => {
    const serviceEmployeeId = await getServiceEmployeeId();

    try {
      const appointmentResponse = await appointmentApi.createAppointment({
        serviceEmployeeId,
        clientId: auth.user.id,
        date: selectedTimeAndDate,
      } as IAppointment);

      console.log("Appointment successfully created: ", appointmentResponse);
      handleCloseModal();
      alert(
        `Serviço agendado com sucesso para ${date.format(
          "DD/MM/YYYY"
        )} às ${selectedTimeDay} horas.`
      );

      navigate("/cart");
    } catch (error) {
      console.warn("Error scheduiling a service: ", error);

      confirm(
        "Conclua o carrinho em aberto antes de abrir um novo carrinho.\n\nOBS.: Só é possível preencher o carrinho com serviços do mesmo estabelecimento."
      )
        ? navigate("/cart")
        : null;

      handleCloseModal();
    }
  };

  useEffect(() => {
    fetchAvailableAppointmentTimes();
  }, [date]);

  useEffect(() => {
    fetchServiceStore(serviceId!, setSelectedServiceStore!);
  }, []);

  const handleOpenModal = (time: string): void => {
    setSelectedTimeDay(time);
    setIsModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setIsModalOpen(false);
  };

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <ScheduilingModal
        open={isModalOpen}
        onClose={handleCloseModal}
        price={selectedServiceStore?.priceDay[date?.toDate().getDay()]}
        date={date?.format("DD/MM/YYYY")}
        timeSelected={selectedTimeDay}
        onConfirm={handleSubmitAppointment}
      />
      <ClientNavbar hideMidButtons />
      <BackPageButton
        containerStyles={styles.backButton}
        iconStyles={styles.backIcon}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
        gap={1}
      >
        <Box justifyContent="center" display="flex" flexDirection="column">
          <Box
            display="flex"
            marginBottom="20px"
            alignItems="center"
            justifyContent="space-evenly"
            gap={5}
            sx={{
              "@media (max-width: 850px)": {
                flexDirection: "column",
                gap: 3,
              },
            }}
          >
            <Box width="100%" textAlign="center">
              <ListItem>
                <ListItemAvatar>
                  <Avatar src={selectedServiceStore?.imagePreview[0]} />
                </ListItemAvatar>
                <ListItemText
                  primary={selectedServiceStore?.Service?.name}
                  secondary={`${selectedEmployeeName} (${selectedServiceStore?.Store?.name})`}
                />
              </ListItem>
            </Box>

            <Box display="flex" alignItems="center" gap={1}>
              <Typography fontWeight="bold">Agendamento: </Typography>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <DatePicker
                  label="Data de busca"
                  slotProps={{
                    textField: {
                      size: "small",
                      placeholder: "dd/mm/aaaa",
                    },
                  }}
                  value={date}
                  onChange={(newScheduilingDate) => {
                    setDate(newScheduilingDate);
                  }}
                  disablePast
                  dayOfWeekFormatter={(day) => {}}
                  sx={{ width: "200px" }}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          <Divider />
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        margin="20px 0px"
      >
        {!areTimesLoading ? (
          <Box
            display="grid"
            maxWidth="100%"
            gridTemplateColumns="repeat(auto-fit, minmax(170px, 1fr))"
            margin="10px 0px"
            rowGap={3}
            columnGap={3}
          >
            {SERVICE_TIMESTAMPS.map((time, index) => (
              <Box key={index}>
                <AvailabilityCard
                  time={time}
                  function={() => {
                    handleOpenModal(time);
                    setSelectedTimeAndDate(
                      `${date.toISOString().split("T")[0]}${
                        ISO_SERVICE_TIMESTAMPS[index]
                      }`
                    );
                  }}
                  isAvailable={availableTimes.includes(
                    SERVICE_TIMESTAMPS[index]
                  )}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            width="100%"
            height="40vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  backButton: {
    margin: "5px",
  },

  backIcon: {
    width: "30px",
    height: "30px",
  },
};
