import {
  Autocomplete,
  Box,
  CircularProgress,
  SxProps,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import "dayjs/locale/pt-br";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import StoreIcon from "@mui/icons-material/Store";
import ExploreIcon from "@mui/icons-material/Explore";
import CustomCard from "../../../../components/general/CustomCard";
import IExcursion from "../../../../services/api/Excursion/IExcursion";
import excursionApi from "../../../../services/api/Excursion";
import { priceFormatter } from "../../../../utils/priceFormatter";
import defaultExcursionImage from "../../../../assets/svg/defaultExcursion.svg";
import defaultStoreImage from "../../../../assets/svg/defaultStore.svg";
import storeApi from "../../../../services/api/Store";
import { IStore } from "../../../../services/api/Store/types";

export const TurismSearchPage = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [searchTypeIndex, setSearchTypeIndex] = useState<number>(0);

  const handleChangeTabIndex = (_, newValue: number) => {
    setSearchTypeIndex(newValue);
  };

  const [searchResultsLoading, setSearchResultsLoading] = useState(false);

  // ============ EXCURSIONS ============ //
  const [excursionSearchInput, setExcursionSearchInput] = useState<string>("");
  const [excursionsAutocomplete, setExcursionAutocomplete] = useState<
    IExcursion[]
  >([]);
  const [excursionResultsArray, setExcursionResultsArray] = useState<
    IExcursion[]
  >([]);

  const handleSearchExcursions = async (title: string) => {
    setSearchResultsLoading(true);
    setSearchParams({ excursion: title });
    try {
      const res = await excursionApi.searchExcursions(title);
      setExcursionAutocomplete(res);
      setExcursionResultsArray(res);
    } catch (error) {
      console.warn("Error searching excursions: ", error);
    }
    setSearchResultsLoading(false);
  };

  useEffect(() => {
    if (excursionSearchInput.length > 0)
      handleSearchExcursions(excursionSearchInput);
    else {
      setExcursionResultsArray([]);
      setExcursionAutocomplete([]);
      setSearchParams({});
    }
  }, [excursionSearchInput]);
  // ==================================== //

  // ============ STORE ============ //
  const [storeSearchInput, setStoreSearchInput] = useState<string>("");
  const [storesAutocomplete, setStoresAutocomplete] = useState<IStore[]>([]);
  const [storeResultsArray, setStoresResultsArray] = useState<IStore[]>([]);

  const handleSearchStores = async (storeName: string) => {
    setSearchResultsLoading(true);
    setSearchParams({ store: storeName });
    try {
      const res = await storeApi.searchStores(storeName);
      setStoresResultsArray(res);
      setStoresAutocomplete(res);
    } catch (error) {
      console.warn("Error searching stores: ", error);
    }
    setSearchResultsLoading(false);
  };

  useEffect(() => {
    if (storeSearchInput.length > 0) {
      handleSearchStores(storeSearchInput);
    } else {
      setStoresResultsArray([]);
      setStoresAutocomplete([]);
      setSearchParams({});
    }
  }, [storeSearchInput]);
  // =============================== //

  const handleSelectStoreClick = (storeId: string) => {
    localStorage.setItem("searchType", JSON.stringify(searchTypeIndex));
    navigate(`/store?id=${storeId}`);
  };

  const handleSelectExcursionClick = (excursionId: string) => {
    navigate(`/excursion?id=${excursionId}`);
  };

  const renderResultsFoundedTypography = (resultsNumber: number) => {
    return (
      <Typography
        variant="h6"
        fontWeight="bold"
      >{`Resultados encontrados (${resultsNumber}):`}</Typography>
    );
  };

  const excursionSearchResults = (
    <Box sx={styles.searchResultsGrid}>
      {excursionResultsArray.map((excursion: IExcursion, index) => (
        <Box
          onClick={() => {
            handleSelectExcursionClick(excursion.id);
          }}
          key={index}
        >
          <CustomCard
            photoURL={excursion.imagePreview[0]}
            header={`R$ ${priceFormatter(excursion.price * 100)}`}
            primaryText={excursion.title}
            secondaryText={`${excursion.city} - ${excursion.country}`}
            defaultPhoto={defaultExcursionImage}
          />
        </Box>
      ))}
    </Box>
  );

  const excursionSearch = (
    <Box sx={styles.searchContainer}>
      <Autocomplete
        freeSolo
        options={excursionsAutocomplete.map((excursion) => excursion?.title)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Busque por uma excursão"
            size="small"
            fullWidth
            sx={{ minWidth: "320px" }}
            value={excursionSearchInput}
          />
        )}
        inputValue={excursionSearchInput}
        onInputChange={(_, value) => {
          setExcursionSearchInput(value);
        }}
        defaultValue={""}
      />

      {excursionResultsArray.length > 0 ? (
        <Box sx={styles.searchResultsContainer}>
          {renderResultsFoundedTypography(excursionResultsArray.length)}
          {excursionSearchResults}
        </Box>
      ) : searchResultsLoading ? (
        <>
          <CircularProgress sx={styles.searchLoadingContainer} />
        </>
      ) : "".length > 0 ? (
        <Box sx={styles.searchResultsContainer}>
          <Typography variant="h6">Nenhuma excursão encontrada.</Typography>
        </Box>
      ) : null}
    </Box>
  );

  const storeSearchResults = (
    <Box sx={styles.searchResultsGrid}>
      {storeResultsArray.map((store: IStore, index) => (
        <Box
          onClick={() => {
            handleSelectStoreClick(store.id);
          }}
          key={index}
        >
          <CustomCard
            photoURL={store.imagePreview[0]}
            header={store.name}
            secondaryText={store.description}
            defaultPhoto={defaultStoreImage}
            primaryText={""}
          />
        </Box>
      ))}
    </Box>
  );

  const storeSearch = (
    <Box sx={styles.searchContainer}>
      <Autocomplete
        freeSolo
        options={storesAutocomplete.map((store) => store.name)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Busque por um estabelecimento"
            size="small"
            sx={{ minWidth: "320px" }}
          />
        )}
        inputValue={storeSearchInput}
        onInputChange={(_, value) => {
          setStoreSearchInput(value);
        }}
        defaultValue={""}
      />

      {storeResultsArray.length > 0 ? (
        <Box sx={styles.searchResultsContainer}>
          {renderResultsFoundedTypography(storeResultsArray.length)}
          {storeSearchResults}
        </Box>
      ) : searchResultsLoading ? (
        <>
          <CircularProgress sx={styles.searchLoadingContainer} />
        </>
      ) : storeSearchInput.length > 0 ? (
        <Box sx={styles.searchResultsContainer}>
          <Typography variant="h6">
            Nenhum estabelecimento encontrado.
          </Typography>
        </Box>
      ) : null}
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <ClientNavbar hideMidButtons />

      <Tabs
        value={searchTypeIndex}
        onChange={handleChangeTabIndex}
        centered
        variant="fullWidth"
        sx={{
          width: "100vw",
          backgroundColor: "primary.main",
          justifyContent: "space-around",
        }}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab
          label="Excursões"
          icon={<ExploreIcon />}
          sx={{ textTransform: "none", color: "rgba(0,0,0,0.4)" }}
        />
        <Tab
          label="Agências"
          icon={<StoreIcon />}
          sx={{
            textTransform: "none",
            minWidth: "30%",
            color: "rgba(0,0,0,0.4)",
          }}
        />
      </Tabs>
      <Box
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        {searchTypeIndex === 0 ? (
          excursionSearch
        ) : searchTypeIndex === 1 ? (
          storeSearch
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  searchContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    alignItems: "center",
    margin: "40px 0px",
  } as SxProps,

  searchResultsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  } as SxProps,

  searchResultsGrid: {
    display: "grid",
    maxWidth: "100%",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    margin: "20px 0px",
    rowGap: 3,
    columnGap: 3,
  } as SxProps,

  searchLoadingContainer: { margin: "35px 0px" } as SxProps,
};
