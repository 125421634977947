import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  SxProps,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useState, useEffect } from "react";
import { AppointmentDetailsModal } from "../../../../components/general/AppointmentDetailsModal";
import AppointmentStatusChip from "../../../../components/general/AppointmentStatusChip";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import appointmentApi from "../../../../services/api/Appointment";
import { IAppointment } from "../../../../services/api/Appointment/types";
import { formatDateAndTimeDayDateToString } from "../ScheduilingPage/timeUtils";
import BackPageButton from "../../../../components/general/buttons/BackPageButton";

const ClientAppointments = () => {
  const auth = useAuth();
  const today = dayjs();

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());

  const [areAppointmentsLoading, setAreAppointmentsLoading] = useState(true);

  const [succeededRatingPost, setSucceededRatingPost] = useState(false);

  const [userAppointments, setUserAppointments] = useState([]);

  const [selectedUserAppointment, setSelectedUserAppointment] = useState(null);

  const [pendentAppointments, setPendentAppointments] = useState([]);
  const [confirmedAppointments, setConfirmedAppointments] = useState([]);
  const [canceledAppointments, setCanceledAppointments] = useState([]);
  const [finishedAppointments, setFinishedAppointments] = useState([]);

  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);

  const handleOpenModal = (selectedAppointment: IAppointment) => {
    setSelectedUserAppointment(selectedAppointment);
    setIsAppointmentModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setIsAppointmentModalOpen(false);
  };

  const fetchUsersAppointments = async () => {
    try {
      const userAppointmentsResponse =
        await appointmentApi.getAppointmentsFromClient(
          auth.user.id,
          today.toISOString()
        );

      setUserAppointments(userAppointmentsResponse);

      // getPendentConfirmedCanceledAndFinishedAppointmentDatesFromUserAppointments(
      //   userAppointmentsResponse
      // );
    } catch (error) {
      console.warn("Error fetching user appointments: ", error);
    }
    setAreAppointmentsLoading(false);
  };

  // const getPendentConfirmedCanceledAndFinishedAppointmentDatesFromUserAppointments =
  //   (appointments: IAppointment[]) => {
  //     const auxiliarPendentAppointmentDays = [];
  //     const auxiliarConfirmedAppointmentDays = [];
  //     const auxiliarCanceledAppointmentDays = [];
  //     const auxiliarFinishedAppointmentDays = [];

  //     let iterableAppointment = null;
  //     let iterableAppointmentDate = "";

  //     for (let i = 0; i < appointments.length; i++) {
  //       iterableAppointment = appointments[i];
  //       iterableAppointmentDate = iterableAppointment.date.split("T")[0];

  //       if (
  //         iterableAppointment.status === AppointmentStatus.PENDENT &&
  //         !auxiliarPendentAppointmentDays.includes(iterableAppointmentDate)
  //       ) {
  //         auxiliarPendentAppointmentDays.push(iterableAppointmentDate);
  //       }
  //       if (
  //         iterableAppointment.status === AppointmentStatus.CONFIRMED &&
  //         !auxiliarConfirmedAppointmentDays.includes(iterableAppointmentDate)
  //       ) {
  //         auxiliarConfirmedAppointmentDays.push(iterableAppointmentDate);
  //       }
  //       if (
  //         iterableAppointment.status === AppointmentStatus.CANCELED &&
  //         !auxiliarCanceledAppointmentDays.includes(iterableAppointmentDate)
  //       ) {
  //         auxiliarCanceledAppointmentDays.push(iterableAppointmentDate);
  //       }
  //       if (
  //         iterableAppointment.status === AppointmentStatus.FINISHED &&
  //         !auxiliarFinishedAppointmentDays.includes(iterableAppointmentDate)
  //       ) {
  //         auxiliarFinishedAppointmentDays.push(iterableAppointmentDate);
  //       }
  //     }

  //     setPendentAppointments(auxiliarPendentAppointmentDays);
  //     setConfirmedAppointments(auxiliarConfirmedAppointmentDays);
  //     setCanceledAppointments(auxiliarCanceledAppointmentDays);
  //     setFinishedAppointments(auxiliarFinishedAppointmentDays);
  //   };

  useEffect(() => {
    fetchUsersAppointments();
  }, [auth]);

  const handleFinishService = () => {
    fetchUsersAppointments();
    handleCloseModal();
  };

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <AppointmentDetailsModal
        open={isAppointmentModalOpen}
        appointment={selectedUserAppointment}
        onClose={handleCloseModal}
        onFinish={handleFinishService}
        setSucceededRatingPost={setSucceededRatingPost}
      />
      <Snackbar
        open={succeededRatingPost}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setSucceededRatingPost(false)}
      >
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          Serviço avaliado com sucesso!
        </Alert>
      </Snackbar>
      <ClientNavbar hideMidButtons />
      <BackPageButton
        containerStyles={styles.backButton}
        iconStyles={styles.backIcon}
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={3}
        marginBottom="30px"
      >
        <Typography variant="h5" fontWeight="bold">
          Seus agendamentos
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <DatePicker
            label="Selecione uma data"
            slotProps={{
              textField: {
                size: "small",
                placeholder: "dd/mm/aaaa",
              },
            }}
            value={selectedDate}
            onChange={(newScheduilingDate) => {
              setSelectedDate(newScheduilingDate);
            }}
            disablePast
            dayOfWeekFormatter={(day) => {}}
            sx={{ width: "200px" }}
          />
        </LocalizationProvider>
        {!areAppointmentsLoading ? (
          <Box display="flex" flexDirection="column" gap={1.5}>
            <Typography variant="h6">
              Dia {selectedDate?.format("DD/MM/YYYY")}
            </Typography>

            {userAppointments.some(
              (appointment) =>
                appointment.date.split("T")[0] ===
                selectedDate?.format("YYYY-MM-DD")
            ) ? (
              <List
                sx={{
                  width: "500px",
                  height: "463px",
                  overflowY: "auto",
                  boxShadow:
                    "inset 0px 15px 10px -10px rgba(0, 0, 0, 0.15), inset 0px -15px 10px -10px rgba(0, 0, 0, 0.15)",
                  "@media (max-width: 900px)": {
                    width: "100%",
                  },
                  borderRadius: "10px",
                }}
                disablePadding
              >
                {userAppointments.map((appointment, index) =>
                  appointment.date.split("T")[0] ===
                  selectedDate?.format("YYYY-MM-DD") ? (
                    <>
                      <ListItemButton
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 2,
                        }}
                        onClick={() => handleOpenModal(userAppointments[index])}
                      >
                        <ListItemText
                          primary={`${userAppointments[index].serviceEmployee.ServiceStore.Service.name} - ${userAppointments[index].serviceEmployee.Employee.store.name}`}
                          secondary={`${formatDateAndTimeDayDateToString(
                            userAppointments[index].date
                          )} com ${
                            userAppointments[index].serviceEmployee.Employee
                              .User.name
                          } `}
                          secondaryTypographyProps={{}}
                        />
                        <AppointmentStatusChip
                          status={userAppointments[index].status}
                        />
                      </ListItemButton>
                    </>
                  ) : (
                    <></>
                  )
                )}
              </List>
            ) : (
              <Box width="500px">
                <Typography color="text.secondary">
                  Nenhum agendamento para esta data.
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            minHeight="30vh"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ClientAppointments;

const styles = {
  backButton: {
    margin: "5px",
  },

  backIcon: {
    width: "30px",
    height: "30px",
  },

  calendar: {
    borderRadius: "10px",
    border: "1px solid",
    borderColor: "text.secondary",

    "& .MuiBadge-badge": {
      fontSize: "0.7em",
      paddingTop: "4px",
    },

    "& .MuiDayCalendar-header": {
      justifyContent: "center",
      width: "100%",
      overflow: "hidden",
      margin: "5px 0px 20px 0px",
    },
    "& .MuiDayCalendar-weekContainer": {
      display: "flex",
      justifyContent: "space-around",
      overflow: "hidden",
      width: "100%",
      height: "100%",
      margin: 0,
    },
    "& .MuiPickersDay-dayWithMargin": {
      width: "calc(100% - 4px)",
      height: "calc(100% - 4px)",
      aspectRatio: "1",
      fontSize: "1.0em",
    },
    "& .MuiBadge-root": {
      aspectRatio: 1,
      width: "10%",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
    },

    "& .MuiDayCalendar-weekDayLabel": {
      aspectRatio: 1,
      width: "100%",
      fontSize: "1.0em",
    },

    "& .MuiPickersCalendarHeader-label": {
      fontSize: "1.3em",
    },

    "& .MuiDayCalendar-slideTransition": {
      aspectRatio: 2.3,
      width: "100%",
      display: "flex",
      paddingBottom: "150px",
      overflow: "hidden",
    },

    "@media (max-width: 700px)": {
      "& .MuiDayCalendar-slideTransition": {
        aspectRatio: 1.5,
        width: "100%",
        paddingBottom: "30px",
      },
    },

    width: "100%",
    maxHeight: "100%",
  } as SxProps,
};
