import { type Dayjs } from "dayjs";

export const SERVICE_TIMESTAMPS: string[] = [
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
];

export const ISO_SERVICE_TIMESTAMPS: string[] = [
  "T10:00:00.000Z",
  "T11:00:00.000Z",
  "T12:00:00.000Z",
  "T13:00:00.000Z",
  "T14:00:00.000Z",
  "T15:00:00.000Z",
  "T16:00:00.000Z",
  "T17:00:00.000Z",
  "T18:00:00.000Z",
  "T19:00:00.000Z",
  "T20:00:00.000Z",
  "T21:00:00.000Z",
  "T22:00:00.000Z",
  "T23:00:00.000Z",
  "T00:00:00.000Z",
  "T01:00:00.000Z",
];

export const convertDayJsDateAndTimeDayToISOString = (
  date: Dayjs,
  timeday: string
): string => {
  let modifiedTimedayString = "";

  if (timeday.length !== 5) {
    modifiedTimedayString = `0${timeday}`;
  } else {
    modifiedTimedayString = timeday;
  }

  return `${date.format("YYYY-MM-DD")}T${modifiedTimedayString}:00.000Z`;
};

export const formatDateAndTimeDayDateToString = (date: string): string => {
  const splittedDateString = date.split("T");

  const splittedYearDate = splittedDateString[0].split("-");

  const year = splittedYearDate[0];
  const month = splittedYearDate[1];
  const yearDay = splittedYearDate[2];

  const timeString = splittedDateString[1].substring(0, 5).split(":")

  const hours = (Number(timeString[0]) - 3).toString();
  const minutes = timeString[1];

  return `${yearDay}/${month}/${year} às ${hours}:${minutes} horas`;
};
