import requests from "../util";
import { IRating } from "./types";

const url = "rating";

const ratingApi = {
  getRating: (id: string): Promise<IRating> => requests.get(`${url}/${id}`),
  getAllRatings: (): Promise<IRating[]> => requests.get(url),
  getRatingFromUserRatedAppointment: (appointmentId: string, userId: string) =>
    requests.get(`${url}?appointmentId=${appointmentId}&userId=${userId}`),
  getRatingFromServiceStore: (serviceStoreId: string): Promise<IRating[]> =>
    requests.get(`${url}?serviceStoreId=${serviceStoreId}`),
  createRating: (rating: IRating): Promise<IRating> =>
    requests.post(url, rating),
  updateRating: (id: string, rating: {}): Promise<IRating> =>
    requests.patch(`${url}/${id}`, rating),
  deleteRating: (id: string): Promise<void> => requests.delete(`${url}/${id}`),
};

export default ratingApi;
