import axios from "axios";
import { BrazilianAddress } from "../types/types";
import { MutableRefObject, Ref } from "react";

const BRASIL_API_CEP_FETCH_URL = `https://brasilapi.com.br/api/cep/v1`;

export const fetchAddressByCEP = async (
  cep: string
): Promise<BrazilianAddress> => {
  try {
    const addressResponse = await axios.get(
      `${BRASIL_API_CEP_FETCH_URL}/${cep}`
    );

    const fetchedAddress: BrazilianAddress = {
      street: addressResponse.data.street,
      city: addressResponse.data.city,
      neighborhood: addressResponse.data.neighborhood,
      state: addressResponse.data.state,
    };

    return fetchedAddress;
  } catch (error) {
    console.warn("Error fetching address from CEP: ", error);
  }
};

export const checkIfCepInputWasFilledAfterFetching = (
  wasCepFetched: boolean,
  inputRef: MutableRefObject<HTMLInputElement>
): boolean => {
  return wasCepFetched && inputRef.current.value ? true : false;
};
