import { Typography, Box, Stack } from "@mui/material";
import LogoSvg from "../../../assets/svg/logo-full.svg"

interface LogoProps {
  color?: string;
  isOnNavbar?: boolean;
}

const Logo: React.FC<LogoProps> = ({ color, isOnNavbar }) => {
  return (
    <Stack direction="row" alignItems="center" spacing="10px">
      <Box sx={{ minWidth: "32px", height: "32px", overflow: "hidden" }}>
        <img src={LogoSvg} alt="logo" height="100%" />
      </Box>
      <Typography
        fontSize="38px"
        fontWeight="bold"
        color={color}
        sx={{
          "@media (max-width: 650px)": {
            display: isOnNavbar ? "none" : "block",
          },
        }}
      >
        Demandoria
      </Typography>
    </Stack>
  );
};

export default Logo;
