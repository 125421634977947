import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import StoreIcon from "@mui/icons-material/Store";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { generalWhite, generalBlack } from "../../../styles/colors";
import { Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import ExploreIcon from "@mui/icons-material/Explore";

const DRAWER_WIDTH = 300;

interface Props {
  content: any;
  window?: () => Window;
}

const drawerIcons = [<StoreIcon />, <ExploreIcon />, <LogoutIcon />];

export default function CEODrawer(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedSessionIndex, setSelectedSessionIndex] = useState(null);

  const auth = useAuth();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogoutClick = () => {
    const wantToLogout: boolean = confirm("Tem certeza que deseja sair?");

    if (wantToLogout) {
      auth.logout();
      localStorage.removeItem("token");
      navigate("/login");
    }
  };

  const navigate = useNavigate();

  const location = useLocation();

  const handleMyProfileClick = (): void => {
    navigate("/user/details");
  };

  const handleChangeSelectedSessionIndex = (index: number) => {
    setSelectedSessionIndex(index);
  };

  const checkIfIsASelectablePath = (path: string) => {
    return (
      location.pathname === path ||
      location.pathname === `${path}/add` ||
      location.pathname === `${path}/edit` ||
      location.pathname === `${path}/edit` ||
      location.pathname === `${path}/details`
    );
  };

  const drawer = (
    <Box
      sx={{
        backgroundColor: "primary.main",
        height: "100%",
        color: generalWhite
      }}
    >
      <Toolbar
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography variant="h5" fontWeight="bold">
          Demandoria Turismo
        </Typography>
      </Toolbar>

      <List sx={{ marginBottom: "5px" }}>
        {[
          { text: "Agências", path: "/agencies" },
          { text: "Excursões", path: "/excursions" },

          { text: "Sair", path: "/login" },
        ].map(({ text, path }, index) => {
          const navigate = useNavigate();

          return (
            <Box key={index}>
              <ListItem
                key={text}
                disablePadding
                sx={{
                  width: "300px",
                  bottom: text === "Sair" ? "0" : "auto",
                  position: text === "Sair" ? "fixed" : "static",
                  ":hover": {
                    backgroundColor: "rgba(0,0,0,0.1)",
                  },
                  ":hover .list-item-icon": {
                    color: generalWhite,
                  },
                  ":hover .list-item-text": {
                    color: generalWhite,
                  },
                }}
                onClick={() => handleChangeSelectedSessionIndex(index)}
              >
                <ListItemButton
                  onClick={() => {
                    if (text === "Sair") {
                      handleLogoutClick();
                    }
                    navigate(path);
                  }}
                  sx={{
                    backgroundColor:
                    checkIfIsASelectablePath(path)
                        ? "secondary.main"
                        : "transparent",
                        color:
                        checkIfIsASelectablePath(path)
                          ? "primary.main"
                          : generalWhite   ,
                  }}
                >
                  <ListItemIcon  className="list-item-icon" sx={{  color:
                      checkIfIsASelectablePath(path)
                          ? "primary.main"
                          : generalWhite, }}>
                    {drawerIcons[index % drawerIcons.length]}
                  </ListItemIcon>
                  <Typography
                    fontWeight={
                      checkIfIsASelectablePath(path) ? "bold" : "regular"
                    }
                    sx={{
                      color: checkIfIsASelectablePath(path)
                        ? "primary.main"
                        : generalWhite,
                    }}
                      className="list-item-text"
                  >
                    {text}
                  </Typography>
                </ListItemButton>
              </ListItem>
            </Box>
          );
        })}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
          ml: { sm: `${DRAWER_WIDTH}px` },
          mr: "12px",
          backgroundColor: generalWhite,
          color: "text.primary",
          boxShadow: "none",
          border: "none",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon sx={{ color: "text.primary" }} />
          </IconButton>
          <Box sx={{ flex: 1 }} />
          <Box display="flex" gap={1} sx={{ justifyContent: "flex-end" }}>
           
            <Tooltip title="Meus dados">
              <IconButton
                size="large"
                onClick={() => {
                  handleMyProfileClick();
                }}
              >
                <AccountCircle sx={{ color: generalBlack }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              minWidth: DRAWER_WIDTH,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginTop: "70px",
          minWidth: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
        }}
      >
        {props.content}
      </Box>
    </Box>
  );
}
