import { Box, Card, CardContent, SxProps, Typography } from "@mui/material";
import Logo from "../../../components/general/Logo";
import { PrimaryButton } from "../../../components/general/buttons/PrimaryButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DEMANDORIA_SUBDOMAINS } from "../../../utils/stringUtils";
import { generalDarkGrey } from "../../../styles/colors";
import BeautyIcon from "../../../assets/img/beauty-icon.png";
import PetIcon from "../../../assets/img/pet-icon.png";
import TurismIcon from "../../../assets/img/turism-icon.png";
import { CSSProperties } from "react";
import Footer from "../../../components/general/Footer";

const GeneralLandingPage = () => {
  const checkIfIsInSubdomain = (): boolean => {
    return (
      window.location.host.includes(DEMANDORIA_SUBDOMAINS[0]) ||
      window.location.host.includes(DEMANDORIA_SUBDOMAINS[1]) ||
      window.location.host.includes(DEMANDORIA_SUBDOMAINS[2])
    );
  };
  const handleNicheClick = (nicheName) => {
    if (checkIfIsInSubdomain())
      window.location.href = `http://${nicheName.toLowerCase()}.${
        window.location.host.split(".")[1]
      }/`;
    else
      window.location.href = `http://${nicheName.toLowerCase()}.${
        window.location.host
      }/`;
  };

  const NicheCard = ({ children, onClickAction }) => {
    return (
      <Card variant="outlined" sx={styles.card} onClick={onClickAction}>
        <CardContent sx={styles.cardContent}>{children}</CardContent>
      </Card>
    );
  };

  return (
    <>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.headerContainer}>
          <Logo />
          <Typography variant="h6" color={generalDarkGrey}>
            Selecione o nicho desejado
          </Typography>
        </Box>
        <Box sx={styles.cardsContainer}>
          {DEMANDORIA_SUBDOMAINS.map((niche, index) => (
            <NicheCard
              key={niche}
              onClickAction={() => handleNicheClick(niche)}
            >
              <img
                src={
                  index === 0 ? BeautyIcon : index === 1 ? PetIcon : TurismIcon
                }
                alt={`${niche}_img`}
                width={170}
              />
              <Typography variant="h5" textAlign="center">
                {niche}
              </Typography>
            </NicheCard>
          ))}
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default GeneralLandingPage;

const styles = {
  mainContainer: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
  } as SxProps,

  headerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as SxProps,

  cardsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: 5,
    "@media(max-width:900px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  } as SxProps,

  card: {
    width: "300px",
    cursor: "pointer",
  } as SxProps,

  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 3,
    padding: "30px 0px",
  } as SxProps,
};
