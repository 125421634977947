import { Box } from "@mui/material";
import { ReactNode } from "react";

interface ContactIconProps {
  icon: ReactNode;
}

const ContactIcon = (props: ContactIconProps) => {
  return <Box sx={styles}>{props.icon}</Box>;
};

export default ContactIcon;

const styles = {
  borderWidth: "1px",
  borderColor: "primary.main",
  borderStyle: "solid",
  borderRadius: "35%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "7px",
};
