import { Modal, Box, Typography, Button } from "@mui/material";
import {
  generalBlack,
  generalDarkGrey,
  dmPrimaryOrange,
} from "../../../styles/colors";
import { priceFormatter } from "../../../utils/priceFormatter";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,

  bgcolor: "background.paper",
  p: 4,
  "@media (max-width: 600px)": {
    width: 300,
  },
};

interface ScheduilingModalProps {
  open: any;
  onClose: any;
  date: string;
  timeSelected: string;
  price: number;
  onConfirm: any;
}

export const ScheduilingModal = (props: ScheduilingModalProps) => {
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box sx={style} color="text.secondary" borderRadius="10px">
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          color="text.primary"
        >
          Deseja agendar o serviço?
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <span style={{ fontWeight: "bold", color: "text.primary" }}>
            Dia:{" "}
          </span>
          {props.date}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <span style={{ fontWeight: "bold", color: "text.primary" }}>
            Horário:{" "}
          </span>
          {props.timeSelected}
        </Typography>
        <Box margin="10px 0px">
          <Typography
            variant="h4"
            fontWeight="bold"
            color="primary"
          >{`R$ ${priceFormatter(props.price)}`}</Typography>
        </Box>
        <Box display="flex" gap={1} justifyContent="flex-end">
          <Button
            variant="contained"
            disableElevation
            onClick={props.onConfirm}
          >
            Sim
          </Button>
          <Button onClick={props.onClose}>Não</Button>
        </Box>
      </Box>
    </Modal>
  );
};
