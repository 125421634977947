import requests from "../util";
import { type IServiceStore } from "./types";

const url = "service-store";

const serviceStoreApi = {
  getServiceStore: async (id: string): Promise<IServiceStore> =>
    await requests.get(`${url}/${id}`),
  getAllServiceStores: async (): Promise<IServiceStore[]> =>
    await requests.get(url),
  getServiceStoresFromAServiceName: async (
    serviceName: string
  ): Promise<IServiceStore[]> =>
    await requests.get(`${url}?serviceName=${serviceName}&start=0&limit=100`),
  createServiceStore: async (
    serviceStore: IServiceStore
  ): Promise<IServiceStore> => await requests.post(url, serviceStore),
  updateServiceStore: async (
    id: string,
    serviceStore: {}
  ): Promise<IServiceStore> =>
    await requests.patch(`${url}/${id}`, serviceStore),
  deleteServiceStore: async (id: string): Promise<void> =>
    await requests.delete(`${url}/${id}`),
  getServiceStoresFromStoreName: async (
    storeName: string
  ): Promise<IServiceStore[]> =>
    await requests.get(`${url}?storeName=${storeName}`),
  getServiceStoresFromStoreId: async (
    storeId: string
  ): Promise<IServiceStore[]> =>
    await requests.get(`${url}?storeId=${storeId}`),
  getServiceStoresFromServiceNameAndStoreId: async (
    serviceName: string,
    storeId: string
  ): Promise<IServiceStore> =>
    await requests.get(`${url}?serviceName=${serviceName}&storeId=${storeId}`),
};

export default serviceStoreApi;
