import requests from "../util";
import { type IEmployee } from "./types";

const url = "employee";

const employeeApi = {
  getEmployee: async (id: string): Promise<IEmployee> =>
    await requests.get(`${url}/${id}`),
  getEmployeeFromUserId: async (id: string): Promise<IEmployee[]> =>
    await requests.get(`${url}?userId=${id}`),
  getAllEmployees: async (): Promise<IEmployee[]> => await requests.get(url),
  createEmployee: async (employee: IEmployee): Promise<IEmployee> =>
    await requests.post(url, employee),
  updateEmployee: async (id: string, employee: {}): Promise<IEmployee> =>
    await requests.patch(`${url}/${id}`, employee),
  deleteEmployee: async (id: string): Promise<void> =>
    await requests.delete(`${url}/${id}`),
  searchEmployees: async (searchTerm: string): Promise<IEmployee[]> =>
    await requests.get(`${url}/search/${searchTerm}`),
  getEmployeesFromStoreId: async (storeId: string): Promise<IEmployee[]> =>
    await requests.get(`${url}?storeId=${storeId}`),
  getEmployeesFromUserIdAndStoreId: async (
    userId: string,
    storeId: string
  ): Promise<IEmployee[]> =>
    await requests.get(`${url}?userId=${userId}&storeId=${storeId}`),
};

export default employeeApi;
